import MarketingStatus from '../MarketingStatus';
import LocationDetail from './LocationDetail';
import Detail from './Detail';
import OfferDetail from './OfferDetail/OfferDetail';
import AdditionalDetail from './AdditionalDetail';
import EnergyCertificate from './EnergyCertificate/EnergyCertificate';
import MatchingParameter from '../Matching/MatchingParameter';
import MatchingTask from '../Matching/MatchingTask';
import ProviderSeekerConversation from '../Messaging/ProviderSeekerConversation';
import PropertyInterest from '../PropertyInterest/PropertyInterest';
import InformationScope from '../InformationScope';
import Contact from '../Contact/Contact';
import Image from '../Image/Image';
import Document from '../Document/Document';
import PropertyResponse from '../../../../api/Llasm/shared/Property/PropertyResponse';
import CompletePropertyResponse from '../../../../api/Llasm/shared/Property/CompletePropertyResponse';
import DetailedPropertyResponse from '../../../../api/Llasm/shared/Property/DetailedPropertyResponse';
import ContactResponse from '../../../../api/Llasm/shared/Contact/ContactResponse';
import ImageResponse from '../../../../api/Llasm/shared/Image/ImageResponse';
import DocumentResponse from '../../../../api/Llasm/shared/Document/DocumentResponse';
import ResponseType from '../../../../api/Llasm/shared/ResponseType';

class Property {
    private _id?: number;

    private _active: boolean;

    private _marketingStatus: MarketingStatus;

    private _title: string;

    private _internalDesignation: string | null = null;

    private _locationDetail: LocationDetail;

    private _detail: Detail;

    private _offerDetail: OfferDetail;

    private _additionalDetail: AdditionalDetail;

    private _energyCertificate: EnergyCertificate | null = null;

    private _matchingParameter: MatchingParameter | null = null;

    private _matchingTask: MatchingTask | null = null;

    private _providerSeekerConversation: ProviderSeekerConversation | null = null;

    private _propertyInterest: PropertyInterest | null = null;

    private _hasContactIntentions: boolean;

    private _informationScope: InformationScope;

    private _contacts: Contact[] = [];

    private _images: Image[] = [];

    private _documents: Document[] = [];

    public static createFromPropertyResponse(propertyResponse: PropertyResponse): Property {
       switch (propertyResponse.responseType) {
           case ResponseType.Complete:
               return Property.createFromCompletePropertyResponse(propertyResponse as CompletePropertyResponse);
           case ResponseType.Detailed:
               return Property.createFromDetailedPropertyResponse(propertyResponse as DetailedPropertyResponse);
           default:
               throw new Error('Invalid ResponseType');
       }
    }

    public static createFromCompletePropertyResponse(completePropertyResponse: CompletePropertyResponse): Property {
        const property: Property = new this();

        this.mapFromPropertyResponse(property, completePropertyResponse);

        property._internalDesignation = completePropertyResponse.internalDesignation;
        property._informationScope = InformationScope.Complete;

        return property;
    }

    public static createFromDetailedPropertyResponse(completePropertyResponse: DetailedPropertyResponse): Property {
        const property: Property = new this();

        this.mapFromPropertyResponse(property, completePropertyResponse);
        property._informationScope = InformationScope.Detailed;

        return property;
    }

    private static mapFromPropertyResponse(property: Property, propertyResponse: PropertyResponse): void {
        property._id = propertyResponse.id;
        property._active = propertyResponse.active;
        property._marketingStatus = propertyResponse.marketingStatus;
        property._title = propertyResponse.title;
        property._locationDetail = LocationDetail.createFromLocationDetailResponse(propertyResponse.locationDetail);
        property._detail = Detail.createFromDetailResponse(propertyResponse.detail);
        property._offerDetail = OfferDetail.createFromOfferDetailResponse(propertyResponse.offerDetail);
        property._additionalDetail = AdditionalDetail.createFromAdditionalDetailResponse(propertyResponse.additionalDetail);

        if (propertyResponse.energyCertificate !== null) {
            property._energyCertificate = EnergyCertificate.createFromEnergyCertificateResponse(propertyResponse.energyCertificate);
        }

        if (propertyResponse.matchingParameter !== null) {
            property._matchingParameter = MatchingParameter.createFromMatchingParameterResponse(propertyResponse.matchingParameter);
        }

        if (propertyResponse.matchingTask !== null) {
            property._matchingTask = MatchingTask.createFromMatchingTaskResponse(propertyResponse.matchingTask);
        }

        if (propertyResponse.providerSeekerConversation !== null) {
            property._providerSeekerConversation = ProviderSeekerConversation.createFromProviderSeekerConversationResponse(propertyResponse.providerSeekerConversation);
        }

        if (propertyResponse.propertyInterest !== null) {
            property._propertyInterest = PropertyInterest.createFromPropertyInterestResponse(propertyResponse.propertyInterest);
        }

        property._hasContactIntentions = propertyResponse.hasContactIntentions;

        property._contacts = propertyResponse.contacts.map((contactResponse: ContactResponse): Contact => {
            return Contact.createFromContactResponse(contactResponse);
        });

        property._images = propertyResponse.images.map((imageResponse: ImageResponse): Image => {
            return Image.createFromImageResponse(imageResponse);
        });

        property._documents = propertyResponse.documents.map((documentResponse: DocumentResponse): Document => {
            return Document.createFromDocumentResponse(documentResponse);
        });
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get marketingStatus(): MarketingStatus {
        return this._marketingStatus;
    }

    set marketingStatus(value: MarketingStatus) {
        this._marketingStatus = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get internalDesignation(): string | null {
        return this._internalDesignation;
    }

    set internalDesignation(value: string | null) {
        this._internalDesignation = value;
    }

    get locationDetail(): LocationDetail {
        return this._locationDetail;
    }

    set locationDetail(value: LocationDetail) {
        this._locationDetail = value;
    }

    get detail(): Detail {
        return this._detail;
    }

    set detail(value: Detail) {
        this._detail = value;
    }

    get offerDetail(): OfferDetail {
        return this._offerDetail;
    }

    set offerDetail(value: OfferDetail) {
        this._offerDetail = value;
    }

    get additionalDetail(): AdditionalDetail {
        return this._additionalDetail;
    }

    set additionalDetail(value: AdditionalDetail) {
        this._additionalDetail = value;
    }

    get energyCertificate(): EnergyCertificate | null {
        return this._energyCertificate;
    }

    set energyCertificate(value: EnergyCertificate | null) {
        this._energyCertificate = value;
    }

    get matchingParameter(): MatchingParameter | null {
        return this._matchingParameter;
    }

    set matchingParameter(value: MatchingParameter | null) {
        this._matchingParameter = value;
    }

    get matchingTask(): MatchingTask | null {
        return this._matchingTask;
    }

    set matchingTask(value: MatchingTask | null) {
        this._matchingTask = value;
    }

    get providerSeekerConversation(): ProviderSeekerConversation | null {
        return this._providerSeekerConversation;
    }

    set providerSeekerConversation(value: ProviderSeekerConversation | null) {
        this._providerSeekerConversation = value;
    }

    get propertyInterest(): PropertyInterest | null {
        return this._propertyInterest;
    }

    set propertyInterest(value: PropertyInterest | null) {
        this._propertyInterest = value;
    }

    get hasContactIntentions(): boolean {
        return this._hasContactIntentions;
    }

    set hasContactIntentions(value: boolean) {
        this._hasContactIntentions = value;
    }

    get informationScope(): InformationScope {
        return this._informationScope;
    }

    set informationScope(value: InformationScope) {
        this._informationScope = value;
    }

    get contacts(): Contact[] {
        return this._contacts;
    }

    set contacts(value: Contact[]) {
        this._contacts = value;
    }

    get images(): Image[] {
        return this._images;
    }

    set images(value: Image[]) {
        this._images = value;
    }

    get documents(): Document[] {
        return this._documents;
    }

    set documents(value: Document[]) {
        this._documents = value;
    }
}

export default Property;
