import UserService from '../../shared/User/UserService';
import User from '../../shared/Entity/User/User';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import Alert from '../../shared/Entity/Alert/Alert';
import AlertType from '../../shared/Entity/Alert/AlertType';
import FormData from '../../shared/Entity/Form/FormData';
import BillingAddressForm from './BillingAddressForm';
import FieldValidationDefinition from '../../shared/FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../../shared/FormValidationHandler/RequiredValidationDefinition';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import LoadingIndicator from '../../shared/Component/LoadingIndicator/LoadingIndicator';
import AlertBox from '../../../components/AlertBox';
import React, {useEffect, useState} from 'react';

interface BillingAddressEditProps {
    readonly billingAddressId: number;
    readonly user: User;
    readonly onBillingAddressUpdated?: (billingAddress: BillingAddress) => void;
    readonly onCloseButtonClick?: () => void;
}

const successAlert: Alert = new Alert(AlertType.Success, 'Die Rechnungsadresse wurde erfolgreich aktualisiert.');

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Du hast nicht alle Pflichtfelder gefüllt. Bitte kontrolliere die mit einem roten Ausrufezeichen markierten Felder.');

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);

const fieldValidationDefinitions: FieldValidationDefinition<BillingAddress>[] = [
    new RequiredValidationDefinition<BillingAddress>('firstName', 'Vorname muss ausgefüllt sein.'),
    new RequiredValidationDefinition<BillingAddress>('lastName', 'Nachname muss ausgefüllt sein.'),
    new RequiredValidationDefinition<BillingAddress>('streetName', 'Straße muss ausgefüllt sein.'),
    new RequiredValidationDefinition<BillingAddress>('houseNumber', 'Hausnummer muss ausgefüllt sein.'),
    new RequiredValidationDefinition<BillingAddress>('postalCode', 'PLZ muss ausgefüllt sein.'),
    new RequiredValidationDefinition<BillingAddress>('placeName', 'Ort muss ausgefüllt sein.'),
    new RequiredValidationDefinition<BillingAddress>('email', 'E-Mail muss ausgefüllt sein.'),
];

const createFormData = (): FormData<BillingAddress> => {
    return {
        data: new BillingAddress(),
        formValidationHandler: new FormValidationHandler<BillingAddress>(fieldValidationDefinitions)
    };
};

const BillingAddressEdit = (props: BillingAddressEditProps): React.JSX.Element => {
    const [billingAddress, setBillingAddress] = useState<BillingAddress>();

    const [formData, setFormData] = useState<FormData<BillingAddress>>(createFormData());

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [alert, setAlert] = useState<Alert>();

    useEffect((): void => {
        fetchBillingAddress();
    }, []);

    useEffect((): void => {
        if (formData.formValidationHandler === undefined) {
            return;
        }

        if (props.user.naturalPerson === false) {
            formData.formValidationHandler.addFieldValidationDefinition(new RequiredValidationDefinition<BillingAddress>('companyName', 'Firmenname'));
        } else {
            formData.formValidationHandler.removeFieldValidationDefinitionsByFieldName('companyName');
        }
    }, [props.user]);

    const fetchBillingAddress = async (): Promise<void> => {
        try {
            setIsLoading(true);
            const billingAddress: BillingAddress | null = await userService.fetchBillingAddressFromApiByUser(props.user);

            if (billingAddress === null) {
                throw new Error();
            }

            setBillingAddress(billingAddress);
            setFormData({...formData, 'data': billingAddress});
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const updateBillingAddress = async (): Promise<void> => {
        if (formData.formValidationHandler === undefined){
            setAlert(errorAlert);

            throw new Error();
        }

        formData.formValidationHandler.validate(formData);

        if (formData.formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setAlert(undefined);

        setIsLoading(true);

        try {
            await userService.updateBillingAddress(props.user, formData.data);

            setAlert(successAlert);

            const billingAddress: BillingAddress | null = await userService.fetchBillingAddressFromApiByUser(props.user);

            if (billingAddress === null) {
                throw new Error();
            }

            setBillingAddress(billingAddress);
            setFormData({...formData, 'data': billingAddress});

            if (props.onBillingAddressUpdated !== undefined) {
                props.onBillingAddressUpdated(billingAddress);
            }
        } catch (error) {
            setAlert(errorAlert);

            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading === true) {
        return <LoadingIndicator />;
    }

    return (
        <div className="container">
            {alert !== undefined &&
                <AlertBox alert={alert} autoDismiss={false} />
            }

            <BillingAddressForm user={props.user} formData={formData} setFormData={setFormData} />

            <button type="submit" className="btn btn-primary mt-3 me-3" onClick={updateBillingAddress}>Speichern</button>
            {props.onCloseButtonClick !== undefined &&
                <button className="btn btn-secondary mt-3" onClick={props.onCloseButtonClick}>
                    SCHLIESSEN
                </button>
            }
        </div>
    );
};

export default BillingAddressEdit;
