import MarketplaceAd from './MarketplaceAd';
import MarketplaceLotResponse from '../../../../api/Llasm/shared/Marketplace/MarketplaceLotResponse';

class MarketplaceLot {
    private _id?: number;

    private _marketplaceAd : MarketplaceAd | null = null;

    private _availableNumberOfDays: number;

    public static createFromMarketplaceLotResponse(marketplaceLotResponse: MarketplaceLotResponse): MarketplaceLot {
        const marketplaceLot: MarketplaceLot = new this();

        marketplaceLot._id = marketplaceLotResponse.id;

        if (marketplaceLotResponse.marketplaceAd !== null) {
            marketplaceLot._marketplaceAd = MarketplaceAd.createFromMarketplaceAdResponse(marketplaceLotResponse.marketplaceAd);
        }

        marketplaceLot._availableNumberOfDays = marketplaceLotResponse.availableNumberOfDays;

        return marketplaceLot;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get marketplaceAd(): MarketplaceAd | null {
        return this._marketplaceAd;
    }

    set marketplaceAd(value: MarketplaceAd | null) {
        this._marketplaceAd = value;
    }

    get availableNumberOfDays(): number {
        return this._availableNumberOfDays;
    }

    set availableNumberOfDays(value: number) {
        this._availableNumberOfDays = value;
    }
}

export default MarketplaceLot;
