import Image from '../Image/Image';
import AgeStructure from '../AgeStructure';
import TargetGroup from '../TargetGroup';
import PriceSegment from '../PriceSegment';
import CompanyValue from '../CompanyValue';
import BranchingDegree from '../BranchingDegree';
import BrandResponse from '../../../../api/Llasm/shared/Brand/BrandResponse';
import DetailedBrandResponse from '../../../../api/Llasm/shared/Brand/DetailedBrandResponse';
import CompleteBrandResponse from '../../../../api/Llasm/shared/Brand/CompleteBrandResponse';
import LockedBrandResponse from '../../../../api/Llasm/shared/Brand/LockedBrandResponse';
import UnlockedBrandResponse from '../../../../api/Llasm/shared/Brand/UnlockedBrandResponse';
import ResponseType from '../../../../api/Llasm/shared/ResponseType';

class Brand {
    private _id?: number;

    private _logo: Image | null = null;

    private _brandName: string;

    private _ageStructures: AgeStructure[];

    private _targetGroups: TargetGroup[];

    private _priceSegments: PriceSegment[];

    private _companyValues: CompanyValue[];

    private _branchingDegree: BranchingDegree;

    public static createFromBrandResponse(brandResponse: BrandResponse): Brand {
        switch (brandResponse.responseType) {
            case ResponseType.Complete:
                return Brand.createFromCompleteBrandResponse(brandResponse as CompleteBrandResponse);
            case ResponseType.Locked:
                return Brand.createFromLockedBrandResponse(brandResponse as LockedBrandResponse);
            case ResponseType.Unlocked:
                return Brand.createFromUnlockedBrandResponse(brandResponse as UnlockedBrandResponse);
            default:
                throw new Error('Invalid ResponseType');
        }
    }

    public static createFromCompleteBrandResponse(brandResponse: CompleteBrandResponse): Brand {
        const brand: Brand = new this();

        this.mapFromBrandResponse(brand, brandResponse);
        this.mapFromDetailedBrandResponse(brand, brandResponse);

        return brand;
    }

    public static createFromLockedBrandResponse(brandResponse: LockedBrandResponse): Brand {
        const brand: Brand = new this();

        this.mapFromBrandResponse(brand, brandResponse);

        return brand;
    }

    public static createFromUnlockedBrandResponse(brandResponse: UnlockedBrandResponse): Brand {
        const brand: Brand = new this();

        this.mapFromBrandResponse(brand, brandResponse);
        this.mapFromDetailedBrandResponse(brand, brandResponse);

        return brand;
    }

    private static mapFromBrandResponse(brand: Brand, brandResponse: BrandResponse): void {
        brand._id = brandResponse.id;
        brand._companyValues = brandResponse.companyValues;
        brand._branchingDegree = brandResponse.branchingDegree;
    }

    private static mapFromDetailedBrandResponse(brand: Brand, brandResponse: DetailedBrandResponse): void {
        brand._brandName = brandResponse.brandName;

        if (brandResponse.logo !== null) {
            brand._logo = Image.createFromImageResponse(brandResponse.logo);
        }

        brand._ageStructures = brandResponse.ageStructures;
        brand._targetGroups = brandResponse.targetGroups;
        brand._priceSegments = brandResponse.priceSegments;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get logo(): Image | null {
        return this._logo;
    }

    set logo(value: Image | null) {
        this._logo = value;
    }

    get brandName(): string {
        return this._brandName;
    }

    set brandName(value: string) {
        this._brandName = value;
    }

    get ageStructures(): AgeStructure[] {
        return this._ageStructures;
    }

    set ageStructures(value: AgeStructure[]) {
        this._ageStructures = value;
    }

    get targetGroups(): TargetGroup[] {
        return this._targetGroups;
    }

    set targetGroups(value: TargetGroup[]) {
        this._targetGroups = value;
    }

    get priceSegments(): PriceSegment[] {
        return this._priceSegments;
    }

    set priceSegments(value: PriceSegment[]) {
        this._priceSegments = value;
    }

    get companyValues(): CompanyValue[] {
        return this._companyValues;
    }

    set companyValues(value: CompanyValue[]) {
        this._companyValues = value;
    }

    get branchingDegree(): BranchingDegree {
        return this._branchingDegree;
    }

    set branchingDegree(value: BranchingDegree) {
        this._branchingDegree = value;
    }
}

export default Brand;
