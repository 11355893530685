import UserService from './UserService';
import LoadingIndicator from '../Component/LoadingIndicator/LoadingIndicator';
import {useAppDispatch} from '../../../app/hooks';
import {Link, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);

const UserEmailChangeConfirmationPage = (): React.JSX.Element => {
    const {userEmailChangeUuid} = useParams<string>();

    const [userEmailChangeSuccessful, setUserEmailChangeSuccessful] = useState<boolean>();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'E-Mail bestätigen'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        if (userEmailChangeUuid === undefined) {
            return;
        }

        confirmUserEmailChange();
    }, []);

    const confirmUserEmailChange = async (): Promise<void> => {
        if (userEmailChangeUuid === undefined) {
            return;
        }

        try {
            await userService.confirmUserEmailChange(userEmailChangeUuid);

            setUserEmailChangeSuccessful(true);
        } catch (error) {
            setUserEmailChangeSuccessful(false);
        }
    };

    if (userEmailChangeSuccessful === undefined) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <div className="row g-3 mt-1 mb-3">
                <div className="col-12 pt-1 px-md-3">
                    <h1 className="fw-bold text-secondary fs-3 mb-4 mb-lg-5">
                        Ladenfläche trifft Nachmieter:<br/>
                        <span className='fs-2'>Match mit!</span>
                    </h1>
                    <h2 className="fs-3 fw-bold ls-1">E-Mail-Adresse ändern</h2>
                </div>
                <div className="col-12 pt-1 text-start px-md-3">
                    {userEmailChangeSuccessful === true ? (
                        <div className="alert alert-primary px-md-3 mb-4" role="alert">
                            Du hast Deine neue E-Mail-Adresse erfolgreich bestätigt.
                            Bitte nutze diese ab sofort für das Login.
                        </div>
                    ) : (
                        <div className="alert alert-warning px-md-3 mb-4" role="alert">
                            Du hast Deine neue E-Mail-Adresse bereits bestätigt.
                        </div>
                    )}
                    <div>
                        <Link className="btn btn-outline-secondary" to="/">Zum Dashboard</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserEmailChangeConfirmationPage;
