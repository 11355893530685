import {TaskStatus} from '../Task/TaskStatus';
import MatchingTaskResponse from '../../../../api/Llasm/shared/Matching/MatchingTaskResponse';

class MatchingTask {
    private _id?: number;

    private _taskStatus: TaskStatus;

    public static createFromMatchingTaskResponse(matchingTaskResponse: MatchingTaskResponse): MatchingTask {
        const matchingTask: MatchingTask = new  this();

        matchingTask._id = matchingTaskResponse.id;
        matchingTask._taskStatus = matchingTaskResponse.taskStatus;

        return matchingTask;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get taskStatus(): TaskStatus {
        return this._taskStatus;
    }

    set taskStatus(value: TaskStatus) {
        this._taskStatus = value;
    }
}

export default MatchingTask;
