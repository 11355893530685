import Place from './Place';
import AutocompletePlaceResponse from '../../../../api/Llasm/shared/Location/AutocompletePlaceResponse';

class AutocompletePlace {
    private _autoCompleteDisplay: string;

    private _place: Place;

    public static createFromAutocompletePlaceResponse(autocompletePlaceResponse: AutocompletePlaceResponse): AutocompletePlace {
        const autocompletePlace: AutocompletePlace = new this();

        autocompletePlace._autoCompleteDisplay = autocompletePlaceResponse.autoCompleteDisplay;
        autocompletePlace._place = Place.createFromPlaceResponse(autocompletePlaceResponse.place);

        return autocompletePlace;
    }

    get autoCompleteDisplay(): string {
        return this._autoCompleteDisplay;
    }

    set autoCompleteDisplay(value: string) {
        this._autoCompleteDisplay = value;
    }

    get place(): Place {
        return this._place;
    }

    set place(value: Place) {
        this._place = value;
    }
}

export default AutocompletePlace;
