import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import Property from '../Entity/Property/Property';
import PropertyInterest from '../Entity/PropertyInterest/PropertyInterest';
import PropertyInterestFeedback from '../Entity/PropertyInterest/PropertyInterestFeedback';
import InterestFeedback from '../Entity/InterestFeedback';
import PropertyInterestService from './PropertyInterestService';
import InterestFeedbackButton from '../Component/InterestFeedbackButton/InterestFeedbackButton';
import LoadingIndicator from '../Component/LoadingIndicator/LoadingIndicator';
import React, {useEffect, useState} from 'react';
import {AxiosError} from 'axios';

interface PropertyInterestButtonProps {
    readonly settlementConcept: SettlementConcept;
    readonly property: Property;
}

const propertyInterestService: PropertyInterestService = new PropertyInterestService(process.env.REACT_APP_LLASM_API_URL!);

const PropertyInterestButton = (props: PropertyInterestButtonProps): React.JSX.Element => {
    const [propertyInterest, setPropertyInterest] = useState<PropertyInterest | null | undefined>();

    useEffect((): void => {
        if (propertyInterest !== undefined)  {
            return;
        }

        if (props.property.propertyInterest !== null) {
            setPropertyInterest(props.property.propertyInterest);
            return;
        }

        fetchPropertyInterest();
    }, []);

    const fetchPropertyInterest = async (): Promise<void> => {
        try {
            const propertyInterest: PropertyInterest = await propertyInterestService.fetchPropertyInterestFromApiByIdentifier(props.settlementConcept.id + '-' + props.property.id);

            setPropertyInterest(propertyInterest);
        } catch (error) {
            if ((error as AxiosError).response!.status !== 404) {
                //TODO throw error
            }

            if ((error as AxiosError).response!.status === 404) {
                setPropertyInterest(null);
            }
        }
    };

    const persistPropertyInterest = async (propertyInterestFeedback: PropertyInterestFeedback): Promise<void> => {
        setPropertyInterest(undefined);

        try {
            const propertyInterest: PropertyInterest = new PropertyInterest();

            propertyInterest.propertyId = props.property.id!;
            propertyInterest.settlementConceptId = props.settlementConcept.id!;
            propertyInterest.propertyInterestFeedback = propertyInterestFeedback;

            const persistedPropertyInterest: PropertyInterest = await propertyInterestService.persistPropertyInterest(propertyInterest);

            setPropertyInterest(persistedPropertyInterest);
        } catch (error) {
            throw error;
        }
    };

    const clickInterested = (): void => {
        persistPropertyInterest(PropertyInterestFeedback.Interested);
    };

    const clickNotInterested = (): void => {
        persistPropertyInterest(PropertyInterestFeedback.NotInterested);
    };

    const mapPropertyInterestFeedbackToInterestFeedback = (propertyInterestFeedback: PropertyInterestFeedback): InterestFeedback => {
        switch (propertyInterestFeedback) {
            case PropertyInterestFeedback.Interested:
                return InterestFeedback.Interested;
            case PropertyInterestFeedback.NotInterested:
                return InterestFeedback.NotInterested;
        }
    };

    if (propertyInterest === undefined) {
        return <LoadingIndicator />;
    }

    return (
        <InterestFeedbackButton
            interestFeedback={propertyInterest !== null ? mapPropertyInterestFeedbackToInterestFeedback(propertyInterest.propertyInterestFeedback) : null}
            onClickInterested={clickInterested}
            onClickNotInterested={clickNotInterested}
        />
    );
};

export default PropertyInterestButton;
