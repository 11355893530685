import MarketplaceService from '../MarketplaceService';
import MarketplaceLot from '../../Entity/Marketplace/MarketplaceLot';
import Property from '../../Entity/Property/Property';
import Alert from '../../Entity/Alert/Alert';
import AlertType from '../../Entity/Alert/AlertType';
import PropertyCard from '../PropertyCard';
import AlertBox from '../../../../components/AlertBox';
import LoadingIndicator from '../../Component/LoadingIndicator/LoadingIndicator';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

interface FreeMarketplaceLotCardProps {
    readonly marketplaceLot: MarketplaceLot;
    readonly canOccupy: boolean;
    readonly onGoBackClick: () => void;
    readonly onOccupied?: (error: boolean) => void;
    readonly alert?: Alert;
}

const marketplaceService: MarketplaceService = new MarketplaceService(process.env.REACT_APP_LLASM_API_URL!);

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const OccupyMarketplaceLot = (props: FreeMarketplaceLotCardProps): React.JSX.Element => {
    const [properties, setProperties] = useState<Property[] | undefined>();

    const [alert, setAlert] = useState<Alert>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect((): void => {
         fetchAvailableProperties();
    }, []);

    const fetchAvailableProperties = async (): Promise<void> => {
        setProperties(await marketplaceService.fetchAvailablePropertiesFromApi());
    };

    const occupy = async (property: Property): Promise<void> => {
        try {
            setIsLoading(true);

            await marketplaceService.occupyMarketplaceLot(props.marketplaceLot, property);

            if (props.onOccupied !== undefined) {
                props.onOccupied(false);
            }
        } catch (error) {
            setAlert(errorAlert);

            if (props.onOccupied !== undefined) {
                props.onOccupied(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const header = (): React.JSX.Element => {
        return (
            <>
                <h3 className="fw-bolder">
                    Stellplatz - Restlaufzeit {props.marketplaceLot.availableNumberOfDays} Tage
                </h3>
                <div className="fs-5 mb-3">
                    Bitte wähle eine deiner Flächen aus, die du auf dem Stellplatz veröffentlichen möchtest.
                </div>
                {props.canOccupy === false &&
                    <div className="fs-5 text-danger mb-5 mt-4">
                        Aktuell kannst Du keine Fläche wählen, da Dein Anbieter-Impressum nicht ausgefüllt ist.
                        Bitte pflege dieses zuerst in der <Link to="/meine-stammdaten">Stammdatenverwaltung</Link> ein.
                    </div>
                }
                {alert !== undefined &&
                    <AlertBox alert={alert} autoDismiss={false} />
                }
                {isLoading === true &&
                    <LoadingIndicator />
                }
            </>
        );
    };

    if (properties === undefined) {
       return <LoadingIndicator />;
    }

    if (isLoading === true) {
        return header();
    }

    if (properties.length === 0) {
        return (
            <>
                {header()}
                <div className="fs-5 mb-3">
                    Aktuell hast du keine Fläche erfasst, die du dem Stellplatz zuweisen kannst.
                </div>
                <div>
                    <button className="btn btn-outline-secondary mx-auto me-3 text-nowrap text-uppercase" onClick={props.onGoBackClick}>
                        Zurück
                    </button>
                    <Link to="/flaechen/anbieten" className="btn btn-secondary mx-auto text-nowrap text-uppercase">
                        Eine Fläche erfassen
                    </Link>
                </div>
            </>
        );
    }

    return (
        <>
            {header()}
            <div className="row">
                {properties.map((property: Property): React.JSX.Element => (
                    <div className="col-12 col-md-6 col-lg-4 mb-4" key={'property' + property.id}>
                        <PropertyCard property={property}>
                            <>
                                {props.canOccupy === true &&
                                    <button
                                        className="btn btn-primary mx-auto text-nowrap text-uppercase w-100 mb-3"
                                        onClick={(): Promise<void> => occupy(property)}
                                    >
                                        Fläche wählen
                                    </button>
                                }
                                {props.canOccupy === false &&
                                    <button className="btn btn-primary mx-auto text-nowrap text-uppercase w-100 mb-3" disabled={true}>
                                        Fläche wählen
                                    </button>
                                }
                            </>
                        </PropertyCard>
                    </div>
                ))}
            </div>
        </>
    );
};

export default OccupyMarketplaceLot;
