import BranchResponse from '../../../../api/Llasm/shared/HallOfInspiration/BranchResponse';

class Branch {
    private _id?: number;

    private _name: string;

    public static createFromBranchResponse(branchResponse: BranchResponse): Branch {
        const branch: Branch = new this();

        branch._id = branchResponse.id;
        branch._name = branchResponse.name;

        return branch;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }
}

export default Branch;
