import ContactService from './ContactService';
import Contact from '../Entity/Contact/Contact';
import FormData from '../Entity/Form/FormData';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import EmailValidationDefinition from '../FormValidationHandler/EmailValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import ContactForm from './ContactForm';
import AlertBox from '../../../components/AlertBox';
import Spinner from '../../../components/Spinner';
import React, {useState} from 'react';

interface CreateContactProps {
    readonly onContactCreated?: (contact: Contact) => void;
    readonly onCloseButtonClick?: () => void;
}

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Du hast nicht alle Pflichtfelder gefüllt. Bitte kontrolliere die mit einem roten Ausrufezeichen markierten Felder.');

const fieldValidationDefinitions: FieldValidationDefinition<Contact>[] = [
    new RequiredValidationDefinition<Contact>('name', 'Name muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Contact>('firstName', 'Vorname muss ausgefüllt sein.'),
    new RequiredValidationDefinition<Contact>('email', 'E-Mail muss ausgefüllt sein.'),
    new EmailValidationDefinition<Contact>('email', 'E-Mail-Format ist nicht korrekt.'),
];

const formValidationHandler: FormValidationHandler<Contact> = new FormValidationHandler<Contact>(fieldValidationDefinitions);

const contactService: ContactService = new ContactService(process.env.REACT_APP_LLASM_API_URL!);

const createContactFormData = (): FormData<Contact> => {
    const contact: Contact = new Contact();

    contact.naturalPerson = false;
    contact.countryName = 'Deutschland';

    return {data: contact};
};

const ContactCreate = (props: CreateContactProps): React.JSX.Element => {
    const [contactFormData, setContactFormData] = useState<FormData<Contact>>(createContactFormData());

    const [alert, setAlert] = useState<Alert>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const persistContact = async (): Promise<void> => {
        formValidationHandler.validate(contactFormData);

        if (formValidationHandler.hasErrors(contactFormData) === true) {
            setAlert(formErrorAlert);

            window.scrollTo(0, 0);

            return;
        }

        setAlert(undefined);

        setIsLoading(true);

        try {
            const contact: Contact = await contactService.persistContact(contactFormData.data);

            if (props.onContactCreated !== undefined) {
                props.onContactCreated(contact);
            }

            setContactFormData(createContactFormData());
        } catch (error) {
            setAlert(errorAlert);

            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <>
            {alert !== undefined &&
                <AlertBox alert={alert} autoDismiss={false} />
            }
            <ContactForm
                formData={contactFormData} setFormData={setContactFormData}
                formValidationHandler={formValidationHandler}
            />
            <button type="submit" className="btn btn-primary align-items-center mt-3" onClick={persistContact}>
                ANSPRECHPARTNER ANLEGEN
            </button>
            {props.onCloseButtonClick !== undefined &&
                <button className="btn align-items-center mt-3" onClick={props.onCloseButtonClick}>
                    SCHLIESSEN
                </button>
            }
        </>
    );
};

export default ContactCreate;
