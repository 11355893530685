import PropertyService from './PropertyService';
import LocalStorageService from '../Storage/LocalStorageService';
import Property from '../Entity/Property/Property';
import PropertyCard from './PropertyCard';
import InitialPropertyCreatedMessage from './InitialPropertyCreatedMessage';
import InfoButtonOverlay from '../Component/InfoButtonOverlay/InfoButtonOverlay';
import Spinner from '../../../components/Spinner';
import {useAppDispatch} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const propertyService: PropertyService = new PropertyService(process.env.REACT_APP_LLASM_API_URL!);
const localStorageService: LocalStorageService = new LocalStorageService();

const PropertyOverviewPage = (): React.JSX.Element => {
    const [properties, setProperties] = useState<Property[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [propertyCreated, setPropertyCreated] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Flächen'},
            ]
        });

        fetchProperties();

        if (localStorageService.getFromStorage('propertyCreated') !== null) {
            setPropertyCreated(true);

            localStorageService.removeFromStorage('propertyCreated');
        }
    }, []);

    const fetchProperties = async (): Promise<void> => {
        setIsLoading(true);

        let properties: Property[];

        properties = await propertyService.fetchPropertiesFromApi();

        setProperties(properties);

        setIsLoading(false);
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="properties">
                <div className="row align-items-center mb-4">
                    <div className="col-12 col-md-8 d-flex align-items-center">
                        <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                        <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">
                            Meine Flächen<span className="d-none d-md-inline">/Ladenlokale</span>
                        </h1>
                    </div>
                    <div className="col-12 col-md-4 d-flex align-items-center justify-content-start justify-content-md-end px-4">
                        <InfoButtonOverlay title="Flächen und Ladenlokale" className="fs-5 ms-1 me-3">
                            <div className="mb-3">
                                Hier findest Du Deine angelegten Flächen/Ladenlokale. Über den Button &raquo;FLÄCHE ANLEGEN&laquo; kannst Du
                                ein neues Objekt erfassen.
                            </div>
                            <div className="mb-3">
                                Nach dem Erstellen und Aktivieren einer Fläche, kannst Du diese ins Matching geben. Drücke dazu einfach den
                                Button &raquo;LET'S MATCH&laquo;. Du erhältst dann von unserem Matching-Algorithmus identifizierte Vorschläge
                                zu passenden Nachnutzern und kannst mit den entsprechenden Konzeptanbietern in Kontakt treten.
                            </div>
                            <div className="mb-3">
                                Alternativ kannst Du eine aktive Fläche auch auf unserem Marktplatz veröffentlichen. Sie ist dann für  alle
                                Nutzer unserer Plattform sichtbar und jeder kann Dich direkt kontaktieren.
                            </div>
                            <div className="mb-3">
                                Die Entscheidung liegt also ganz bei Dir, ob Du diskret nach Nachnutzern suchst, die genau zu Deiner Fläche
                                passen, oder ob Du von einem möglichst breitem Nutzerspektrum gefunden und kontaktiert werden willst.
                            </div>
                        </InfoButtonOverlay>
                        <Link to="/flaechen/anbieten" className="btn btn-secondary d-flex align-items-center text">
                            <i className="bi bi-plus-lg fs-5 px-2"></i>
                            Fläche anlegen
                        </Link>
                    </div>
                </div>
                {propertyCreated === true && properties.length === 1 &&
                    <div className="mb-4">
                        <InitialPropertyCreatedMessage property={properties[0]} />
                    </div>
                }
                <div className="row px-md-2">
                    {properties.map((property: Property): React.JSX.Element => (
                        <div key={property.id} className="col-12 col-md-6 col-xl-4 col-xxl-3 mb-4">
                            <PropertyCard property={property} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PropertyOverviewPage;
