import AdPartnerType from './AdPartnerType';
import AdPartnerResponse from '../../../../api/Llasm/shared/AdPartner/AdPartnerResponse';

class AdPartner {
    private _id?: number;

    private _adPartnerType: AdPartnerType;

    private _name: string;

    private _firstName: string;

    private _lastName: string;

    private _position: string | null = null;

    private _streetName: string;

    private _houseNumber: string;

    private _postalCode: string;

    private _placeName: string;

    private _phoneNumber: string;

    private _descriptionHtml: string;

    private _shortDescription: string | null = null;

    public static createFromAdPartnerResponse(adPartnerResponse: AdPartnerResponse): AdPartner {
        const adPartner: AdPartner = new this();

        adPartner._id = adPartnerResponse.id;
        adPartner._adPartnerType = adPartnerResponse.adPartnerType;
        adPartner._name = adPartnerResponse.name;
        adPartner._firstName = adPartnerResponse.firstName;
        adPartner._lastName = adPartnerResponse.lastName;
        adPartner._position = adPartnerResponse.position;
        adPartner._streetName = adPartnerResponse.streetName;
        adPartner._houseNumber = adPartnerResponse.houseNumber;
        adPartner._postalCode = adPartnerResponse.postalCode;
        adPartner._placeName = adPartnerResponse.placeName;
        adPartner._phoneNumber = adPartnerResponse.phoneNumber;
        adPartner._descriptionHtml = adPartnerResponse.descriptionHtml;
        adPartner._shortDescription = adPartnerResponse.shortDescription;

        return adPartner;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get adPartnerType(): AdPartnerType {
        return this._adPartnerType;
    }

    set adPartnerType(value: AdPartnerType) {
        this._adPartnerType = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get position(): string | null {
        return this._position;
    }

    set position(value: string | null) {
        this._position = value;
    }

    get streetName(): string {
        return this._streetName;
    }

    set streetName(value: string) {
        this._streetName = value;
    }

    get houseNumber(): string {
        return this._houseNumber;
    }

    set houseNumber(value: string) {
        this._houseNumber = value;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    set postalCode(value: string) {
        this._postalCode = value;
    }

    get placeName(): string {
        return this._placeName;
    }

    set placeName(value: string) {
        this._placeName = value;
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }

    set phoneNumber(value: string) {
        this._phoneNumber = value;
    }

    get descriptionHtml(): string {
        return this._descriptionHtml;
    }

    set descriptionHtml(value: string) {
        this._descriptionHtml = value;
    }

    get shortDescription(): string | null {
        return this._shortDescription;
    }

    set shortDescription(value: string | null) {
        this._shortDescription = value;
    }
}

export default AdPartner;
