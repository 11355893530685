import IndustryClassification from '../IndustryClassification/IndustryClassification';
import BranchingDegree from '../BranchingDegree';
import PriceSegment from '../PriceSegment';
import TargetGroup from '../TargetGroup';
import AgeStructure from '../AgeStructure';
import MatchingParameterResponse from '../../../../api/Llasm/shared/Matching/MatchingParameterResponse';
import IndustryClassificationResponse from '../../../../api/Llasm/shared/IndustryClassification/IndustryClassificationResponse';

class MatchingParameter {
    private _ageStructures: AgeStructure[] = [];

    private _targetGroups: TargetGroup[] = [];

    private _priceSegments: PriceSegment[] = [];

    private _branchingDegrees: BranchingDegree[] = [];

    private _industryClassifications: IndustryClassification[] = [];

    public static createFromMatchingParameterResponse(matchingParameterResponse: MatchingParameterResponse): MatchingParameter {
        const matchingParameter: MatchingParameter = new this();

        matchingParameter._ageStructures = matchingParameterResponse.ageStructures;
        matchingParameter._targetGroups = matchingParameterResponse.targetGroups;
        matchingParameter._priceSegments = matchingParameterResponse.priceSegments;
        matchingParameter._branchingDegrees = matchingParameterResponse.branchingDegrees;

        matchingParameter._industryClassifications = matchingParameterResponse.industryClassifications.map((industryClassificationResponse: IndustryClassificationResponse): IndustryClassification => {
            return IndustryClassification.createFromIndustryClassificationResponse(industryClassificationResponse);
        });

        return matchingParameter;
    }

    get ageStructures(): AgeStructure[] {
        return this._ageStructures;
    }

    set ageStructures(value: AgeStructure[]) {
        this._ageStructures = value;
    }

    get targetGroups(): TargetGroup[] {
        return this._targetGroups;
    }

    set targetGroups(value: TargetGroup[]) {
        this._targetGroups = value;
    }

    get priceSegments(): PriceSegment[] {
        return this._priceSegments;
    }

    set priceSegments(value: PriceSegment[]) {
        this._priceSegments = value;
    }

    get branchingDegrees(): BranchingDegree[] {
        return this._branchingDegrees;
    }

    set branchingDegrees(value: BranchingDegree[]) {
        this._branchingDegrees = value;
    }

    get industryClassifications(): IndustryClassification[] {
        return this._industryClassifications;
    }

    set industryClassifications(value: IndustryClassification[]) {
        this._industryClassifications = value;
    }
}

export default MatchingParameter;
