import Contact from '../Entity/Contact/Contact';
import FormData from  '../Entity/Form/FormData';
import SelectOption from '../Entity/Form/SelectOption';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import InputField from '../Component/Form/Field/InputField';
import SelectField from '../Component/Form/Field/SelectField';
import React from 'react';

interface ContactFormProps {
    readonly formData: FormData<Contact>;
    readonly setFormData: (formData: FormData<Contact>) => void;
    readonly formValidationHandler?: FormValidationHandler<Contact>;
}

const naturalPersonSelectOptions: SelectOption<boolean>[] = [
    {label: 'ja', value: true},
    {label: 'nein', value: false},
];

const ContactForm = (props: ContactFormProps): React.JSX.Element => {
    const contact: Contact = props.formData.data;

    const fetchNaturalPersonSelectedOption = (): SelectOption<boolean> | undefined => {
        if (contact.naturalPerson === undefined) {
            return undefined;
        }

        return naturalPersonSelectOptions.find((selectOption: SelectOption<boolean>): boolean => {
            return contact.naturalPerson === selectOption.value;
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        if (event.target.value === '') {
            (contact as any)[event.target.name] = null;
        } else {
            (contact as any)[event.target.name] = event.target.value;
        }

        updateFormData();
        validateField(event.target.name);
    };

    const handleNaturalPersonSelectChange = (selectedValue: SelectOption<boolean> | null): void => {
        if (selectedValue === null) {
            return;
        }

        contact.naturalPerson = selectedValue.value;

        updateFormData();
        validateField('naturalPerson');
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: contact});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <div className="row mb-3">
                <div className="col-md-6 col-lg-3 mb-3">
                    <SelectField
                        name="naturalPerson"
                        label="Natürliche Person (Privatperson)"
                        required={true}
                        options={naturalPersonSelectOptions}
                        value={fetchNaturalPersonSelectedOption()}
                        defaultValue={{label: 'nein', value: false}}
                        placeholder="Bitte wählen"
                        onChange={handleNaturalPersonSelectChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="firstName"
                        label="Vorname"
                        type="text"
                        required={true}
                        value={contact.firstName}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'firstName')}
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="name"
                        label="Name"
                        type="text"
                        required={true}
                        value={contact.name}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'name')}
                    />
                </div>
                {contact.naturalPerson === false &&
                    <>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <InputField
                                name="companyName"
                                label="Firma"
                                type="text"
                                required={false}
                                value={contact.companyName ?? undefined}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <InputField
                                name="position"
                                label="Position"
                                type="text"
                                required={false}
                                value={contact.position ?? undefined}
                                onChange={handleChange}
                            />
                        </div>
                    </>
                }
            </div>
            <div className="row mb-3">
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="streetName"
                        label="Straße"
                        type="text"
                        required={false}
                        value={contact.streetName ?? undefined}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="houseNumber"
                        label="Hausnummer"
                        type="text"
                        required={false}
                        value={contact.houseNumber ?? undefined}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="postalCode"
                        label="PLZ"
                        type="text"
                        required={false}
                        value={contact.postalCode ?? undefined}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="placeName"
                        label="Ort"
                        type="text"
                        required={false}
                        value={contact.placeName ?? undefined}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12 col-lg-6 mb-3">
                    <InputField
                        name="countryName"
                        label="Land"
                        type="text"
                        required={false}
                        value={contact.countryName ?? undefined}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="email"
                        label="E-Mail"
                        type="email"
                        required={true}
                        value={contact.email}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'email')}
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="phoneNumber"
                        label="Telefon"
                        type="text"
                        required={false}
                        value={contact.phoneNumber ?? undefined}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="mobilePhoneNumber"
                        label="Mobil"
                        type="text"
                        required={false}
                        value={contact.mobilePhoneNumber ?? undefined}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="faxNumber"
                        label="Fax"
                        type="text"
                        required={false}
                        value={contact.faxNumber ?? undefined}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <InputField
                        name="website"
                        label="Webseite"
                        type="text"
                        required={false}
                        value={contact.website ?? undefined}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    );
};

export default ContactForm;
