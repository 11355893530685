import Place from '../Location/Place';
import LocationType from '../LocationType';
import LocationCategory from '../LocationCategory';
import LocationFactor from '../LocationFactor';
import LocationDetailResponse from '../../../../api/Llasm/shared/Property/LocationDetailResponse';

class LocationDetail {
    private _countryPlace: Place;

    private _city: Place | undefined;

    private _postalCode: string;

    private _streetName: string | null = null;

    private _houseNumber: string | null = null;

    private _addressAffix: string | null = null;

    private _locationDescription: string | null = null;

    private _locationType: LocationType;

    private _locationCategory: LocationCategory;

    private _locationFactors: LocationFactor[] = [];

    public static createFromLocationDetailResponse(locationDetailResponse: LocationDetailResponse): LocationDetail {
        const locationDetail: LocationDetail = new this();

        locationDetail._countryPlace = Place.createFromPlaceResponse(locationDetailResponse.countryPlace);
        locationDetail._city = Place.createFromPlaceResponse(locationDetailResponse.city);
        locationDetail._postalCode = locationDetailResponse.postalCode;
        locationDetail._streetName = locationDetailResponse.streetName;
        locationDetail._houseNumber = locationDetailResponse.houseNumber;
        locationDetail._addressAffix = locationDetailResponse.addressAffix;
        locationDetail._locationDescription = locationDetailResponse.locationDescription;
        locationDetail._locationType = locationDetailResponse.locationType;
        locationDetail._locationCategory = locationDetailResponse.locationCategory;
        locationDetail._locationFactors = locationDetailResponse.locationFactors;

        return locationDetail;
    }

    get countryPlace(): Place {
        return this._countryPlace;
    }

    set countryPlace(value: Place) {
        this._countryPlace = value;
    }

    get city(): Place | undefined {
        return this._city;
    }

    set city(value: Place | undefined) {
        this._city = value;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    set postalCode(value: string) {
        this._postalCode = value;
    }

    get streetName(): string | null {
        return this._streetName;
    }

    set streetName(value: string | null) {
        this._streetName = value;
    }

    get houseNumber(): string | null {
        return this._houseNumber;
    }

    set houseNumber(value: string | null) {
        this._houseNumber = value;
    }

    get addressAffix(): string | null {
        return this._addressAffix;
    }

    set addressAffix(value: string | null) {
        this._addressAffix = value;
    }

    get locationDescription(): string | null {
        return this._locationDescription;
    }

    set locationDescription(value: string | null) {
        this._locationDescription = value;
    }

    get locationType(): LocationType {
        return this._locationType;
    }

    set locationType(value: LocationType) {
        this._locationType = value;
    }

    get locationCategory(): LocationCategory {
        return this._locationCategory;
    }

    set locationCategory(value: LocationCategory) {
        this._locationCategory = value;
    }

    get locationFactors(): LocationFactor[] {
        return this._locationFactors;
    }

    set locationFactors(value: LocationFactor[]) {
        this._locationFactors = value;
    }
}

export default LocationDetail;
