import ProductType from './ProductType';
import Duration from './Duration';
import ProductResponse from '../../../../api/Llasm/shared/Product/ProductResponse';

class Product {
    private _id?: number;

    private _name: string;

    private _description: string | null = null;

    private _productType: ProductType;

    private _enabled: boolean;

    private _priceNet: number;

    private _priceGross: number;

    private _numberOfMatchPoints: number | null = null;

    private _priceMatchPoints: number | null = null;

    private _duration: Duration | null = null;

    public static createFromProductResponse(productResponse: ProductResponse): Product {
        const product: Product = new this();

        product._id = productResponse.id;
        product._name = productResponse.name;
        product._description = productResponse.description;
        product._productType = productResponse.productType;
        product._enabled = productResponse.enabled;
        product._priceNet = productResponse.priceNet;
        product._priceGross = productResponse.priceGross;
        product._numberOfMatchPoints = productResponse.numberOfMatchPoints;
        product._priceMatchPoints = productResponse.priceMatchPoints;
        product._duration = productResponse.duration;

        return product;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string | null {
        return this._description;
    }

    set description(value: string | null) {
        this._description = value;
    }

    get productType(): ProductType {
        return this._productType;
    }

    set productType(value: ProductType) {
        this._productType = value;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }

    get priceNet(): number {
        return this._priceNet;
    }

    set priceNet(value: number) {
        this._priceNet = value;
    }

    get priceGross(): number {
        return this._priceGross;
    }

    set priceGross(value: number) {
        this._priceGross = value;
    }

    get numberOfMatchPoints(): number | null {
        return this._numberOfMatchPoints;
    }

    set numberOfMatchPoints(value: number | null) {
        this._numberOfMatchPoints = value;
    }

    get priceMatchPoints(): number | null {
        return this._priceMatchPoints;
    }

    set priceMatchPoints(value: number | null) {
        this._priceMatchPoints = value;
    }

    get duration(): Duration | null {
        return this._duration;
    }

    set duration(value: Duration | null) {
        this._duration = value;
    }
}

export default Product;
