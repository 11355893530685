import Message from './Message'
import ProviderSeekerMessageResponse from '../../../../api/Llasm/shared/Messaging/ProviderSeekerMessageResponse';

class ProviderSeekerMessage extends Message {
    public static createFromProviderSeekerMessageResponse(providerSeekerMessageResponse: ProviderSeekerMessageResponse): ProviderSeekerMessage {
        const providerSeekerMessage: ProviderSeekerMessage = new this('providerSeekerMessage' + providerSeekerMessageResponse.id, providerSeekerMessageResponse.messageType);

        providerSeekerMessage.mapFromMessageResponse(providerSeekerMessageResponse);

        return providerSeekerMessage;
    }
}

export default ProviderSeekerMessage;
