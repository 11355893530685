import DocumentEntity from '../Entity/Document/Document';
import Document from './Document';
import BootstrapIcon from '../Component/Icon/BootstrapIcon';
import uploaderStyle from '../Component/Uploader/Uploader.module.scss';
import FontAwesomeIcon from '../Component/Icon/FontAwesomeIcon';
import LoadingIndicator from '../Component/LoadingIndicator/LoadingIndicator';
import documentUploaderStyle from './DocumentUploader.module.scss';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Dropzone, {DropzoneFile, DropzoneOptions} from 'dropzone';

interface DocumentUploaderProps {
    readonly documents: DocumentEntity[];
    readonly isProcessingDocuments: boolean;
    readonly token: string;
    readonly autoProcessQueue: boolean;
    readonly setNumberOfDocumentUploads: (numberOfDocumentUploads: number | ((prevState: number) => number)) => void;
    readonly buildDocumentGetPath?: (document: DocumentEntity) => string;
    readonly buildDocumentPatchPath?: (document: DocumentEntity) => string;
    readonly buildDocumentDeletePath?: (document: DocumentEntity) => string;
    readonly setIsProcessingDocuments?: (isProcessingDocuments: boolean) => void;
    readonly uploadUrl?: string;
    readonly onQueueComplete?: () => void;
    readonly uploadText?: string;
    readonly onDeleted?: () => Promise<void>;
}

interface UploadError {
    readonly file: boolean;
    readonly upload: boolean;
}

const DocumentUploader = forwardRef((props: DocumentUploaderProps, ref: React.ForwardedRef<any>): React.JSX.Element => {
    if (props.autoProcessQueue === true && props.uploadUrl === undefined) {
        throw new Error('If autoProcessQueue is true, please provide an uploadUrl.');
    }

    const dropzoneRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const dropzoneClickableRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const [dropzone, setDropzone] = useState<Dropzone>();

    const [dropzoneFiles, setDropzoneFiles] = useState<DropzoneFile[]>([]);

    const [uploadError, setUploadError] = useState<UploadError>({
        file: false,
        upload: false
    });

    useImperativeHandle(ref, () => ({
        uploadFiles(uploadUrl: string): void {
            dropzone!.on('processing', (dropzoneFile: DropzoneFile): void => {
                dropzone!.options.url = uploadUrl;
            });

            dropzone!.processQueue();
        }
    }));

    useEffect((): () => void => {
        if (dropzoneRef.current === null) {
            return (): void => {
                return;
            };
        }

        if (dropzoneClickableRef.current === null) {
            return (): void => {
                return;
            };
        }

        const dropzoneConfig: DropzoneOptions = {
            url: props.uploadUrl ?? 'https://',
            method: 'post',
            paramName: 'document',
            headers: {
                'Authorization': 'Bearer ' + props.token
            },
            autoProcessQueue: props.autoProcessQueue,
            maxFilesize: 10,
            acceptedFiles: 'application/pdf',
            clickable: dropzoneClickableRef.current,
        };

        const dropzone: Dropzone = new Dropzone(dropzoneRef.current, dropzoneConfig);

        setDropzone(dropzone);

        dropzone.on('addedfile', (addedFile: DropzoneFile): void => {
            const isAlreadyUploaded: boolean = dropzone.getAcceptedFiles().some((dropzoneFile: DropzoneFile): boolean =>  dropzoneFile.name === addedFile.name);

            if (isAlreadyUploaded === true) {
                dropzone.removeFile(addedFile);
            }

            setUploadError({file: false, upload: false});

            props.setNumberOfDocumentUploads((prevState: number) => prevState + 1);
        });

        dropzone.on('removedfile', (removedFile: DropzoneFile): void => {
            props.setNumberOfDocumentUploads((prevState: number) => prevState - 1);
        });

        dropzone.on('addedfiles', (dropzoneFiles: DropzoneFile[]): void => {
            if (props.autoProcessQueue === true) {
                return;
            }

            const timer: number = dropzone.getAcceptedFiles().length > 5 ? 1500 : 500;

            setTimeout(
                (): void => {
                    setDropzoneFiles(dropzone.getAcceptedFiles());
                },
                timer
            );
        });

        dropzone.on('error', (dropzoneFile: DropzoneFile): void => {
            dropzone.removeFile(dropzoneFile);

            if (dropzoneFile.accepted === false) {
                setUploadError((prevState) => ({
                    ...prevState,
                    file: true,
                    upload: false
                }));
            } else {
                setUploadError((prevState) => ({
                    ...prevState,
                    file: false,
                    upload: true
                }));
            }
        });

        dropzone.on('sending', (dropzoneFiles: DropzoneFile[]): void => {
            if (props.setIsProcessingDocuments !== undefined) {
                props.setIsProcessingDocuments(true);
            }
        });

        dropzone.on('queuecomplete', (): void => {
            if (props.onQueueComplete !== undefined) {
                props.onQueueComplete();
            }

            dropzone.removeAllFiles();
        });

        return (): void => {
            dropzone.destroy();
        };
    }, [dropzoneRef.current, dropzoneClickableRef.current]);

    const removeDropzoneFile = (dropzoneFile: DropzoneFile): void => {
        if (dropzone === undefined) {
            return;
        }

        dropzone.removeFile(dropzoneFile);

        setDropzoneFiles(dropzone.getQueuedFiles());
    };

    return (
        <div className={uploaderStyle.uploader}>
            {props.isProcessingDocuments === true &&
                <div className={uploaderStyle.uploaderLoadingIndicatorContainer}>
                    <LoadingIndicator />
                </div>
            }

            <div className="row">
                <div className="col-12 col-xl-3">
                    <div className={['d-flex', 'flex-column', 'justify-content-center', documentUploaderStyle.documentUploader].join(' ')}>
                        <div ref={dropzoneRef} className="dropzone mb-4 mb-xl-0">
                            <div ref={dropzoneClickableRef} className="dz-clickarea w-100 h-100">
                                <p className="text-center fs-3">
                                    {props.uploadText === undefined &&
                                        <span>Zieh hier Dokumente rein</span>
                                    }
                                    {props.uploadText !== undefined &&
                                        <span>{props.uploadText}</span>
                                    }
                                </p>
                                <p className="text-center"><small>(PDF, max. 10MB)</small></p>
                                {uploadError.file === true &&
                                    <p className="text-center text-danger fs-6">
                                        Bitte nur PDF-Dokumente hochladen, die maximal 10MB groß sind!
                                    </p>
                                }
                                {uploadError.upload === true &&
                                    <p className="text-center text-danger fs-6">Dokumente konnten nicht hochgeladen werden!</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-xl-9">
                    {props.documents.map((document: DocumentEntity): React.JSX.Element => (
                        <div key={'document-' + document.id} className="fs-3">
                            <Document
                                document={document}
                                setIsLoading={props.setIsProcessingDocuments}
                                buildDocumentGetPath={props.buildDocumentGetPath}
                                buildDocumentPatchPath={props.buildDocumentPatchPath}
                                buildDocumentDeletePath={props.buildDocumentDeletePath}
                                onDeleted={props.onDeleted}
                            />
                        </div>
                    ))}
                    {dropzoneFiles.map((dropzoneFile: DropzoneFile, index: number): React.JSX.Element => (
                        <div key={'dropzoneFile-' + index} className="fs-4">
                            <FontAwesomeIcon iconName="file-pdf" iconType="regular" className="me-2 fs-1" />
                            <span className="me-4 text-truncate">
                                {dropzoneFile.name}
                            </span>
                            <button
                                type="button"
                                className="btn btn-danger pt-1 pb-1 ps-2 pe-2"
                                onClick={(): void => removeDropzoneFile(dropzoneFile)}
                            >
                                <BootstrapIcon iconName="trash-fill" />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default DocumentUploader;
