import MarketplaceLotPurchaseService from './MarketplaceLotPurchaseService';
import UserService from '../../User/UserService';
import MarketplaceLotDuration, {getMarketplaceLotDurationLabel} from '../../Entity/Marketplace/MarketplaceLotDuration';
import MarketplaceLotPurchase from '../../Entity/Marketplace/MarketplaceLotPurchase';
import Alert from '../../Entity/Alert/Alert';
import AlertType from '../../Entity/Alert/AlertType';
import User from '../../Entity/User/User';
import AuthenticationState from '../../Entity/Authentication/AuthenticationState';
import MatchPointCard from '../../../user/MatchPoint/MatchPointCard';
import Overlay from '../../Component/Overlay/Overlay';
import FancyNumberInputField from '../../Component/FancyNumberInputField/FancyNumberInputField';
import CheckboxField from '../../Component/Form/Field/CheckboxField';
import InfoBox from '../../Component/InfoBox/InfoBox';
import LoadingIndicator from '../../Component/LoadingIndicator/LoadingIndicator';
import {useAppSelector} from '../../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';

interface PurchaseMarketplaceLotOverlayProps {
    readonly show: boolean;
    readonly setShow: (show: boolean) => void;
    readonly onPurchase: (alert: Alert) => void;
}

const minAmountOfMarketplaceLots: number = 1;
const maxAmountOfMarketplaceLots: number = 10;

const possibleMarketplaceLotDurations: MarketplaceLotDuration[] = [
    MarketplaceLotDuration.Ninety,
    MarketplaceLotDuration.OneHundredEighty,
    MarketplaceLotDuration.ThreeHundredSixtyFive,
];

const marketplaceLotPurchaseService: MarketplaceLotPurchaseService = new MarketplaceLotPurchaseService(process.env.REACT_APP_LLASM_API_URL!);
const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);

const createMarketplaceLotPurchase = (): MarketplaceLotPurchase => {
    const amountOfMarketplaceLots: number = 1;
    const marketplaceLotPurchase: MarketplaceLotPurchase = new MarketplaceLotPurchase();

    marketplaceLotPurchase.amountOfMarketplaceLots = amountOfMarketplaceLots;
    marketplaceLotPurchase.marketplaceLotDuration = MarketplaceLotDuration.Ninety;

    marketplaceLotPurchase.amountOfMatchPoints = MarketplaceLotPurchaseService.calculateAmountOfMatchPoints(
        MarketplaceLotDuration.Ninety,
        amountOfMarketplaceLots,
        minAmountOfMarketplaceLots,
        maxAmountOfMarketplaceLots,
        possibleMarketplaceLotDurations
    );

    return marketplaceLotPurchase;
};

const successAlert: Alert = new Alert(AlertType.Success, 'Du hast erfolgreich einen oder mehrere Stellplätze gekauft und kannst diese nun mit einer Fläche belegen.');

const PurchaseMarketplaceLotOverlay = (props: PurchaseMarketplaceLotOverlayProps): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const navigate: NavigateFunction = useNavigate();

    const [user, setUser] = useState<User>();

    const [marketplaceLotPurchase, setMarketplaceLotPurchase] = useState<MarketplaceLotPurchase>(createMarketplaceLotPurchase);

    const [amountOfMarketplaceLots, setAmountOfMarketplaceLots] = useState<number | undefined>(1);

    const [marketplaceLotDuration, setMarketplaceLotDuration] = useState<MarketplaceLotDuration>(MarketplaceLotDuration.Ninety);

    const [purchaseAgreement, setPurchaseAgreement] = useState<boolean>(false);

    const [showPurchaseAgreementError, setShowPurchaseAgreementError] = useState<boolean>(false);

    const [showPurchaseError, setShowPurchaseError] = useState<boolean>(false);

    useEffect((): void => {
        if (props.show === true) {
            fetchUser();
        }
    }, [props.show]);

    useEffect((): void => {
        if (amountOfMarketplaceLots === undefined) {
            return;
        }

        marketplaceLotPurchase.amountOfMarketplaceLots = amountOfMarketplaceLots;

        marketplaceLotPurchase.amountOfMatchPoints = calculateAmountOfMatchPoints(
            marketplaceLotPurchase.marketplaceLotDuration,
            marketplaceLotPurchase.amountOfMarketplaceLots
        );

        setMarketplaceLotPurchase(marketplaceLotPurchase);
    }, [amountOfMarketplaceLots]);

    useEffect((): void => {
        if (marketplaceLotDuration === undefined) {
            return;
        }

        marketplaceLotPurchase.marketplaceLotDuration = marketplaceLotDuration;

        marketplaceLotPurchase.amountOfMatchPoints = calculateAmountOfMatchPoints(
            marketplaceLotPurchase.marketplaceLotDuration,
            marketplaceLotPurchase.amountOfMarketplaceLots
        );

        setMarketplaceLotPurchase(marketplaceLotPurchase);
    }, [marketplaceLotDuration]);

    const fetchUser = async (): Promise<void> => {
        if (authenticatedUser === undefined || authenticatedUser === null) {
            return;
        }

        const user: User = await userService.fetchUserFromApiById(authenticatedUser.user.id!);

        setUser(user);
    };

    const purchaseMarketplaceLot = async (): Promise<void> => {
        if (purchaseAgreement === false) {
            setShowPurchaseAgreementError(true);

            return;
        }

        try {
            await marketplaceLotPurchaseService.purchaseMarketplaceLots(marketplaceLotPurchase);

            setShowPurchaseError(false);
            props.onPurchase(successAlert);
        } catch (error) {
            setShowPurchaseError(true);
        }
    };

    const onPurchaseAgreementChange = (): void => {
        if (purchaseAgreement === false) {
            setShowPurchaseAgreementError(false);
        }

        setPurchaseAgreement(purchaseAgreement === false);
    };

    const handleCloseButtonClick = (): void => {
        props.setShow(false);
    };

    const calculateAmountOfMatchPoints = (marketplaceLotDuration: MarketplaceLotDuration, amountOfMarketplaceLots: number): number => {
        return MarketplaceLotPurchaseService.calculateAmountOfMatchPoints(
            marketplaceLotDuration,
            amountOfMarketplaceLots,
            minAmountOfMarketplaceLots,
            maxAmountOfMarketplaceLots,
            possibleMarketplaceLotDurations
        );
    };

    const buildPurchaseAgreementLabel = (): string => {
        if (amountOfMarketplaceLots === undefined || amountOfMarketplaceLots < 1) {
            throw new Error('Invalid amount of MarketplaceLots.');
        }

        let purchaseAgreementLabel: string = 'Ja, ich möchte '
            + calculateAmountOfMatchPoints(marketplaceLotDuration, amountOfMarketplaceLots)
            + ' MatchPoints ausgeben um '
            + amountOfMarketplaceLots;

        if (amountOfMarketplaceLots === 1) {
            purchaseAgreementLabel += ' Stellplatz mit einer Laufzeit von ';
        } else {
            purchaseAgreementLabel += ' Stellplätze mit einer Laufzeit von jeweils ';
        }

        purchaseAgreementLabel += getMarketplaceLotDurationLabel(marketplaceLotDuration) + 'n zu buchen.';

        return purchaseAgreementLabel;
    };

    return (
        <Overlay show={props.show} setShow={props.setShow} title="Stellplatz buchen">
            <div className="container">
                {user === undefined &&
                    <LoadingIndicator />
                }
                {user !== undefined &&
                    <>
                        <h3>Hier kannst du einen oder mehrere Stellplätze buchen</h3>
                        <div className="mb-3">
                            Bitte wähle die Anzahl und die Laufzeit der Stellplätze, die du buchen möchtest.
                            Es können maximal 10 Stellplätze auf einmal gebucht werden.
                        </div>
                        <div className="mb-4">
                            Nach Ablauf der gebuchten Laufzeit erfolgt keine automatische Verlängerung. Stellplätze laufen nur ab, wenn Sie belegt sind.
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-4 mb-3">
                                <MatchPointCard header="Anzahl der Stellplätze">
                                    <div className="d-flex justify-content-center mt-4">
                                        <FancyNumberInputField
                                            amount={amountOfMarketplaceLots}
                                            setAmount={setAmountOfMarketplaceLots}
                                            min={minAmountOfMarketplaceLots}
                                            max={maxAmountOfMarketplaceLots}
                                        />
                                    </div>
                                </MatchPointCard>
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <MatchPointCard header="Laufzeit der Stellplätze">
                                    <div className="container d-flex align-items-center flex-column">
                                        <div className="mt-3">
                                            {possibleMarketplaceLotDurations.map((possibleMarketplaceLotDuration: MarketplaceLotDuration): React.JSX.Element => (
                                                <div key={'marketplaceLotDuration-' + possibleMarketplaceLotDuration.toString()} className="mb-3">
                                                    <CheckboxField
                                                        name={'marketplaceLotDuration' + possibleMarketplaceLotDuration}
                                                        label={getMarketplaceLotDurationLabel(possibleMarketplaceLotDuration)}
                                                        checked={marketplaceLotDuration === possibleMarketplaceLotDuration}
                                                        onChange={(): void => setMarketplaceLotDuration(possibleMarketplaceLotDuration)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </MatchPointCard>
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <MatchPointCard header="Kosten">
                                    {amountOfMarketplaceLots !== undefined &&
                                        <>
                                            <div className="fs-2 text-center text-primary mt-3">
                                                {calculateAmountOfMatchPoints(marketplaceLotDuration, amountOfMarketplaceLots)} MatchPoints
                                            </div>
                                            {user.numberOfMatchPoints < calculateAmountOfMatchPoints(marketplaceLotDuration, amountOfMarketplaceLots) &&
                                                <div className="text-center text-danger">
                                                    Du hast derzeitig nicht genügend MatchPoints um den Einkauf zu tätigen.
                                                </div>
                                            }
                                        </>
                                    }
                                </MatchPointCard>
                            </div>
                        </div>
                        {process.env.REACT_APP_FRONTEND === 'lean_match' &&
                            <InfoBox cardType="outline" className="p-3 mb-3">
                                Aktuell hast Du nicht genügend MatchPoints, um einen Stellplatz zu kaufen.
                                MatchPoints sind unsere digitale Währung in LeAn MATCH.
                                Als kommunaler Nutzer ist derzeit leider kein Online-Kauf von MatchPoints möglich.
                                Gerne kannst Du aber jederzeit unseren Support unter <a href="mailto:help@llasm.de" className="text-white me-1">help@llasm.de</a>
                                oder Tel.: <a href="tel:+4921714018846" className="text-white">+49 2171 401 88 46</a> kontaktieren.<br/>
                                Gerne schalten wir Dir Stellplätze gegen Rechnung frei. Frag uns einfach nach den
                                Konditionen und wir finden ein passgenaues Angebot für Dich.
                            </InfoBox>
                        }
                        {amountOfMarketplaceLots !== undefined && user.numberOfMatchPoints >= calculateAmountOfMatchPoints(marketplaceLotDuration, amountOfMarketplaceLots) &&
                            <div className="d-flex">
                                <CheckboxField
                                    name="purchaseAgreement"
                                    label={buildPurchaseAgreementLabel()}
                                    checked={purchaseAgreement === true}
                                    onChange={onPurchaseAgreementChange}
                                />
                            </div>
                        }
                        {showPurchaseAgreementError === true &&
                            <div className="alert alert-danger text-center mt-3 mb-3 fs-5">
                                Bitte stimme der Ausgabe von MatchPoints zu.
                            </div>
                        }
                        {showPurchaseError === true &&
                            <div className="alert alert-danger text-center mt-3 mb-3 fs-5">
                                Leider ist beim Buchen etwas schief gelaufen. Bitte versuche es erneut.
                            </div>
                        }
                        <div className="d-flex justify-content-end mt-3">
                            <button type="button" className="btn btn-dark" onClick={handleCloseButtonClick}>Schließen</button>
                            {process.env.REACT_APP_FRONTEND === 'user' &&
                                <>
                                    {amountOfMarketplaceLots !== undefined && user.numberOfMatchPoints < calculateAmountOfMatchPoints(marketplaceLotDuration, amountOfMarketplaceLots) &&
                                        <button className="btn btn-outline-secondary ms-2" onClick={(): void => navigate('/match-points')}>
                                            MatchPoints kaufen
                                        </button>
                                    }
                                </>
                            }
                            <button
                                type="button"
                                className="btn btn-primary ms-2"
                                onClick={purchaseMarketplaceLot}
                                disabled={amountOfMarketplaceLots === undefined || user.numberOfMatchPoints < calculateAmountOfMatchPoints(marketplaceLotDuration, amountOfMarketplaceLots)}
                            >
                                Stellplätze buchen
                            </button>
                        </div>
                    </>
                }
            </div>
        </Overlay>
    );
};

export default PurchaseMarketplaceLotOverlay;
