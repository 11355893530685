import ConceptService from './ConceptService';
import Concept from '../Entity/HallOfInspiration/Concept';
import Branch from '../Entity/HallOfInspiration/Branch';
import Message from '../Entity/HallOfInspiration/Message';
import ImageEntity from '../Entity/Image/Image';
import CompanyValue from '../Entity/CompanyValue';
import CompanyLocation, {getCompanyLocationLabel} from '../Entity/HallOfInspiration/CompanyLocation';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import FormData from '../Entity/Form/FormData';
import MessageForm from './MessageForm';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import MinMaxLengthValidationDefinition from '../FormValidationHandler/MinMaxLengthValidationDefinition';
import Image from '../Image/Image';
import IconBadgeWrapper from '../Component/IconBadge/IconBadgeWrapper';
import CompanyValueIconBadge from './CompanyValueIconBadge';
import TextBadge from '../Component/TextBadge/TextBadge';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import Slider from '../Component/Slider/Slider';
import RoundedContainer from '../Component/RoundedContainer/RoundedContainer';
import Overlay from '../Component/Overlay/Overlay';
import ImageViewer from '../Component/ImageViewer/ImageViewer';
import Card from '../Component/Card/Card';
import AlertBox from '../../../components/AlertBox';
import conceptDetailInformationStyle from './ConceptDetailInformation.module.scss';
import noPicture from '../../../img/no-picture.png';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

interface ConceptDetailInformationProps extends React.PropsWithChildren {
    readonly concept: Concept;
}

const conceptService: ConceptService = new ConceptService(process.env.REACT_APP_LLASM_API_URL!);

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Deine Nachricht konnte leider nicht gesendet werden. Bitte gib eine Nachricht mit mindestens 5 Zeichen ein.');
const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schief gelaufen. Bitte versuche es zu einem späteren Zeitpunkt noch einmal.');
const successAlert: Alert = new Alert(AlertType.Success, 'Deine Nachricht wurde erfolgreich übermittelt.');

const messageFieldValidationDefinitions: FieldValidationDefinition<Message>[] = [
    new RequiredValidationDefinition<Message>('text', 'Die Nachricht muss ausgefüllt sein.'),
    new MinMaxLengthValidationDefinition<Message>('text', 5, null, 'Die Nachricht muss mindestens 5 Zeichen lang sein.'),
];

const formValidationHandler: FormValidationHandler<Message> = new FormValidationHandler<Message>(messageFieldValidationDefinitions);

const ConceptDetailInformation = (props: ConceptDetailInformationProps): React.JSX.Element => {
    const [images, setImages] = useState<ImageEntity[]>();

    const [imageViewerIndex, setImageViewerIndex] = useState<number>(0);

    const [showImageViewerOverlay, setShowImageViewerOverlay] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const [messageFormData, setMessageFormData] = useState<FormData<Message>>({data: new Message()});

    const [showMessageForm, setShowMessageForm] = useState<boolean>(true);

    useEffect((): void => {
        fetchImages();
    }, []);

    const fetchImages = async (): Promise<void> => {
        const images: ImageEntity[] = await conceptService.fetchImagesFromConcept(Number(props.concept.id));

        setImages(images);
    };

    const sendMessage = async (): Promise<void> => {
        formValidationHandler.validate(messageFormData);

        if (formValidationHandler.hasErrors(messageFormData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        try {
            await conceptService.sendMessage(props.concept, messageFormData.data);

            setShowMessageForm(false);
            setAlert(successAlert);
        } catch (error) {
            setAlert(errorAlert);
        }
    };

    const showImageViewer = (imageIndex: number): void => {
        setImageViewerIndex(imageIndex);
        setShowImageViewerOverlay(true);
    };

    const buildImageGetPath = (image: ImageEntity): string => {
        return ConceptService.buildImageApiPath(props.concept, image);
    };

    return (
        <div className="py-3 px-0 px-md-3 mb-3">
            <div className="row mb-5">
                <div className="col-lg-4 col-md-6 col-12 mb-5 mb-md-0">
                    <RoundedContainer>
                        {props.concept.mainImage !== null ? (
                            <Image image={props.concept.mainImage} buildImageGetPath={buildImageGetPath} alt={props.concept.brandName} style={{width: '100%', height: 'auto'}} />
                        ) : (
                            <img src={noPicture} style={{width: '100%', height: 'auto'}} alt="Hall of Inspiration" />
                        )}
                    </RoundedContainer>
                    <IconBadgeWrapper positionTop={-15} positionLeft={5}>
                        {props.concept.companyValues.map((companyValue: CompanyValue): React.JSX.Element => (
                            <CompanyValueIconBadge key={'companyValue-' + companyValue} companyValue={companyValue} />
                        ))}
                    </IconBadgeWrapper>
                </div>
                <div className="col-lg-8 col-md-6 col-12">
                    <span className="fw-bold">Dürfen wir vorstellen:</span>
                    <h1 className="text-primary mt-md-4 mb-3">{props.concept.brandName}</h1>
                    <div className={'fs-5 ' + conceptDetailInformationStyle.textWrap}>
                        {props.concept.conceptDescription}
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12 col-md-10 mb-2">
                    <div className="d-flex flex-wrap gap-2">
                        {props.concept.branches.map((branch: Branch): React.JSX.Element => (
                            <TextBadge key={'branch-' + branch.id} text={branch.name} />
                        ))}
                    </div>
                </div>
                <div className="col-12 col-md-2 fs-5 d-flex justify-content-end mb-2">
                    {props.concept.instagram !== null &&
                        <Link to={props.concept.instagram} target="_blank" className="text-secondary"><i className="bi bi-instagram me-2"></i></Link>
                    }
                    {props.concept.linkedIn !== null &&
                        <Link to={props.concept.linkedIn} target="_blank" className="text-secondary"><i className="bi bi-linkedin me-2"></i></Link>
                    }
                    {props.concept.website !== null &&
                        <Link to={props.concept.website} target="_blank" className="text-secondary"><i className="bi bi-globe2 me-2"></i></Link>
                    }
                </div>
            </div>
            {((images !== undefined && images.length > 0) || props.concept.videos.length > 0) &&
                <CollapseCard className="mb-3" cardType="shadow" title="Licht - Kamera - Action" iconClassName="bi bi-camera-fill text-primary">
                    {images !== undefined && images.length > 0 &&
                        <div className="row">
                            <div className="col-12 mb-3">
                                <Slider
                                    config={{
                                        type: 'slider',
                                        showArrows: true,
                                        arrowsPosition: 'inside',
                                        rewind: true,
                                        perView: 3,
                                        breakpoints: {
                                            992: {
                                                perView: 3
                                            },
                                            768: {
                                                perView: 2
                                            },
                                            480: {
                                                perView: 1
                                            },
                                        }
                                    }}
                                >
                                    {images.map((image: ImageEntity, index: number): React.JSX.Element => (
                                        <div key={'image-' + image.id} className="cursor-pointer" onClick={(): void => {showImageViewer(index);}}>
                                            <RoundedContainer>
                                                <Image
                                                    image={image}
                                                    buildImageGetPath={buildImageGetPath}
                                                    className="w-100"
                                                    style={{objectFit: 'cover', height: '350px'}}
                                                />
                                            </RoundedContainer>
                                        </div>
                                    ))}
                                </Slider>
                                <Overlay title={props.concept.brandName} show={showImageViewerOverlay} setShow={setShowImageViewerOverlay}>
                                    <div className="container">
                                        <ImageViewer images={images} startAt={imageViewerIndex} buildImageGetPath={buildImageGetPath} />
                                    </div>
                                </Overlay>
                            </div>
                        </div>
                    }
                    {props.concept.videos.length > 0 &&
                        <div className="row fs-5">
                            <div className="col-12 d-flex align-items-center">
                                <i className="bi bi-youtube text-primary fs-3 align-middle me-3"></i>
                                <span className="fw-bold align-middle">It's Popcorn time:</span>
                            </div>
                            {props.concept.videos.map((video: string, index: number): React.JSX.Element => (
                                <div key={'video-link-' + index} className="col-12">
                                    <Link to={video} target="_blank">{video}</Link>
                                </div>
                            ))}
                        </div>
                    }
                </CollapseCard>
            }
            {props.concept.usp !== null &&
                <CollapseCard className="mb-3" cardType="shadow" title="Das macht es ganz besonders" iconClassName="bi bi-heart-fill text-primary">
                    <div className={'fs-5 ' + conceptDetailInformationStyle.textWrap}>
                        {props.concept.usp}
                    </div>
                </CollapseCard>
            }
            {props.concept.experience !== null &&
                <CollapseCard className="mb-3" cardType="shadow" title="Erfahrungen & Herausforderungen" iconClassName="bi bi-flag-fill text-primary">
                    <div className={'fs-5 ' + conceptDetailInformationStyle.textWrap}>
                        {props.concept.experience}
                    </div>
                </CollapseCard>
            }
            {props.concept.successStory !== null &&
                <CollapseCard className="mb-3" cardType="shadow" title="Success Story" iconClassName="bi bi-rocket-takeoff-fill text-primary">
                    <div className={'fs-5 ' + conceptDetailInformationStyle.textWrap}>
                        {props.concept.successStory}
                    </div>
                </CollapseCard>
            }
            {props.concept.expansionPlans !== null &&
                <CollapseCard className="mb-3" cardType="shadow" title="Expansionspläne" iconClassName="bi bi-arrows-fullscreen text-primary">
                    <div className={'fs-5 ' + conceptDetailInformationStyle.textWrap}>
                        {props.concept.expansionPlans}
                    </div>
                </CollapseCard>
            }
            <Card className="mb-3" cardType="shadow">
                <div className="row p-3 p-lg-4 p-xl-5 gap-5 gap-md-0">
                    {props.concept.hasContact === true &&
                        <div className="col-12 col-md-6 col-lg-5 mb-3">
                            {alert !== undefined &&
                                <AlertBox alert={alert} autoDismiss={false} />
                            }
                            {showMessageForm === true &&
                                <>
                                    <MessageForm
                                        formData={messageFormData}
                                        setFormData={setMessageFormData}
                                        formValidationHandler={formValidationHandler}
                                    />
                                    <div className="text-center mt-2">
                                        <button className="btn btn-secondary py-1 px-5" onClick={sendMessage}>Absenden</button>
                                    </div>
                                </>
                            }
                        </div>
                    }
                    <div className="col-12 col-md-6 col-lg-7 d-flex d-md-block px-5 gap-4 gap-md-0">
                        <div>
                            <div className="fw-bold">Standort</div>
                            {props.concept.numberOfCompanyLocations === 1 ? (
                                <div style={{fontSize: '11px'}}>1 Standort</div>
                            ) : (
                                <div style={{fontSize: '11px'}}>{props.concept.numberOfCompanyLocations} Standorte</div>
                            )}
                            {props.concept.companyLocations.map((companyLocation: CompanyLocation, index: number): React.JSX.Element => (
                                <div style={{fontSize: '11px'}}>
                                    {index === 0 ? (
                                        <>{getCompanyLocationLabel(companyLocation)}</>
                                    ) : (
                                        <>{', ' + getCompanyLocationLabel(companyLocation)}</>
                                    )}
                                </div>
                            ))}
                        </div>
                        {props.concept.address !== null &&
                            <div>
                                <div className="fw-bold">Firmensitz</div>
                                {(props.concept.address.streetName !== null || props.concept.address.houseNumber !== null) &&
                                    <div style={{fontSize: '11px'}}>
                                        {props.concept.address.streetName !== null &&
                                            <>{props.concept.address.streetName + ' '}</>
                                        }
                                        {props.concept.address.houseNumber !== null &&
                                            <>{props.concept.address.houseNumber}</>
                                        }
                                    </div>
                                }
                                <div style={{fontSize: '11px'}}>
                                    {props.concept.address.postalCode + ' ' + props.concept.address.placeName}
                                </div>
                                {props.concept.address.federalState !== null &&
                                    <div style={{fontSize: '11px'}}>
                                        {props.concept.address.federalState}
                                    </div>
                                }
                                {props.concept.website !== null &&
                                    <Link to={props.concept.website} target="_blank">{props.concept.website}</Link>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ConceptDetailInformation;
