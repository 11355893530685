import User from './User';
import DefaultBillingAddressResponse from '../../../../api/Llasm/shared/User/DefaultBillingAddressResponse';
import hmacSHA512 from 'crypto-js/hmac-sha512';

class BillingAddress {
    private _id?: number;

    private _firstName: string;

    private _lastName: string;

    private _companyName: string | null = null;

    private _streetName: string;

    private _houseNumber: string;

    private _postalCode: string;

    private _placeName: string;

    private _email: string;

    public static createFromBillingAddressResponse(billingAddressResponse: DefaultBillingAddressResponse): BillingAddress {
        const billingAddress: BillingAddress = new this();

        billingAddress._id = billingAddressResponse.id;
        billingAddress._firstName = billingAddressResponse.firstName;
        billingAddress._lastName = billingAddressResponse.lastName;
        billingAddress._companyName = billingAddressResponse.companyName;
        billingAddress._streetName = billingAddressResponse.streetName;
        billingAddress._houseNumber = billingAddressResponse.houseNumber;
        billingAddress._postalCode = billingAddressResponse.postalCode;
        billingAddress._placeName = billingAddressResponse.placeName;
        billingAddress._email = billingAddressResponse.email;

        return billingAddress;
    }

    public static createFromUser(user: User): BillingAddress {
        const billingAddress: BillingAddress = new this();

        billingAddress._firstName = user.firstName;
        billingAddress._lastName = user.lastName;
        billingAddress._companyName = user.companyName;
        billingAddress._streetName = user.streetName;
        billingAddress._houseNumber = user.houseNumber;
        billingAddress._postalCode = user.postalCode;
        billingAddress._placeName = user.placeName;
        billingAddress._email = user.email;

        return billingAddress;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get companyName(): string | null {
        return this._companyName;
    }

    set companyName(value: string | null) {
        this._companyName = value;
    }

    get streetName(): string {
        return this._streetName;
    }

    set streetName(value: string) {
        this._streetName = value;
    }

    get houseNumber(): string {
        return this._houseNumber;
    }

    set houseNumber(value: string) {
        this._houseNumber = value;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    set postalCode(value: string) {
        this._postalCode = value;
    }

    get placeName(): string {
        return this._placeName;
    }

    set placeName(value: string) {
        this._placeName = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    public buildChecksum = (key: string): string => {
        const payload: string = String.prototype.concat(
            this._firstName,
            this._lastName,
            this._companyName ?? '',
            this._streetName,
            this._houseNumber,
            this._postalCode,
            this._placeName,
            this._email
        );

        return hmacSHA512(payload, key).toString();
    }
}

export default BillingAddress;
