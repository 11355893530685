import SettlementConcept from '../SettlementConcept/SettlementConcept';
import MatchingResultStatus from './MatchingResultStatus';
import MatchingResultResponse from '../../../../api/Llasm/shared/Matching/MatchingResultResponse';

class MatchingResult {
    private _id?: number;

    private _settlementConcept: SettlementConcept;

    private _score: number;

    private _maximumPossibleScore: number;

    private _scorePercentage: number;

    private _companyPercentage: number;

    private _areaPercentage: number;

    private _locationPercentage: number;

    private _targetedCitySearchInPropertyCity: boolean;

    private _matchingResultStatuses: MatchingResultStatus[];

    public static createFromMatchingResultResponse(matchingResultResponse: MatchingResultResponse): MatchingResult {
        const matchingResult: MatchingResult = new this();

        matchingResult._id = matchingResultResponse.id;
        matchingResult._settlementConcept = SettlementConcept.createFromSettlementConceptResponse(matchingResultResponse.settlementConcept);
        matchingResult._score = matchingResultResponse.score;
        matchingResult._maximumPossibleScore = matchingResultResponse.maximumPossibleScore;
        matchingResult._scorePercentage = matchingResultResponse.scorePercentage;
        matchingResult._companyPercentage = matchingResultResponse.companyPercentage;
        matchingResult._areaPercentage = matchingResultResponse.areaPercentage;
        matchingResult._locationPercentage = matchingResultResponse.locationPercentage;
        matchingResult._targetedCitySearchInPropertyCity = matchingResultResponse.targetedCitySearchInPropertyCity;
        matchingResult._matchingResultStatuses = matchingResultResponse.matchingResultStatuses;

        return matchingResult;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get settlementConcept(): SettlementConcept {
        return this._settlementConcept;
    }

    set settlementConcept(value: SettlementConcept) {
        this._settlementConcept = value;
    }

    get score(): number {
        return this._score;
    }

    set score(value: number) {
        this._score = value;
    }

    get maximumPossibleScore(): number {
        return this._maximumPossibleScore;
    }

    set maximumPossibleScore(value: number) {
        this._maximumPossibleScore = value;
    }

    get scorePercentage(): number {
        return this._scorePercentage;
    }

    set scorePercentage(value: number) {
        this._scorePercentage = value;
    }

    get companyPercentage(): number {
        return this._companyPercentage;
    }

    set companyPercentage(value: number) {
        this._companyPercentage = value;
    }

    get areaPercentage(): number {
        return this._areaPercentage;
    }

    set areaPercentage(value: number) {
        this._areaPercentage = value;
    }

    get locationPercentage(): number {
        return this._locationPercentage;
    }

    set locationPercentage(value: number) {
        this._locationPercentage = value;
    }

    get targetedCitySearchInPropertyCity(): boolean {
        return this._targetedCitySearchInPropertyCity;
    }

    set targetedCitySearchInPropertyCity(value: boolean) {
        this._targetedCitySearchInPropertyCity = value;
    }

    get matchingResultStatuses(): MatchingResultStatus[] {
        return this._matchingResultStatuses;
    }

    set matchingResultStatuses(value: MatchingResultStatus[]) {
        this._matchingResultStatuses = value;
    }
}

export default MatchingResult;
