import Concept from '../Entity/HallOfInspiration/Concept';
import ConceptFilter from '../Entity/HallOfInspiration/ConceptFilter';
import ConceptPaginated from '../Entity/HallOfInspiration/ConceptPaginated';
import Message from '../Entity/HallOfInspiration/Message';
import SortingOption from '../Entity/SortingOption/SortingOption';
import PaginationParameter from '../Entity/Pagination/PaginationParameter';
import Image from '../Entity/Image/Image';
import Branch from '../Entity/HallOfInspiration/Branch';
import ConceptResponse from '../../../api/Llasm/shared/HallOfInspiration/ConceptResponse';
import ConceptPaginatedResponse from '../../../api/Llasm/shared/HallOfInspiration/ConceptPaginatedResponse';
import ConceptFilterPostRequest from '../../../api/Llasm/shared/HallOfInspiration/ConceptFilterPostRequest';
import MessagePostRequest from '../../../api/Llasm/shared/HallOfInspiration/MessagePostRequest';
import ImageResponse from '../../../api/Llasm/shared/Image/ImageResponse';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import {AxiosResponse} from 'axios';

class ConceptService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/hall-of-inspiration/concepts';
    }

    public async fetchConceptsFromApi(conceptFilter: ConceptFilter, sortingOption?: SortingOption, paginationParameter?: PaginationParameter): Promise<ConceptPaginated> {
        const apiResponse: AxiosResponse<ConceptPaginatedResponse> = await this.llasmApiV1Provider.post<null, AxiosResponse<ConceptPaginatedResponse>, ConceptFilterPostRequest>(
            this.buildConceptSearchPath(sortingOption, paginationParameter),
            ConceptService.mapFromConceptFilterToConceptFilterPostRequest(conceptFilter)
        );

        return ConceptPaginated.createFromConceptPaginatedResponse(apiResponse.data);
    }

    public async fetchConceptFromApiById(id: number): Promise<Concept> {
        const apiResponse: AxiosResponse<ConceptResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + id);

        const conceptResponse: ConceptResponse = apiResponse.data;

        return Concept.createFromConceptResponse(conceptResponse);
    }

    public async fetchImagesFromConcept(conceptId: number): Promise<Image[]> {
        const apiResponse: AxiosResponse<ImageResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + conceptId + '/images');

        return apiResponse.data.map((imageResponse: ImageResponse): Image => {
            return Image.createFromImageResponse(imageResponse);
        });
    }

    public async fetchNumberOfConcepts(): Promise<number> {
        const apiResponse: AxiosResponse<ConceptCountResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/count');

        return apiResponse.data.numberOfConcepts;
    }

    public async sendMessage(concept: Concept, message: Message): Promise<void> {
        const apiResponse: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, MessagePostRequest>(
            this.resourcePath + '/' + concept.id + '/messages',
            ConceptService.mapFromMessageToMessagePostRequest(message)
        );
    }

    public static buildImageApiPath(concept: Concept, image: Image): string {
        return '/hall-of-inspiration/concepts/' + concept.id + '/images/' + image.id;
    }

    private buildConceptSearchPath(sortingOption?: SortingOption, paginationParameter?: PaginationParameter): string {
        const resourcePath: string = '/hall-of-inspiration/concepts/search';

        const searchParams: URLSearchParams = new URLSearchParams();

        if (sortingOption !== undefined) {
            searchParams.set('sorting-by', sortingOption.sortingBy);
            searchParams.set('direction', sortingOption.sortingDirection.toString());
        }

        if (paginationParameter !== undefined) {
            searchParams.set('page', paginationParameter.page.toString());
            searchParams.set('limit', paginationParameter.limit.toString());
        }

        if (searchParams.size > 0) {
            return resourcePath + '?' + searchParams.toString();
        } else {
            return resourcePath;
        }
    }

    private static mapFromConceptFilterToConceptFilterPostRequest(conceptFilter: ConceptFilter): ConceptFilterPostRequest {
        return {
            companyValues: conceptFilter.companyValues,
            branchIds: conceptFilter.branches.map((branch: Branch): number => {
                return branch.id!;
            })
        };
    }

    private static mapFromMessageToMessagePostRequest(message: Message): MessagePostRequest {
        return {
            text: message.text
        };
    }
}

export default ConceptService;
