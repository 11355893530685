import UserService from '../../../domain/shared/User/UserService';
import AuthenticationState from '../../shared/Entity/Authentication/AuthenticationState';
import FormData from '../../shared/Entity/Form/FormData';
import Alert from '../../shared/Entity/Alert/Alert';
import AlertType from '../../shared/Entity/Alert/AlertType';
import EmailValidationDefinition from '../../shared/FormValidationHandler/EmailValidationDefinition';
import RequiredValidationDefinition from '../../shared/FormValidationHandler/RequiredValidationDefinition';
import FieldValidationDefinition from '../../shared/FormValidationHandler/FieldValidationDefinition';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import UserPasswordResetDemandForm from '../../shared/User/UserPasswordResetDemandForm';
import UserPasswordResetDemandPostRequest from '../../../api/Llasm/shared/User/UserPasswordResetDemandPostRequest';
import Spinner from '../../../components/Spinner';
import AlertBox from '../../../components/AlertBox';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link, NavigateFunction, useNavigate} from 'react-router-dom';

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Bitte gib Deine E-Mail-Adresse an.');

const fieldValidationDefinitions: FieldValidationDefinition<UserPasswordResetDemandPostRequest>[] = [
    new RequiredValidationDefinition<UserPasswordResetDemandPostRequest>('email', 'E-Mail muss ausgefüllt sein.'),
    new EmailValidationDefinition<UserPasswordResetDemandPostRequest>('email', 'Das E-Mail Format ist nicht korrekt'),
];

const formValidationHandler: FormValidationHandler<UserPasswordResetDemandPostRequest> = new FormValidationHandler<UserPasswordResetDemandPostRequest>(fieldValidationDefinitions);

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);

const UserPasswordResetDemandPage = (): React.JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [formData, setFormData] = useState<FormData<UserPasswordResetDemandPostRequest>>({data: {email: ''}});

    const [userPasswordResetRequestSuccessful, setUserPasswordResetRequestSuccessful] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const navigate: NavigateFunction = useNavigate();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Passwort vergessen'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        if (authenticatedUser !== null) {
            navigate('/');
        }
    }, [authenticatedUser, navigate]);

    const persistUserPasswordResetDemand = async (): Promise<void> => {
        formValidationHandler.validate(formData!);

        if (formValidationHandler.hasErrors(formData!) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setIsLoading(true);

        try {
            await userService.persistUserPasswordResetDemand(formData!.data);

            setUserPasswordResetRequestSuccessful(true);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="row g-3 mt-1 mb-3">
            <div className="col-12 pt-1 px-md-3">
                <h1 className="fw-bold text-secondary fs-2 mb-4 mb-lg-5">Ladenfläche trifft Nachmieter:<br />Match mit!</h1>
                <h2 className="fs-3 fw-bold ls-1 mt-4">Passwort vergessen?</h2>
                <p className="text-muted mb-4">Kein Problem! Wir senden Dir eine E-Mail zu, mit der Du Dein Passwort zurücksetzen kannst.</p>
            </div>
            <div className="d-flex flex-column px-md-3 justify-content-between flex-grow-1 gap-3">
                {alert !== undefined &&
                    <AlertBox alert={alert} autoDismiss={false} />
                }
            </div>
            {userPasswordResetRequestSuccessful ? (
                <div className="alert alert-primary px-md-3 mb-4" role="alert">
                    Deine Anfrage war erfolgreich. Du erhältst in kürze eine E-Mail mit der
                    Möglichkeit Dein Passwort zu setzen. Danach kannst Du Dich <Link to="/login">einloggen</Link>.
                </div>
            ) : (
                <div className="col-12 pt-1 text-start px-md-3">
                    <div className="mb-3">
                        <UserPasswordResetDemandForm formData={formData} setFormData={setFormData} formValidationHandler={formValidationHandler} />
                    </div>
                    <div className="mb-3 text-center">
                        <button type="submit" className="btn btn-primary mx-auto mb-3" onClick={persistUserPasswordResetDemand}>Passwort jetzt zurücksetzen</button>
                    </div>
                    <div className="mb-3 text-center">
                        <Link to="/login" type="button" className="btn mx-auto mb-5" >Abbrechen</Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserPasswordResetDemandPage;
