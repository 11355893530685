import UserService from '../../User/UserService';
import MatchingService from '../../Matching/MatchingService';
import AuthenticationState from '../../Entity/Authentication/AuthenticationState';
import User from '../../Entity/User/User';
import SettlementConceptActivationContingent from '../../Entity/SettlementConcept/SettlementConceptActivationContingent';
import MatchingResultGroup from '../../Entity/Matching/MatchingResultGroup';
import Overlay from '../../Component/Overlay/Overlay';
import LoadingIndicator from '../../Component/LoadingIndicator/LoadingIndicator';
import {useAppSelector} from '../../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';

interface UnlockMatchingResultGroupsOverlayProps {
    readonly matchingResultGroups: MatchingResultGroup[];
    readonly show: boolean;
    readonly setShow: (show: boolean) => void;
    readonly amount: number;
    readonly price: number;
    readonly settlementConceptActivationContingent: SettlementConceptActivationContingent;
    readonly onUnlockMatchingResultsOverlayClose?: () => void;
}

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);

const matchingService: MatchingService = new MatchingService(process.env.REACT_APP_LLASM_API_URL!);

const UnlockMatchingResultGroupsOverlay = (props: UnlockMatchingResultGroupsOverlayProps): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const navigate: NavigateFunction = useNavigate();

    const [user, setUser] = useState<User>();

    const [showAgreementError, setShowAgreementError] = useState<boolean>(false);

    const [purchaseAgreement, setPurchaseAgreement] = useState<boolean>(false);

    const [showApiError, setShowApiError] = useState<boolean>(false);

    const [showApiSuccess, setShowApiSuccess] = useState<boolean>(false);

    const [showActionButton, setShowActionButton] = useState<boolean>(false);

    useEffect((): void => {
        if (props.show === true) {
            fetchUser();
        }
    }, [props.show]);

    useEffect((): void => {
        if (user === undefined) {
            return;
        }

        if (user.numberOfMatchPoints >= props.price) {
            setShowActionButton(true);
        }
    }, [props.price, user]);

    const fetchUser = async (): Promise<void> => {
        if (authenticatedUser === undefined || authenticatedUser === null) {
            return;
        }

        const user: User = await userService.fetchUserFromApiById(authenticatedUser.user.id!);

        setUser(user);
    };

    const activate = async (): Promise<void> => {
        if (purchaseAgreement === false) {
            setShowAgreementError(true);

            return;
        }

        const matchingResultGroups: MatchingResultGroup[] = [];

        props.matchingResultGroups.forEach((matchingResultGroup: MatchingResultGroup): void => {
            if (matchingResultGroups.length === props.amount) {
                return;
            }

            if (matchingResultGroup.locked === false) {
                return;
            }

            matchingResultGroups.push(matchingResultGroup);
        });

        try {
            await matchingService.activateMatchingResultGroups(matchingResultGroups, props.settlementConceptActivationContingent);

            setShowApiSuccess(true);
            setShowApiError(false);
        } catch (error) {
            setShowApiError(true);
            setShowApiSuccess(false);
        }
    };

    const handlePurchaseAgreementCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPurchaseAgreement(event.target.checked);

        if (showAgreementError === true && event.target.checked === true) {
            setShowAgreementError(false);
        }
    };

    const handleCloseButtonClick = (): void => {
        props.setShow(false);

        if (props.onUnlockMatchingResultsOverlayClose !== undefined) {
            props.onUnlockMatchingResultsOverlayClose();
        }
    };

    return (
        <Overlay show={props.show} setShow={props.setShow} title="Ergebnisse freischalten" onClose={props.onUnlockMatchingResultsOverlayClose}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                           <span className="fs-5">
                               Prima, Dein Matching hat passende Suchprofile für Dein Objekt geliefert.
                           </span>
                    </div>
                    <div className="col-12 pt-1">
                        Du kannst Dir nun alle Informationen inkl. der Möglichkeit zur Kontaktaufnahme mit dem Suchprofilanbieter freischalten.
                    </div>
                    {user === undefined &&
                        <div className="p-3">
                            <div className="d-flex justify-content-center align-items-center">
                                <LoadingIndicator />
                            </div>
                        </div>
                    }
                </div>
                {user !== undefined &&
                    <div className="row">
                        <div className="col-12 pt-2">
                            {props.amount === 1 &&
                                <div className="alert alert-light text-center fs-3 mb-3">
                                    {props.amount} Ergebnis für {props.price} MatchPoints freischalten
                                </div>
                            }
                            {props.amount > 1 &&
                                <div className="alert alert-light text-center fs-3 mb-3">
                                    {props.amount} Ergebnisse für {props.price} MatchPoints freischalten
                                </div>
                            }
                        </div>
                        {user.numberOfMatchPoints < props.price &&
                            <div className="col-12">
                                <div className="alert alert-danger text-center mb-3">
                                    <span className="fs-3">
                                        Du hast derzeit nicht genügend MatchPoints für die Freischaltung!
                                    </span>
                                    <button className="btn btn-secondary fs-5" onClick={(): void => navigate('/match-points')}>MatchPoints kaufen</button>
                                </div>
                            </div>
                        }
                        {showAgreementError === true &&
                            <div className="col-12">
                                <div className="alert alert-danger text-center mb-3 fs-3">
                                    Bitte stimme der Ausgabe der MatchPoints zu.
                                </div>
                            </div>
                        }
                        {showApiError === true &&
                            <div className="col-12">
                                <div className="alert alert-danger text-center mb-3 fs-3">
                                    Es gab einen Fehler beim Freischalten
                                    {props.amount === 1 &&
                                        <> des Suchprofils, </>
                                    }
                                    {props.amount > 1 &&
                                        <> der Suchprofile, </>
                                    }
                                    bitte versuche es erneut.
                                </div>
                            </div>
                        }
                        {showApiSuccess === true &&
                            <div className="col-12">
                                <div className="alert alert-success text-center mb-3 fs-3">
                                    Die Freischaltung war erfolgreich.
                                </div>
                            </div>
                        }
                        {user.numberOfMatchPoints >= props.price && showApiSuccess !== true &&
                            <div className="col-12">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={purchaseAgreement}
                                    id="purchaseAgreementCheckbox"
                                    name="purchaseAgreementCheckbox"
                                    onChange={handlePurchaseAgreementCheckbox}
                                />
                                <label className="form-check-label ms-2" htmlFor="purchaseAgreementCheckbox">
                                    Ja, ich möchte {props.price} MatchPoints ausgeben, um {props.amount}
                                    {props.amount === 1 &&
                                        <> Ergebnis, </>
                                    }
                                    {props.amount > 1 &&
                                        <> Ergebnisse, </>
                                    }
                                    freizuschalten
                                </label>
                            </div>
                        }
                        {showApiSuccess === false &&
                            <div className="pt-3 pt-xl-0 offset-lg-6 col-4 col-lg-2">
                                <button type="button" className="btn btn-dark" onClick={handleCloseButtonClick}>Schließen</button>
                            </div>
                        }
                        {showApiSuccess === true &&
                            <div className="pt-3 pt-xl-0 col-12 d-flex flex-row-reverse">
                                <button type="button" className="btn btn-dark" onClick={handleCloseButtonClick}>Schließen</button>
                            </div>
                        }
                        {(showApiSuccess === false && showActionButton === true) &&
                            <div className="pt-3 pt-xl-0 col-8 col-lg-4">
                                <button type="button" className="btn btn-primary" onClick={activate}>Ergebnisse freischalten</button>
                            </div>
                        }
                    </div>
                }
            </div>
        </Overlay>
    );
};

export default UnlockMatchingResultGroupsOverlay;
