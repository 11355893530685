import BuildingCondition from './BuildingCondition';
import MonumentProtectionType from './MonumentProtectionType';
import InternetConnectionType from './InternetConnectionType';
import LiftType from './LiftType';
import Feature from './Feature';
import AdditionalDetailResponse from '../../../../api/Llasm/shared/Property/AdditionalDetailResponse';

class AdditionalDetail {
    private _buildingCondition: BuildingCondition | null = null;

    private _monumentProtectionType: MonumentProtectionType | null = null;

    private _internetConnectionTypes: InternetConnectionType[] = [];

    private _ceilingHeight: number | null = null;

    private _floorLoadCapacity: number | null = null;

    private _numberOfEntrances: number | null = null;

    private _liftTypes: LiftType[] = [];

    private _features: Feature[] = [];

    private _generalFurnishing: string | null = null;

    private _storeSpaceInterestPopupConcept: boolean;

    private _storeSpaceInterestMixedUseConcept: boolean;

    public static createFromAdditionalDetailResponse(additionalDetailResponse: AdditionalDetailResponse): AdditionalDetail {
        const additionalDetail: AdditionalDetail = new this();

        additionalDetail._buildingCondition = additionalDetailResponse.buildingCondition;
        additionalDetail._monumentProtectionType = additionalDetailResponse.monumentProtectionType;
        additionalDetail._internetConnectionTypes = additionalDetailResponse.internetConnectionTypes;
        additionalDetail._ceilingHeight = additionalDetailResponse.ceilingHeight;
        additionalDetail._floorLoadCapacity = additionalDetailResponse.floorLoadCapacity;
        additionalDetail._numberOfEntrances = additionalDetailResponse.numberOfEntrances;
        additionalDetail._liftTypes = additionalDetailResponse.liftTypes;
        additionalDetail._features = additionalDetailResponse.features;
        additionalDetail._generalFurnishing = additionalDetailResponse.generalFurnishing;
        additionalDetail._storeSpaceInterestPopupConcept = additionalDetailResponse.storeSpaceInterestPopupConcept;
        additionalDetail._storeSpaceInterestMixedUseConcept = additionalDetailResponse.storeSpaceInterestMixedUseConcept;

        return additionalDetail;
    }

    get buildingCondition(): BuildingCondition | null {
        return this._buildingCondition;
    }

    set buildingCondition(value: BuildingCondition | null) {
        this._buildingCondition = value;
    }

    get monumentProtectionType(): MonumentProtectionType | null {
        return this._monumentProtectionType;
    }

    set monumentProtectionType(value: MonumentProtectionType | null) {
        this._monumentProtectionType = value;
    }

    get internetConnectionTypes(): InternetConnectionType[] {
        return this._internetConnectionTypes;
    }

    set internetConnectionTypes(value: InternetConnectionType[]) {
        this._internetConnectionTypes = value;
    }

    get ceilingHeight(): number | null {
        return this._ceilingHeight;
    }

    set ceilingHeight(value: number | null) {
        this._ceilingHeight = value;
    }

    get floorLoadCapacity(): number | null {
        return this._floorLoadCapacity;
    }

    set floorLoadCapacity(value: number | null) {
        this._floorLoadCapacity = value;
    }

    get numberOfEntrances(): number | null {
        return this._numberOfEntrances;
    }

    set numberOfEntrances(value: number | null) {
        this._numberOfEntrances = value;
    }

    get liftTypes(): LiftType[] {
        return this._liftTypes;
    }

    set liftTypes(value: LiftType[]) {
        this._liftTypes = value;
    }

    get features(): Feature[] {
        return this._features;
    }

    set features(value: Feature[]) {
        this._features = value;
    }

    get generalFurnishing(): string | null {
        return this._generalFurnishing;
    }

    set generalFurnishing(value: string | null) {
        this._generalFurnishing = value;
    }

    get storeSpaceInterestPopupConcept(): boolean {
        return this._storeSpaceInterestPopupConcept;
    }

    set storeSpaceInterestPopupConcept(value: boolean) {
        this._storeSpaceInterestPopupConcept = value;
    }

    get storeSpaceInterestMixedUseConcept(): boolean {
        return this._storeSpaceInterestMixedUseConcept;
    }

    set storeSpaceInterestMixedUseConcept(value: boolean) {
        this._storeSpaceInterestMixedUseConcept = value;
    }

    public hasContent(): boolean {
        return (
            this._buildingCondition !== null
            || this._monumentProtectionType !== null
            || this._internetConnectionTypes.length > 0
            || this._ceilingHeight !== null
            || this._floorLoadCapacity !== null
            || this._numberOfEntrances !== null
            || this._liftTypes.length > 0
            || this._features.length > 0
            || this._generalFurnishing !== null
            || this._storeSpaceInterestPopupConcept === true
            || this._storeSpaceInterestMixedUseConcept === true
        );
    }
}

export default AdditionalDetail;
