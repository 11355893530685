import AddressResponse from '../../../../api/Llasm/shared/HallOfInspiration/AddressResponse';

class Address {
    private _id?: number;

    private _streetName: string | null = null;

    private _houseNumber: string | null = null;

    private _postalCode: string;

    private _placeName: string;

    private _federalState: string | null = null;

    public static createFromAddressResponse(addressResponse: AddressResponse): Address {
        const address: Address = new this();

        address._id = addressResponse.id;
        address._streetName = addressResponse.streetName;
        address._houseNumber = addressResponse.houseNumber;
        address._postalCode = addressResponse.postalCode;
        address._placeName = addressResponse.placeName;
        address._federalState = addressResponse.federalState;

        return address;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get streetName(): string | null {
        return this._streetName;
    }

    set streetName(value: string | null) {
        this._streetName = value;
    }

    get houseNumber(): string | null {
        return this._houseNumber;
    }

    set houseNumber(value: string | null) {
        this._houseNumber = value;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    set postalCode(value: string) {
        this._postalCode = value;
    }

    get placeName(): string {
        return this._placeName;
    }

    set placeName(value: string) {
        this._placeName = value;
    }

    get federalState(): string | null {
        return this._federalState;
    }

    set federalState(value: string | null) {
        this._federalState = value;
    }
}

export default Address;
