import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import IndustryClassification from '../Entity/IndustryClassification/IndustryClassification';
import IndustryClassificationResponse from '../../../api/Llasm/shared/IndustryClassification/IndustryClassificationResponse';
import { AxiosResponse } from 'axios';

class IndustryClassificationService {

    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/industry-classifications';
    }

    public async fetchIndustryClassificationsFromApi(): Promise<IndustryClassification[]> {
        const apiResponse: AxiosResponse<IndustryClassificationResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath);

        return apiResponse.data.map((industryClassificationResponse: IndustryClassificationResponse): IndustryClassification => {
            return IndustryClassification.createFromIndustryClassificationResponse(industryClassificationResponse);
        });
    }

    public async fetchIndustryClassificationFromApiById(id: number): Promise<IndustryClassification> {
        const apiResponse: AxiosResponse<IndustryClassificationResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + id);

        return IndustryClassification.createFromIndustryClassificationResponse(apiResponse.data);
    }

}

export default IndustryClassificationService;
