import FormData from '../Entity/Form/FormData';
import SelectOption from '../Entity/Form/SelectOption';
import Contact from '../Entity/Contact/Contact';
import ContactService from './ContactService';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import ContactCreate from'./ContactCreate';
import Overlay from '../Component/Overlay/Overlay';
import MultiSelectField from '../Component/Form/Field/MultiSelectField';
import LoadingIndicator from '../Component/LoadingIndicator/LoadingIndicator';
import React, {useEffect, useState} from 'react';

interface SelectOrCreateContactProps {
    readonly formData: FormData<any> ;
    readonly setFormData: (formData: FormData<any>) => void;
    readonly validateField?: (fieldName: string) => void;
}

const contactService: ContactService = new ContactService(process.env.REACT_APP_LLASM_API_URL!);

const SelectOrCreateContact = (props: SelectOrCreateContactProps): React.JSX.Element => {
    const [contactSelectOptions, setContactSelectOptions] = useState<SelectOption<Contact>[] | undefined>(undefined);

    const [showCreateContactOverlay, setShowCreateContactOverlay] = useState<boolean>(false);

    useEffect((): void => {
        fetchContactSelectOptions();
    }, []);

    const fetchContactSelectOptions = async (): Promise<void> => {
        const selectOptions: SelectOption<Contact>[] = (await (contactService.fetchContactsFromApi())).map((contact: Contact): SelectOption<Contact> => {
            return {
                label: contact.buildDisplayName(),
                value: contact
            };
        });

        setContactSelectOptions(selectOptions);
    };

    const handleContactsChange = (selectedContacts: readonly SelectOption<Contact>[]): void => {
        props.formData.data.contacts = selectedContacts.map((contactSelectOption: SelectOption<Contact>): Contact => {
            return contactSelectOption.value;
        });

        props.setFormData({...props.formData, data: props.formData.data});

        if (props.validateField !== undefined) {
            props.validateField('contacts');
        }
    };

    const fetchContactSelectedOptions = (): SelectOption<Contact>[] => {
        if (contactSelectOptions === undefined) {
            return [];
        }

        return contactSelectOptions.filter((contactSelectOption: SelectOption<Contact>): boolean => {
            const contact: Contact | undefined = props.formData.data.contacts.find((contact: Contact): boolean => {
                return contact.id === contactSelectOption.value.id;
            });

            return contact !== undefined;
        });
    };

    const onContactCreated = (contact: Contact): void => {
        setShowCreateContactOverlay(false);

        contactSelectOptions?.push( {
            label: contact.buildDisplayName(),
            value: contact
        });

        props.formData.data.contacts.push(contact);

        if (props.validateField !== undefined) {
            props.validateField('contacts');
        }
    };

    const onCloseButtonClick = (): void => {
        setShowCreateContactOverlay(false);
    };

    if (contactSelectOptions === undefined) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <div className="row align-items-end">
                <div className="col-md-8">
                    <MultiSelectField
                        label="Ansprechpartner"
                        name="contacts"
                        required={true}
                        options={contactSelectOptions}
                        closeMenuOnSelect={false}
                        placeholder="Bitte einen oder mehrere Ansprechpartner auswählen"
                        onChange={handleContactsChange}
                        value={fetchContactSelectedOptions()}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'contacts')}
                    />
                </div>
                <div className="col-md-4">
                    <button type="button" className="btn btn-secondary" onClick={(): void => setShowCreateContactOverlay(true)}>
                        <i className="bi bi-plus-lg fs-6 px-2"></i>Ansprechpartner anlegen
                    </button>
                </div>
            </div>
            <Overlay show={showCreateContactOverlay} setShow={setShowCreateContactOverlay} title="Neuen Ansprechpartner anlegen">
                <div className="container">
                    <ContactCreate onContactCreated={onContactCreated} onCloseButtonClick={onCloseButtonClick} />
                </div>
            </Overlay>
        </>
    );
};

export default SelectOrCreateContact;
