import ParticipatingUser from './ParticipatingUser';
import SettlementConceptResponse from '../../../../api/Llasm/shared/Messaging/SettlementConceptResponse';

class SettlementConcept {
    private _id: number;

    private _reference: string;

    private _title: string;

    private _participatingUser: ParticipatingUser;

    public static createFromSettlementConceptResponse(settlementConceptResponse: SettlementConceptResponse): SettlementConcept {
        const settlementConcept: SettlementConcept = new this();

        settlementConcept._id = settlementConceptResponse.id;
        settlementConcept._reference = settlementConceptResponse.reference;
        settlementConcept._title = settlementConceptResponse.title;
        settlementConcept._participatingUser = ParticipatingUser.createFromParticipatingUserResponse(settlementConceptResponse.participatingUser);

        return settlementConcept;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get reference(): string {
        return this._reference;
    }

    set reference(value: string) {
        this._reference = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get participatingUser(): ParticipatingUser {
        return this._participatingUser;
    }

    set participatingUser(value: ParticipatingUser) {
        this._participatingUser = value;
    }
}

export default SettlementConcept;
