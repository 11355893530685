import App from '../App';
import AuthenticationTokenExchangePage from '../domain/shared/Authentication/AuthenticationTokenExchangePage';
import LoginPage from '../domain/lean_match/Login/LoginPage';
import UserProfilePage from '../domain/shared/User/UserProfilePage';
import MessagingPage from '../domain/shared/Messaging/MessagingPage';
import StartPage from '../domain/lean_match/StartPage';
import ContactOverviewPage from '../domain/shared/Contact/ContactOverviewPage';
import ContactCreatePage from '../domain/shared/Contact/ContactCreatePage';
import ContactPage from '../domain/shared/Contact/ContactPage';
import PropertyOverviewPage from '../domain/shared/Property/PropertyOverviewPage';
import PropertyCreatePage from '../domain/shared/Property/PropertyCreatePage';
import PropertyPage from '../domain/shared/Property/PropertyPage';
import MatchingOverviewPage from '../domain/shared/Property/Matching/MatchingOverviewPage';
import PropertyInitialEntryPage from '../domain/shared/Property/PropertyInitialEntryPage';
import PublicPageLayout from '../domain/lean_match/Public/PublicPageLayout';
import UserActivationPage from '../domain/user/User/UserActivationPage';
import UserPasswordResetDemandPage from '../domain/user/User/UserPasswordResetDemandPage';
import UserPasswordResetPage from '../domain/user/User/UserPasswordResetPage';
import UserEmailChangeConfirmationPage from '../domain/shared/User/UserEmailChangeConfirmationPage';
import ImprintPage from '../domain/lean_match/Public/ImprintPage';
import GtcPage from '../domain/lean_match/Public/GtcPage';
import PrivacyPolicyPage from '../domain/lean_match/Public/PrivacyPolicyPage';
import ConceptOverviewPage from '../domain/shared/HallOfInspiration/ConceptOverviewPage';
import MarketplaceAdOverviewPage from '../domain/shared/Marketplace/MarketplaceAd/MarketplaceAdOverviewPage';
import MarketplaceLotOverviewPage from '../domain/shared/Marketplace/MarketplaceLot/MarketplaceLotOverviewPage';
import {Router} from '@remix-run/router';
import {createBrowserRouter} from 'react-router-dom';

const leanMatchRouter: Router = createBrowserRouter([
    {
        element: <App />,
        children: [
            {
                path: '/',
                element: <StartPage />
            },
            {
                path: 'meine-stammdaten',
                element: <UserProfilePage />
            },
            {
                path: 'konversationen',
                element: <MessagingPage />
            },
            {
                path: 'ansprechpartner',
                element: <ContactOverviewPage />
            },
            {
                path: 'ansprechpartner/anlegen',
                element: <ContactCreatePage />
            },
            {
                path: 'ansprechpartner/:contactId',
                element: <ContactPage />
            },
            {
                path: 'flaechen',
                element: <PropertyOverviewPage />
            },
            {
                path: 'flaechen/anbieten',
                element: <PropertyCreatePage />
            },
            {
                path: 'flaechen/:propertyId',
                element: <PropertyPage />
            },
            {
                path: 'flaechen/:propertyId/matching',
                element: <MatchingOverviewPage />
            },
            {
                path: 'flaechen/ersterfassung',
                element: <PropertyInitialEntryPage />
            },
            {
                path: 'hall-of-inspiration/konzepte',
                element: <ConceptOverviewPage />
            },
            {
                path: 'marktplatz',
                element: <MarketplaceAdOverviewPage />
            },
            {
                path: 'marktplatz/inserate',
                element: <MarketplaceLotOverviewPage />
            },
        ]
    },
    {
        element: <PublicPageLayout />,
        children: [
            {
                path: 'aktivieren/:userActivationUuid',
                element: <UserActivationPage />,
            },
            {
                path: 'passwort-vergessen',
                element: <UserPasswordResetDemandPage />,
            },
            {
                path: 'passwort-setzen/:userPasswordResetUuid',
                element: <UserPasswordResetPage />,
            },
            {
                path: 'email-bestaetigen/:userEmailChangeUuid',
                element: <UserEmailChangeConfirmationPage />,
            },
            {
                path: 'impressum',
                element: <ImprintPage />
            },
        ]
    },
    {
        element: <PublicPageLayout infobox={true} />,
        children: [
            {
                path: 'login',
                element: <LoginPage />
            },
        ]
    },
    {
        element: <PublicPageLayout extended={true} />,
        children: [
            {
                path: 'agb',
                element: <GtcPage />
            },
            {
                path: 'datenschutz',
                element: <PrivacyPolicyPage />
            },
        ]
    },
    {
        path: 'authentication-token-exchange/:oneTimeTokenUuid',
        element: <AuthenticationTokenExchangePage />
    },
]);

export default leanMatchRouter;
