import Message from './Message'
import LeanMessageResponse from '../../../../api/Llasm/shared/Messaging/LeanMessageResponse';

class LeanMessage extends Message {
    public static createFromLeanMessageResponse(leanMessageResponse: LeanMessageResponse): LeanMessage {
        const leanMessage: LeanMessage = new this('leanMessage' + leanMessageResponse.id, leanMessageResponse.messageType);

        leanMessage.mapFromMessageResponse(leanMessageResponse);

        return leanMessage;
    }
}

export default LeanMessage;
