import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import MatchingParameter from '../Entity/Matching/MatchingParameter';
import MatchType from '../Entity/Matching/MatchType';
import Property from '../Entity/Property/Property';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import MatchingResultGroup from '../Entity/Matching/MatchingResultGroup';
import IndustryClassification from '../Entity/IndustryClassification/IndustryClassification';
import SettlementConceptActivationContingent from '../Entity/SettlementConcept/SettlementConceptActivationContingent';
import SettlementConceptResponse from '../../../api/Llasm/shared/SettlementConcept/SettlementConceptResponse';
import PropertyResponse from '../../../api/Llasm/shared/Property/PropertyResponse';
import MatchingResultGroupResponse from '../../../api/Llasm/shared/Matching/MatchingResultGroupResponse';
import MatchingParameterPostRequest from '../../../api/Llasm/shared/Matching/MatchingParameterPostRequest';
import MatchingResultGroupUnlockPostRequest from '../../../api/Llasm/shared/Matching/MatchingResultGroupUnlockPostRequest';
import { AxiosResponse } from 'axios';

class MatchingService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
    }

    public async fetchMatchingResultGroupsFromApiByProperty(property: Property, matchType: MatchType): Promise<MatchingResultGroup[]> {
        const apiResponse: AxiosResponse<MatchingResultGroupResponse[]> = await this.llasmApiV1Provider.get('/properties/' + property.id + '/matching-result-groups?matchType=' + matchType);

        return apiResponse.data.map((matchingResultGroupResponse: MatchingResultGroupResponse): MatchingResultGroup => {
            return MatchingResultGroup.createFromMatchingResultGroupResponse(matchingResultGroupResponse);
        });
    }

    public async fetchMatchedSettlementConceptsByPropertyFromApi(property: Property): Promise<SettlementConcept[]> {
        const apiResponse: AxiosResponse<SettlementConceptResponse[]> = await this.llasmApiV1Provider.get('/matching/properties/' + property.id + '/settlement-concepts');

        return apiResponse.data.map((settlementConceptResponse: SettlementConceptResponse): SettlementConcept => {
            return SettlementConcept.createFromSettlementConceptResponse(settlementConceptResponse);
        });
    }

    public async fetchMatchedPropertiesBySettlementConceptFromApi(settlementConcept: SettlementConcept): Promise<Property[]> {
        const apiResponse: AxiosResponse<PropertyResponse[]> = await this.llasmApiV1Provider.get('/matching/settlement-concepts/' + settlementConcept.id + '/properties');

        return apiResponse.data.map((propertyResponse: PropertyResponse): Property => {
            return Property.createFromPropertyResponse(propertyResponse);
        });
    }

    public async startMatchingByProperty(property: Property): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.post<null, AxiosResponse<null>, MatchingParameterPostRequest>(
            '/properties/' + property.id! + '/matching-tasks',
            MatchingService.mapMatchingParameterPostRequest(property.matchingParameter!)
        );
    }

    public async activateMatchingResultGroups(
        matchingResultGroups: MatchingResultGroup[],
        settlementConceptActivationContingent: SettlementConceptActivationContingent
    ): Promise<AxiosResponse<null>> {
        const matchingResultGroupUnlockPostRequest: MatchingResultGroupUnlockPostRequest = {
            brandIds: matchingResultGroups.map((matchingResultGroup: MatchingResultGroup): number => matchingResultGroup.brand.id!),
            settlementConceptActivationContingentId: settlementConceptActivationContingent.id!
        };

        return await this.llasmApiV1Provider.post<null, AxiosResponse<null>, MatchingResultGroupUnlockPostRequest>(
            '/matching-result-groups/unlock',
            matchingResultGroupUnlockPostRequest
        );
    }

    public fetchNumberOfLockedMatchingResultGroups(matchingResultGroups: MatchingResultGroup[]): number {
        return this.fetchLockedMatchingResultGroups(matchingResultGroups).length;
    }

    public fetchLockedMatchingResultGroups(matchingResultGroups: MatchingResultGroup[]): MatchingResultGroup[] {
        return matchingResultGroups.filter((matchingResultGroup: MatchingResultGroup): boolean => {
            return matchingResultGroup.locked === true;
        });
    }

    private static mapMatchingParameterPostRequest(matchingParameter: MatchingParameter): MatchingParameterPostRequest {
        return {
            ageStructures: matchingParameter.ageStructures,
            targetGroups: matchingParameter.targetGroups,
            priceSegments: matchingParameter.priceSegments,
            branchingDegrees: matchingParameter.branchingDegrees,
            industryClassificationIds: matchingParameter.industryClassifications.map((industryClassification: IndustryClassification): number => industryClassification.id!)
        };
    }
}

export default MatchingService;
