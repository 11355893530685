import ContactIntention from '../Entity/ContactIntention/ContactIntention';
import Property from '../Entity/Property/Property';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import ContactIntentionService from './ContactIntentionService';
import LoadingIndicator from '../Component/LoadingIndicator/LoadingIndicator';
import thumbsUpGreen from './Icon/icon-metro-thumbs-up-green.svg';
import thumbsUpGray from './Icon/icon-metro-thumbs-up-grey.svg';
import contactIntentionButtonStyle from './ContactIntentionButton.module.scss';
import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

interface ContactIntentionButtonProps {
    readonly property: Property;
    readonly settlementConcept: SettlementConcept;
    readonly setContactIntention?: (contactIntention: ContactIntention | null | undefined) => void;
}

const contactIntentionService: ContactIntentionService = new ContactIntentionService(process.env.REACT_APP_LLASM_API_URL!);

const ContactIntentionButton = (props: ContactIntentionButtonProps): React.JSX.Element => {
    const [contactIntention, setContactIntention] = useState<ContactIntention | null | undefined>();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect((): void => {
        fetchContactIntention();
    }, []);

    const fetchContactIntention = async (): Promise<void> => {
        try {
            setIsLoading(true);

            const identifier: string = props.property.id! + '-' + props.settlementConcept.id!

            const contactIntention: ContactIntention = await contactIntentionService.fetchContactIntentionFromApiByIdentifier(identifier);

            setContactIntention(contactIntention);

            if (props.setContactIntention !== undefined) {
                props.setContactIntention(contactIntention);
            }
        } catch (error) {
            if ((error as AxiosError).response!.status !== 404) {
                //TODO throw error
            }

            if ((error as AxiosError).response!.status === 404) {
                setContactIntention(null);

                if (props.setContactIntention !== undefined) {
                    props.setContactIntention(null);
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const persistContactIntention = async (): Promise<void> => {
        setIsLoading(true);

        try {
            const contactIntention: ContactIntention = new ContactIntention(props.property.id!, props.settlementConcept.id!);

            const persistedContactIntention: ContactIntention = await contactIntentionService.persistContactIntention(contactIntention);

            setContactIntention(persistedContactIntention);

            if (props.setContactIntention !== undefined) {
                props.setContactIntention(contactIntention);
            }
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading === true) {
        return (
            <div className={contactIntentionButtonStyle.loadingIndicatorContainer}>
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <>
            {contactIntention !== undefined && contactIntention !== null ? (
                <div className="text-center">
                    <span className={contactIntentionButtonStyle.textGreen}>Gefällt mir</span>
                    <img src={thumbsUpGreen} alt="Daumen hoch" className={['img-fluid', 'ms-2', 'mb-2', contactIntentionButtonStyle.iconGreen].join(' ')} />
                </div>
            ) : (
                <div className="text-center">
                    <span className={['cursor-pointer', 'border', 'p-3', contactIntentionButtonStyle.borderGray].join(' ')} onClick={persistContactIntention}>
                        <span className={contactIntentionButtonStyle.textGray}>Gefällt mir</span>
                        <img src={thumbsUpGray} alt="Daumen hoch" className={['img-fluid', 'ms-2', 'mb-2', contactIntentionButtonStyle.iconGray].join(' ')} />
                     </span>
                </div>
            )}
        </>
    );
};

export default ContactIntentionButton;
