import Purchase from '../../shared/Entity/MatchPoint/Purchase';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import PurchaseMethod from '../../shared/Entity/Payment/PurchaseMethod';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import PurchaseResponse from '../../../api/Llasm/shared/MatchPoint/PurchaseResponse';
import PurchasePostRequest from '../../../api/Llasm/shared/MatchPoint/PurchasePostRequest';
import {AxiosResponse} from 'axios';

class MatchPointService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly checksumSalt: string = process.env.REACT_APP_CHECKSUM_SALT!;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
    }

    public calculatePrice(amount: number): number {
        if (amount > 1000) {
            return 1749.9 + (amount - 1000) * 2250 / 1500;
        }

        if (amount > 250) {
            return 449.9 + (amount - 250) * 1300 / 750;
        }

        if (amount > 100) {
            return 189.9 + (amount - 100) * 260 / 150;
        }

        if (amount > 50) {
            return 99.9 + (amount - 50) * 90 / 50;
        }

        if (amount > 0) {
            return 99.9 / 50 * amount;
        }

        return 0;
    }

    public async purchaseMatchPoint(amount: number, priceNet: number, purchaseMethod: PurchaseMethod, billingAddress: BillingAddress): Promise<Purchase> {
        const purchasePostRequest: PurchasePostRequest = {
            amount: amount,
            priceNet: Math.floor(Number(priceNet.toFixed(2)) * 100),
            purchaseMethod: purchaseMethod,
            checksum: billingAddress.buildChecksum(this.checksumSalt)
        };

        const apiResponse: AxiosResponse<PurchaseResponse> = await this.llasmApiV1Provider.post<null, AxiosResponse<PurchaseResponse>, PurchasePostRequest>(
            '/match-point/purchase',
            purchasePostRequest
        );

        return Purchase.createFromPurchaseResponse(apiResponse.data);
    }
}

export default MatchPointService;
