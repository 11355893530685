import PropertyService from '../Property/PropertyService';
import Property from '../Entity/Property/Property';
import ImageEntity from '../Entity/Image/Image';
import SpecialObjectCategory from '../Entity/Property/SpecialObjectCategory';
import TimeAvailability from '../Entity/Property/TimeAvailability';
import Card from '../Component/Card/Card';
import CardContent from '../Component/Card/CardContent';
import ImageSliderCardMedia from '../Component/Card/ImageSliderCardMedia';
import IconBadgeWrapper from '../Component/IconBadge/IconBadgeWrapper';
import IconBadge from '../Component/IconBadge/IconBadge';
import BootstrapIcon from '../Component/Icon/BootstrapIcon';
import CardFooter from '../Component/Card/CardFooter';
import noPicture from '../../../img/no-picture.png';
import marketplaceAdCardStyle from './MarketplaceAd/MarketplaceAdCard.module.scss';
import React, {useState} from 'react';

interface PropertyCardProps extends React.PropsWithChildren {
    readonly property: Property;
    readonly thumbnailImageGetPath?: string;
}

const PropertyCard = (props: PropertyCardProps): React.JSX.Element => {
    const [cardMediaContainerHeight, setCardMediaContainerHeight] = useState<number>();

    const buildThumbnailImageGetPath = (image: ImageEntity): string => {
        if (props.thumbnailImageGetPath !== undefined) {
            return props.thumbnailImageGetPath + '/' + image.id;
        }

        return PropertyService.buildThumbnailImageApiPath(props.property, image);
    };

    const calculateTotalAreaSize = (): number => {
        if (props.property.detail.secondaryAreaSize === null) {
            return props.property.detail.areaSize;
        }

        return props.property.detail.areaSize + props.property.detail.secondaryAreaSize;
    };

    return (
        <Card cardType="shadow" className="position-relative h-100">
            <ImageSliderCardMedia
                images={props.property.images}
                noImage={noPicture}
                buildThumbnailImageGetPath={buildThumbnailImageGetPath}
                cardMediaContainerHeight={cardMediaContainerHeight}
                setCardMediaContainerHeight={setCardMediaContainerHeight}
            />
            <CardContent className="px-4">
                <IconBadgeWrapper positionTop={-40} positionLeft={0}>
                    {props.property.detail.specialObjectCategories.includes(SpecialObjectCategory.PopUpArea) === true &&
                        <IconBadge iconPublisher="bootstrap" iconName="clock-history" backgroundColor="secondary" description="PopUp-Fläche" />
                    }
                    {props.property.detail.specialObjectCategories.includes(SpecialObjectCategory.MixedUseArea) === true &&
                        <IconBadge iconPublisher="bootstrap" iconName="columns-gap" backgroundColor="secondary" description="MixedUse-Fläche" />
                    }
                    {props.property.detail.specialObjectCategories.includes(SpecialObjectCategory.DepartmentStore) === true &&
                        <IconBadge iconPublisher="bootstrap" iconName="building" backgroundColor="secondary" description="Kaufhaus" />
                    }
                    {props.property.detail.specialObjectCategories.includes(SpecialObjectCategory.CoWorkingSpace) === true &&
                        <IconBadge iconPublisher="bootstrap" iconName="person-workspace" backgroundColor="secondary" description="Co-Working-Space" />
                    }
                    {props.property.offerDetail.fundingAvailable === true &&
                        <IconBadge iconPublisher="bootstrap" iconName="cash-coin" backgroundColor="secondary" description="Förderfläche" />
                    }
                </IconBadgeWrapper>
                <div className={['fw-bolder', 'mb-3', marketplaceAdCardStyle.titleHeight].join(' ')}>
                    {props.property.title}
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <div className="me-3">
                        <BootstrapIcon iconName="geo-alt-fill" />
                    </div>
                    <div>
                        {props.property.locationDetail.streetName !== null &&
                            <div>{props.property.locationDetail.streetName} {props.property.locationDetail.houseNumber}</div>
                        }
                        <div>{props.property.locationDetail.postalCode} {props.property.locationDetail.city!.placeName}</div>
                    </div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <div className="me-3">
                        <BootstrapIcon iconName="bounding-box-circles" />
                    </div>
                    <div>
                        {new Intl.NumberFormat('de-DE').format(calculateTotalAreaSize())} m²
                    </div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <div className="me-3">
                        <BootstrapIcon iconName="calendar-event-fill" />
                    </div>
                    <div>
                        {props.property.offerDetail.fromTimeAvailability === TimeAvailability.CertainDate && props.property.offerDetail.availableFrom !== null &&
                            <>
                                {(props.property.offerDetail.availableFrom < new Date() && (props.property.offerDetail.availableUntil === null || props.property.offerDetail.availableUntil >= new Date())) ? (
                                    <>
                                        sofort verfügbar
                                    </>
                                ) : (
                                    <>
                                        ab {props.property.offerDetail.availableFrom.toLocaleDateString('de-DE', {year: 'numeric', month: 'numeric', day: 'numeric'})} verfügbar
                                    </>
                                )}
                            </>
                        }
                        {props.property.offerDetail.fromTimeAvailability === TimeAvailability.Immediately &&
                            <>
                                sofort verfügbar
                            </>
                        }
                        {props.property.offerDetail.fromTimeAvailability === TimeAvailability.ByArrangement &&
                            <>
                                verfügbar nach Vereinbarung
                            </>
                        }
                        {props.property.offerDetail.untilTimeAvailability === TimeAvailability.CertainDate && (props.property.offerDetail.availableUntil !== null && props.property.offerDetail.availableUntil >= new Date()) &&
                            <span className="ms-1">
                                (bis {props.property.offerDetail.availableUntil.toLocaleDateString('de-DE', {year: 'numeric', month: 'numeric', day: 'numeric'})})
                            </span>
                        }
                    </div>
                </div>
                <div className={marketplaceAdCardStyle.h100px}></div>
            </CardContent>
            <CardFooter className="position-absolute bottom-0 w-100">
                {props.children !== undefined &&
                    <>
                        {props.children}
                    </>
                }
            </CardFooter>
        </Card>
    );
};

export default PropertyCard;
