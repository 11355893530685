import FormData from '../Entity/Form/FormData';
import ConceptFilter from '../Entity/HallOfInspiration/ConceptFilter';
import Branch from '../Entity/HallOfInspiration/Branch';
import SelectOption from '../Entity/Form/SelectOption';
import CompanyValue, {getCompanyValueLabel} from '../Entity/CompanyValue';
import MultiSelectField from '../Component/Form/Field/MultiSelectField';
import LoadingIndicator from '../Component/LoadingIndicator/LoadingIndicator';
import React, {useEffect, useState} from 'react';

interface FilterProps {
    readonly branches: Branch[];
    readonly conceptFilter: ConceptFilter;
    readonly onSubmit: (conceptFilter: ConceptFilter) => void;
}

const companyValueSelectOptions: SelectOption<CompanyValue>[] = [
    {label: getCompanyValueLabel(CompanyValue.Sustainability), value: CompanyValue.Sustainability},
    {label: getCompanyValueLabel(CompanyValue.Specialized), value: CompanyValue.Specialized},
    {label: getCompanyValueLabel(CompanyValue.Innovative), value: CompanyValue.Innovative},
    {label: getCompanyValueLabel(CompanyValue.Networking), value: CompanyValue.Networking},
    {label: getCompanyValueLabel(CompanyValue.RelaxationRecreation), value: CompanyValue.RelaxationRecreation},
    {label: getCompanyValueLabel(CompanyValue.Creativity), value: CompanyValue.Creativity},
    {label: getCompanyValueLabel(CompanyValue.ExperienceEntertainment), value: CompanyValue.ExperienceEntertainment},
    {label: getCompanyValueLabel(CompanyValue.LocalityRegionality), value: CompanyValue.LocalityRegionality},
    {label: getCompanyValueLabel(CompanyValue.ServiceOriented), value: CompanyValue.ServiceOriented},
    {label: getCompanyValueLabel(CompanyValue.VitalityVibrancy), value: CompanyValue.VitalityVibrancy},
    {label: getCompanyValueLabel(CompanyValue.ShopWindowDressing), value: CompanyValue.ShopWindowDressing},
    {label: getCompanyValueLabel(CompanyValue.ShopFitting), value: CompanyValue.ShopFitting},
    {label: getCompanyValueLabel(CompanyValue.Digitalisation), value: CompanyValue.Digitalisation},
];

const Filter = (props: FilterProps): React.JSX.Element => {
    const [formData, setFormData] = useState<FormData<ConceptFilter>>({data: props.conceptFilter});

    const [branchSelectOptions, setBranchSelectOptions] = useState<SelectOption<Branch>[]>();

    useEffect((): void => {
        if (branchSelectOptions !== undefined) {
            return;
        }

        const mappedBranchSelectOptions: SelectOption<Branch>[] = props.branches.map((branch: Branch): SelectOption<Branch> => {
            return {label: branch.name, value: branch};
        });

        setBranchSelectOptions(mappedBranchSelectOptions);
    }, []);

    const fetchCompanyValuesSelectedOptions = (): SelectOption<CompanyValue>[] => {
        if (props.conceptFilter.companyValues.length === 0) {
            return [];
        }

        return companyValueSelectOptions.filter((selectOption: SelectOption<CompanyValue>): boolean => {
            return props.conceptFilter.companyValues.includes(selectOption.value);
        });
    };

    const fetchBranchesSelectedOptions = (): SelectOption<Branch>[] => {
        if (branchSelectOptions === undefined) {
            throw new Error('BranchSelectOptions are undefined.');
        }

        if (props.conceptFilter.branches.length === 0) {
            return [];
        }

        return branchSelectOptions.filter((selectOption: SelectOption<Branch>): boolean => {
            return props.conceptFilter.branches.includes(selectOption.value);
        });
    };

    const handleCompanyValuesChange = (selectedValues: readonly SelectOption<CompanyValue>[]): void => {
        props.conceptFilter.companyValues = selectedValues.map((selectOption: SelectOption<CompanyValue>): CompanyValue => {
            return selectOption.value;
        });

        updateFormData();
    };

    const handleBranchesChange = (selectedValues: readonly SelectOption<Branch>[]): void => {
        props.conceptFilter.branches = selectedValues.map((selectOption: SelectOption<Branch>): Branch => {
            return selectOption.value;
        });

        updateFormData();
    };

    const updateFormData = (): void => {
        setFormData({...formData, data: props.conceptFilter});
    };

    const onSubmit = (): void => {
        props.onSubmit(props.conceptFilter);
    };

    if (branchSelectOptions === undefined) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-6 mb-3">
                    <MultiSelectField
                        label="Unternehmensmerkmale"
                        name="companyValues"
                        options={companyValueSelectOptions}
                        closeMenuOnSelect={false}
                        value={fetchCompanyValuesSelectedOptions()}
                        placeholder="Bitte wählen"
                        onChange={handleCompanyValuesChange}
                    />
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <MultiSelectField
                        label="Branchen"
                        name="branches"
                        options={branchSelectOptions}
                        closeMenuOnSelect={false}
                        value={fetchBranchesSelectedOptions()}
                        placeholder="Bitte wählen"
                        onChange={handleBranchesChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3 d-flex align-items-end justify-content-end">
                    <button className="btn btn-primary flex-grow-1 flex-sm-grow-0 mt-1" onClick={onSubmit}>
                        Filter anwenden
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Filter;
