import SpecialObjectCategory from './SpecialObjectCategory';
import SpecialObjectSeparation from './SpecialObjectSeparation';
import AccessibilityType from '../AccessibilityType';
import LastUsage from './LastUsage';
import DetailResponse from '../../../../api/Llasm/shared/Property/DetailResponse';

class Detail {
    private _specialObjectCategories: SpecialObjectCategory[] = [];

    private _specialObjectSeparations: SpecialObjectSeparation[] = [];

    private _floors: string[] = [];

    private _areaSize: number;

    private _secondaryAreaSize: number | null = null;

    private _outdoorSalesAreaSize: number | null = null;

    private _storeWidth: number | null = null;

    private _shopWindowFrontLength: number | null = null;

    private _accessibilityType: AccessibilityType;

    private _groundLevelSalesArea: boolean;

    private _parkingLotAvailable: boolean;

    private _numberOfParkingLots: number | null = null;

    private _parkingLotsExplanation: string | null = null;

    private _objectDescription: string;

    private _lastUsage: LastUsage | null = null;

    public static createFromDetailResponse(detailResponse: DetailResponse): Detail {
        const detail: Detail = new this();

        detail._specialObjectCategories = detailResponse.specialObjectCategories;
        detail._specialObjectSeparations = detailResponse.specialObjectSeparations;
        detail._floors = detailResponse.floors;
        detail._areaSize = detailResponse.areaSize;
        detail._secondaryAreaSize = detailResponse.secondaryAreaSize;
        detail._outdoorSalesAreaSize = detailResponse.outdoorSalesAreaSize;
        detail._storeWidth = detailResponse.storeWidth;
        detail._shopWindowFrontLength = detailResponse.shopWindowFrontLength;
        detail._accessibilityType = detailResponse.accessibilityType;
        detail._groundLevelSalesArea = detailResponse.groundLevelSalesArea;
        detail._parkingLotAvailable = detailResponse.parkingLotAvailable;
        detail._numberOfParkingLots = detailResponse.numberOfParkingLots;
        detail._parkingLotsExplanation = detailResponse.parkingLotsExplanation;
        detail._objectDescription = detailResponse.objectDescription;
        detail._lastUsage = detailResponse.lastUsage;

        return detail;
    }

    get specialObjectCategories(): SpecialObjectCategory[] {
        return this._specialObjectCategories;
    }

    set specialObjectCategories(value: SpecialObjectCategory[]) {
        this._specialObjectCategories = value;
    }

    get specialObjectSeparations(): SpecialObjectSeparation[] {
        return this._specialObjectSeparations;
    }

    set specialObjectSeparations(value: SpecialObjectSeparation[]) {
        this._specialObjectSeparations = value;
    }

    get floors(): string[] {
        return this._floors;
    }

    set floors(value: string[]) {
        this._floors = value;
    }

    get areaSize(): number {
        return this._areaSize;
    }

    set areaSize(value: number) {
        this._areaSize = value;
    }

    get secondaryAreaSize(): number | null {
        return this._secondaryAreaSize;
    }

    set secondaryAreaSize(value: number | null) {
        this._secondaryAreaSize = value;
    }

    get outdoorSalesAreaSize(): number | null {
        return this._outdoorSalesAreaSize;
    }

    set outdoorSalesAreaSize(value: number | null) {
        this._outdoorSalesAreaSize = value;
    }

    get storeWidth(): number | null {
        return this._storeWidth;
    }

    set storeWidth(value: number | null) {
        this._storeWidth = value;
    }

    get shopWindowFrontLength(): number | null {
        return this._shopWindowFrontLength;
    }

    set shopWindowFrontLength(value: number | null) {
        this._shopWindowFrontLength = value;
    }

    get accessibilityType(): AccessibilityType {
        return this._accessibilityType;
    }

    set accessibilityType(value: AccessibilityType) {
        this._accessibilityType = value;
    }

    get groundLevelSalesArea(): boolean {
        return this._groundLevelSalesArea;
    }

    set groundLevelSalesArea(value: boolean) {
        this._groundLevelSalesArea = value;
    }

    get parkingLotAvailable(): boolean {
        return this._parkingLotAvailable;
    }

    set parkingLotAvailable(value: boolean) {
        this._parkingLotAvailable = value;
    }

    get numberOfParkingLots(): number | null {
        return this._numberOfParkingLots;
    }

    set numberOfParkingLots(value: number | null) {
        this._numberOfParkingLots = value;
    }

    get parkingLotsExplanation(): string | null {
        return this._parkingLotsExplanation;
    }

    set parkingLotsExplanation(value: string | null) {
        this._parkingLotsExplanation = value;
    }

    get objectDescription(): string {
        return this._objectDescription;
    }

    set objectDescription(value: string) {
        this._objectDescription = value;
    }

    get lastUsage(): LastUsage | null {
        return this._lastUsage;
    }

    set lastUsage(value: LastUsage | null) {
        this._lastUsage = value;
    }
}

export default Detail;
