import LeanPropertyExposeResponse from '../../../../api/Llasm/shared/Messaging/LeanPropertyExposeResponse';

class LeanPropertyExpose {
    private _fileContentAsBase64: string;

    public static createFromLeanPropertyExposeResponse(leanPropertyExposeResponse: LeanPropertyExposeResponse): LeanPropertyExpose
    {
        const leanPropertyExpose: LeanPropertyExpose = new this();

        leanPropertyExpose._fileContentAsBase64 = leanPropertyExposeResponse.fileContentAsBase64;

        return leanPropertyExpose;
    }

    get fileContentAsBase64(): string {
        return this._fileContentAsBase64;
    }

    set fileContentAsBase64(value: string) {
        this._fileContentAsBase64 = value;
    }
}

export default LeanPropertyExpose;
