import EnergyCertificateType from './EnergyCertificateType';
import EnergyEfficiencyClass from './EnergyEfficiencyClass';
import EnergyCertificateHeatingType from './EnergyCertificateHeatingType';
import EnergyCertificateResponse from '../../../../../api/Llasm/shared/Property/EnergyCertificate/EnergyCertificateResponse';

class EnergyCertificate {
    private _energyCertificateType: EnergyCertificateType;

    private _issueDate: Date | null = null;

    private _expirationDate: Date | null = null;

    private _buildingConstructionYear: number | null = null;

    private _energyEfficiencyClass: EnergyEfficiencyClass | null = null;

    private _energyCertificateHeatingTypes: EnergyCertificateHeatingType[]  = [];

    private _withHotWater: boolean;

    private _energyDemand: number | null = null;

    private _electricityDemand: number | null = null;

    private _heatingEnergyDemand: number | null = null;

    private _energyConsumption: number | null = null;

    private _electricityConsumption: number | null = null;

    private _heatingEnergyConsumption: number | null = null;

    private _otherInformation: string | null = null;

    public static createFromEnergyCertificateResponse(energyCertificateResponse: EnergyCertificateResponse): EnergyCertificate {
        const energyCertificate: EnergyCertificate = new this();

        energyCertificate._energyCertificateType = energyCertificateResponse.energyCertificateType;

        if (energyCertificateResponse.issueDate !== null) {
            energyCertificate._issueDate = new Date(energyCertificateResponse.issueDate);
        }

        if (energyCertificateResponse.expirationDate !== null) {
            energyCertificate._expirationDate = new Date(energyCertificateResponse.expirationDate);
        }

        energyCertificate._buildingConstructionYear = energyCertificateResponse.buildingConstructionYear;
        energyCertificate._energyEfficiencyClass = energyCertificateResponse.energyEfficiencyClass;
        energyCertificate._energyCertificateHeatingTypes = energyCertificateResponse.energyCertificateHeatingTypes;
        energyCertificate._withHotWater = energyCertificateResponse.withHotWater;
        energyCertificate._energyDemand = energyCertificateResponse.energyDemand;
        energyCertificate._electricityDemand = energyCertificateResponse.electricityDemand;
        energyCertificate._heatingEnergyDemand = energyCertificateResponse.heatingEnergyDemand;
        energyCertificate._energyConsumption = energyCertificateResponse.energyConsumption;
        energyCertificate._electricityConsumption = energyCertificateResponse.electricityConsumption;
        energyCertificate._heatingEnergyConsumption = energyCertificateResponse.heatingEnergyConsumption;
        energyCertificate._otherInformation = energyCertificateResponse.otherInformation;

        return energyCertificate;
    }

    get energyCertificateType(): EnergyCertificateType {
        return this._energyCertificateType;
    }

    set energyCertificateType(value: EnergyCertificateType) {
        this._energyCertificateType = value;
    }

    get issueDate(): Date | null {
        return this._issueDate;
    }

    set issueDate(value: Date | null) {
        this._issueDate = value;
    }

    get expirationDate(): Date | null {
        return this._expirationDate;
    }

    set expirationDate(value: Date | null) {
        this._expirationDate = value;
    }

    get buildingConstructionYear(): number | null {
        return this._buildingConstructionYear;
    }

    set buildingConstructionYear(value: number | null) {
        this._buildingConstructionYear = value;
    }

    get energyEfficiencyClass(): EnergyEfficiencyClass | null {
        return this._energyEfficiencyClass;
    }

    set energyEfficiencyClass(value: EnergyEfficiencyClass | null) {
        this._energyEfficiencyClass = value;
    }

    get energyCertificateHeatingTypes(): EnergyCertificateHeatingType[] {
        return this._energyCertificateHeatingTypes;
    }

    set energyCertificateHeatingTypes(value: EnergyCertificateHeatingType[]) {
        this._energyCertificateHeatingTypes = value;
    }

    get withHotWater(): boolean {
        return this._withHotWater;
    }

    set withHotWater(value: boolean) {
        this._withHotWater = value;
    }

    get energyDemand(): number | null {
        return this._energyDemand;
    }

    set energyDemand(value: number | null) {
        this._energyDemand = value;
    }

    get electricityDemand(): number | null {
        return this._electricityDemand;
    }

    set electricityDemand(value: number | null) {
        this._electricityDemand = value;
    }

    get heatingEnergyDemand(): number | null {
        return this._heatingEnergyDemand;
    }

    set heatingEnergyDemand(value: number | null) {
        this._heatingEnergyDemand = value;
    }

    get energyConsumption(): number | null {
        return this._energyConsumption;
    }

    set energyConsumption(value: number | null) {
        this._energyConsumption = value;
    }

    get electricityConsumption(): number | null {
        return this._electricityConsumption;
    }

    set electricityConsumption(value: number | null) {
        this._electricityConsumption = value;
    }

    get heatingEnergyConsumption(): number | null {
        return this._heatingEnergyConsumption;
    }

    set heatingEnergyConsumption(value: number | null) {
        this._heatingEnergyConsumption = value;
    }

    get otherInformation(): string | null {
        return this._otherInformation;
    }

    set otherInformation(value: string | null) {
        this._otherInformation = value;
    }
}

export default EnergyCertificate;
