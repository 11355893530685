import PaginationResponse from '../../../../api/Llasm/shared/Pagination/PaginationResponse';

class Pagination {
    private _total: number;

    private _page: number;

    private _pages: number;

    private _limit: number;

    public static createFromPaginationResponse(paginationResponse: PaginationResponse): Pagination {
        const pagination: Pagination = new this();

        pagination._total = paginationResponse.total;
        pagination._page = paginationResponse.page;
        pagination._pages = paginationResponse.pages;
        pagination._limit = paginationResponse.limit;
        
        return pagination;
    }

    get total(): number {
        return this._total;
    }

    set total(value: number) {
        this._total = value;
    }

    get page(): number {
        return this._page;
    }

    set page(value: number) {
        this._page = value;
    }

    get pages(): number {
        return this._pages;
    }

    set pages(value: number) {
        this._pages = value;
    }

    get limit(): number {
        return this._limit;
    }

    set limit(value: number) {
        this._limit = value;
    }
}

export default Pagination;
