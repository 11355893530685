import App from '../App';
import AuthenticationTokenExchangePage from '../domain/shared/Authentication/AuthenticationTokenExchangePage';
import LoginPage from '../domain/user/Login/LoginPage';
import UserProfilePage from '../domain/shared/User/UserProfilePage';
import MessagingPage from '../domain/shared/Messaging/MessagingPage';
import StartPage from '../domain/user/StartPage';
import ContactOverviewPage from '../domain/shared/Contact/ContactOverviewPage';
import ContactCreatePage from '../domain/shared/Contact/ContactCreatePage';
import ContactPage from '../domain/shared/Contact/ContactPage';
import BrandOverviewPage from '../domain/shared/Brand/BrandOverviewPage';
import BrandCreatePage from '../domain/shared/Brand/BrandCreatePage';
import BrandPage from '../domain/shared/Brand/BrandPage';
import PropertyOverviewPage from '../domain/shared/Property/PropertyOverviewPage';
import PropertyCreatePage from '../domain/shared/Property/PropertyCreatePage';
import PropertyPage from '../domain/shared/Property/PropertyPage';
import SettlementConceptOverviewPage from '../domain/shared/SettlementConcept/SettlementConceptOverviewPage';
import SettlementConceptCreatePage from '../domain/shared/SettlementConcept/SettlementConceptCreatePage';
import SettlementConceptPage from '../domain/shared/SettlementConcept/SettlementConceptPage';
import MatchingOverviewPage from '../domain/shared/Property/Matching/MatchingOverviewPage';
import MatchPointOverviewPage from '../domain/user/MatchPoint/MatchPointOverviewPage';
import PropertyInitialEntryPage from '../domain/shared/Property/PropertyInitialEntryPage';
import SettlementConceptInitialEntryPage from '../domain/shared/SettlementConcept/SettlementConceptInitialEntryPage';
import PublicPageLayout from '../domain/user/Public/PublicPageLayout';
import UserRegistrationPage from '../domain/user/User/UserRegistrationPage';
import UserActivationPage from '../domain/user/User/UserActivationPage';
import UserPasswordResetDemandPage from '../domain/user/User/UserPasswordResetDemandPage';
import UserPasswordResetPage from '../domain/user/User/UserPasswordResetPage';
import UserEmailChangeConfirmationPage from '../domain/shared/User/UserEmailChangeConfirmationPage';
import ImprintPage from '../domain/user/Public/ImprintPage';
import GtcPage from '../domain/user/Public/GtcPage';
import PrivacyPolicyPage from '../domain/user/Public/PrivacyPolicyPage';
import ConceptOverviewPage from '../domain/shared/HallOfInspiration/ConceptOverviewPage';
import MarketplaceAdOverviewPage from '../domain/shared/Marketplace/MarketplaceAd/MarketplaceAdOverviewPage';
import MarketplaceLotOverviewPage from '../domain/shared/Marketplace/MarketplaceLot/MarketplaceLotOverviewPage';
import {Router} from '@remix-run/router';
import {createBrowserRouter} from 'react-router-dom';

const userRouter: Router = createBrowserRouter([
    {
        element: <App />,
        children: [
            {
                path: '/',
                element: <StartPage />
            },
            {
                path: 'meine-stammdaten',
                element: <UserProfilePage />
            },
            {
                path: 'konversationen',
                element: <MessagingPage />
            },
            {
                path: 'marken',
                element: <BrandOverviewPage />
            },
            {
                path: 'marken/anlegen',
                element: <BrandCreatePage />
            },
            {
                path: 'marken/:brandId',
                element: <BrandPage />
            },
            {
                path: 'ansprechpartner',
                element: <ContactOverviewPage />
            },
            {
                path: 'ansprechpartner/anlegen',
                element: <ContactCreatePage />
            },
            {
                path: 'ansprechpartner/:contactId',
                element: <ContactPage />
            },
            {
                path: 'suchprofile',
                element: <SettlementConceptOverviewPage />
            },
            {
                path: 'suchprofile/einstellen',
                element: <SettlementConceptCreatePage />
            },
            {
                path: 'suchprofile/:settlementConceptId',
                element: <SettlementConceptPage />,
            },
            {
                path: 'flaechen',
                element: <PropertyOverviewPage />
            },
            {
                path: 'flaechen/anbieten',
                element: <PropertyCreatePage />
            },
            {
                path: 'flaechen/:propertyId',
                element: <PropertyPage />
            },
            {
                path: 'flaechen/:propertyId/matching',
                element: <MatchingOverviewPage />
            },
            {
                path: 'match-points',
                element: <MatchPointOverviewPage />
            },
            {
                path: 'flaechen/ersterfassung',
                element: <PropertyInitialEntryPage />
            },
            {
                path: 'suchprofile/ersterfassung',
                element: <SettlementConceptInitialEntryPage />
            },
            {
                path: 'hall-of-inspiration/konzepte',
                element: <ConceptOverviewPage />
            },
            {
                path: 'marktplatz',
                element: <MarketplaceAdOverviewPage />
            },
            {
                path: 'marktplatz/inserate',
                element: <MarketplaceLotOverviewPage />
            },
        ]
    },
    {
        element: <PublicPageLayout />,
        children: [
            {
                path: 'aktivieren/:userActivationUuid',
                element: <UserActivationPage />,
            },
            {
                path: 'passwort-vergessen',
                element: <UserPasswordResetDemandPage />,
            },
            {
                path: 'passwort-setzen/:userPasswordResetUuid',
                element: <UserPasswordResetPage />,
            },
            {
                path: 'email-bestaetigen/:userEmailChangeUuid',
                element: <UserEmailChangeConfirmationPage />,
            },
            {
                path: 'impressum',
                element: <ImprintPage />
            },
        ]
    },
    {
        element: <PublicPageLayout infobox={true} />,
        children: [
            {
                path: 'login',
                element: <LoginPage />
            },
            {
                path: 'registrierung',
                element: <UserRegistrationPage />
            },
        ]
    },
    {
        element: <PublicPageLayout extended={true} />,
        children: [
            {
                path: 'agb',
                element: <GtcPage />
            },
            {
                path: 'datenschutz',
                element: <PrivacyPolicyPage />
            },
        ]
    },
    {
        path: 'authentication-token-exchange/:oneTimeTokenUuid',
        element: <AuthenticationTokenExchangePage />,
    },
]);

export default userRouter;
