import SettlementConceptActivationContingentResponse from '../../../../api/Llasm/shared/SettlementConcept/SettlementConceptActivationContingentResponse';

class SettlementConceptActivationContingent {

    private _id?: number;

    private _numberOfActivations: number;

    private _matchPointCost: number;

    public static createFromSettlementConceptActivationContingentResponse(settlementConceptActivationContingentResponse: SettlementConceptActivationContingentResponse) {
        const settlementConceptActivationContingent = new this();

        settlementConceptActivationContingent._id = settlementConceptActivationContingentResponse.id;
        settlementConceptActivationContingent._numberOfActivations = settlementConceptActivationContingentResponse.numberOfActivations;
        settlementConceptActivationContingent._matchPointCost = settlementConceptActivationContingentResponse.matchPointCost;

        return settlementConceptActivationContingent;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get numberOfActivations(): number {
        return this._numberOfActivations;
    }

    set numberOfActivations(value: number) {
        this._numberOfActivations = value;
    }

    get matchPointCost(): number {
        return this._matchPointCost;
    }

    set matchPointCost(value: number) {
        this._matchPointCost = value;
    }

}

export default SettlementConceptActivationContingent;
