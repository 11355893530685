import PropertyInterestFeedback from './PropertyInterestFeedback';
import PropertyInterestResponse from '../../../../api/Llasm/shared/PropertyInterest/PropertyInterestResponse';

class PropertyInterest {
    private _id?: number;

    private _propertyId: number | null = null;

    private _settlementConceptId: number | null = null;

    private _propertyInterestFeedback: PropertyInterestFeedback;

    private _createdAt: Date;

    public static createFromPropertyInterestResponse(propertyInterestResponse: PropertyInterestResponse): PropertyInterest {
        const propertyInterest: PropertyInterest = new this();

        propertyInterest._propertyInterestFeedback = propertyInterestResponse.propertyInterestFeedback;
        propertyInterest._createdAt = new Date(propertyInterestResponse.createdAt);

        return propertyInterest;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get propertyId(): number | null {
        return this._propertyId;
    }

    set propertyId(value: number | null) {
        this._propertyId = value;
    }

    get settlementConceptId(): number | null {
        return this._settlementConceptId;
    }

    set settlementConceptId(value: number | null) {
        this._settlementConceptId = value;
    }

    get propertyInterestFeedback(): PropertyInterestFeedback {
        return this._propertyInterestFeedback;
    }

    set propertyInterestFeedback(value: PropertyInterestFeedback) {
        this._propertyInterestFeedback = value;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    set createdAt(value: Date) {
        this._createdAt = value;
    }
}

export default PropertyInterest;
