import ConversationService from './ConversationService';
import MessageEntity from '../Entity/Messaging/Message';
import MessageType from '../Entity/Messaging/MessageType';
import ProviderSeekerMessage from '../Entity/Messaging/ProviderSeekerMessage';
import Property from '../Entity/Property/Property';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import ContactIntention from '../Entity/ContactIntention/ContactIntention';
import ProviderSeekerConversation from '../Entity/Messaging/ProviderSeekerConversation';
import Conversation from '../Entity/Messaging/Conversation';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import LoadingIndicator from '../Component/LoadingIndicator/LoadingIndicator';
import AlertBox from '../../../components/AlertBox';
import messagingStyle from './Messaging.module.scss';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

interface ConversationInitializationProps {
    readonly property: Property;
    readonly settlementConcept: SettlementConcept;
    readonly contactIntention: ContactIntention | null | undefined;
}

const messageAlert: Alert = new Alert(AlertType.Error, 'Deine Nachricht konnte leider nicht gesendet werden. Bitte gib eine Nachricht mit mindestens 5 Zeichen ein.');
const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schief gelaufen. Bitte versuche es zu einem späteren Zeitpunkt noch einmal.');
const successAlert: Alert = new Alert(AlertType.Success, 'Deine Nachricht wurde erfolgreich an den Suchprofilanbietenden übermittelt. Dieser wird sich sicher bald bei Dir melden.');

const conversationService: ConversationService = new ConversationService(process.env.REACT_APP_LLASM_API_URL!);

const ConversationInitialization = (props: ConversationInitializationProps): React.JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>();

    const [conversation, setConversation] = useState<ProviderSeekerConversation>()

    const [messageText, setMessageText] = useState<string>();

    const [alert, setAlert] = useState<Alert>();

    useEffect((): void => {
        fetchConversation();
    }, [props.contactIntention]);

    const fetchConversation = async (): Promise<void> => {
        setIsLoading(true);

        const conversations: Conversation[] = await conversationService.fetchProviderSeekerConversationsFromApiByPropertyAndSettlementConcept(props.property, props.settlementConcept);

        if (conversations.length === 1) {
            setConversation(conversations[0] as ProviderSeekerConversation);
        }

        setIsLoading(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setMessageText(event.target.value);
    };

    const isMessageTextValid = (): boolean => {
        return (messageText === undefined || messageText === '' || messageText.length < 5) === false;
    };

    const createMessageFromMessageText = (messageText: string): MessageEntity => {
        const message: MessageEntity = new ProviderSeekerMessage('providerSeekerMessage' + (new Date()).valueOf(), MessageType.Standard);

        message.text = messageText;
        message.createdAt = new Date();
        message.isSender = true;

        return message;
    };

    const persistMessage = async (): Promise<void> => {
        if (conversation === undefined) {
            throw new Error();
        }

        if (isMessageTextValid() === false) {
            setAlert(messageAlert);

            return;
        }

        setIsLoading(true);

        try {
            const message: MessageEntity = createMessageFromMessageText(messageText!);

            const persistedMessage: MessageEntity = await conversationService.persistMessage(conversation, message);

            await fetchConversation();

            setAlert(successAlert);
        } catch (error) {
            setAlert(errorAlert);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading === true) {
        return (
            <div className={messagingStyle.loadingIndicatorContainer}>
                <LoadingIndicator />
            </div>
        );
    }

    if (conversation !== undefined && conversation.hasMessages === true) {
        return (
            <>
                {alert !== undefined &&
                    <AlertBox alert={alert} autoDismiss={false} />
                }
                <div className="small mb-4">
                    Du hast bereits eine Konversation mit dem Suchprofilanbietenden begonnen 😊. Über den untenstehenden Button kannst Du Deine bisherige Unterhaltung aufrufen:
                </div>
                <div className="text-center mt-3">
                    <Link to={'/konversationen?conversation=' + conversation.buildIdentifier()} className="btn btn-secondary">Zur Konversation</Link>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="small mb-4">
                Möchtest Du den Ansprechpartner direkt kontaktieren? Die Kontaktdaten findest Du unten. Hast Du direkt eine Nachricht für den Suchprofilanbietenden/zum Suchprofil? Dann schreibe sie gerne hierhin. 😊
            </div>
            <div className="mb-4">
                {alert !== undefined &&
                    <AlertBox alert={alert} autoDismiss={false} />
                }
                <TextAreaField
                    name="message"
                    label="Nachricht"
                    required={true}
                    value={messageText}
                    rows={10}
                    onChange={handleChange}
                />
                <div className="text-center mt-3">
                    <button className="btn btn-secondary ps-5 pe-5" onClick={persistMessage} disabled={conversation === undefined}>Absenden</button>
                </div>
            </div>
        </>
    );
};

export default ConversationInitialization;
