import MatchingService from '../../Matching/MatchingService';
import SettlementConceptActivationContingentService from '../../SettlementConcept/SettlementConceptActivationContingentService';
import Property from '../../Entity/Property/Property';
import MatchingResultGroup from '../../Entity/Matching/MatchingResultGroup';
import MatchType from '../../Entity/Matching/MatchType';
import SettlementConceptActivationContingent from '../../Entity/SettlementConcept/SettlementConceptActivationContingent';
import AdPartnerType from '../../Entity/AdPartner/AdPartnerType';
import MatchPointButton from './MatchPointButton';
import MatchingResultGroupCard from './MatchingResultGroupCard';
import UnlockMatchingResultGroupsOverlay from './UnlockMatchingResultGroupsOverlay';
import AdPartnerSection from '../../../user/AdPartner/AdPartnerSection';
import InfoBox from '../../Component/InfoBox/InfoBox';
import LoadingIndicator from '../../Component/LoadingIndicator/LoadingIndicator';
import React, {useEffect, useState} from 'react';

interface MatchingResultOverviewProps {
    readonly matchingService: MatchingService;
    readonly property: Property;
    readonly onUnlockMatchingResultsOverlayClose: () => void;
    readonly scrollPosition: number | null;
    readonly setScrollPosition: (scrollPosition: number | null) => void;
    readonly withAdPartner: boolean;
}

const settlementConceptActivationContingentService: SettlementConceptActivationContingentService = new SettlementConceptActivationContingentService(process.env.REACT_APP_LLASM_API_URL!);

const MatchingResultOverview = (props: MatchingResultOverviewProps): React.JSX.Element => {
    const [exactMatchingResultGroups, setExactMatchingResultGroups] = useState<MatchingResultGroup[]>();

    const [fromOtherIndustryClassificationsMatchingResultGroups, setFromOtherIndustryClassificationsMatchingResultGroups] = useState<MatchingResultGroup[]>();

    const [forUnlockMatchingResultGroups, setForUnlockMatchingResultGroups] = useState<MatchingResultGroup[]>();

    const [settlementConceptActivationContingents, setSettlementConceptActivationContingents] = useState<SettlementConceptActivationContingent[]>();

    const [singleActivationMatchingResultGroup, setSingleActivationMatchingResultGroup] = useState<MatchingResultGroup>();

    const [showSingleActivationUnlockMatchingResultGroupsOverlay, setShowSingleActivationUnlockMatchingResultGroupsOverlay] = useState<boolean>(false);

    const [singleActivationSettlementConceptActivationContingent, setSingleActivationSettlementConceptActivationContingent] = useState<SettlementConceptActivationContingent>();

    useEffect((): void => {
        fetchSettlementConceptActivationContingents();
    }, []);

    useEffect((): void => {
        fetchMatchingResultGroups(props.property);
    }, [props.property]);

    useEffect((): void => {
        if (settlementConceptActivationContingents === undefined) {
            return;
        }

        const settlementConceptActivationContingent: SettlementConceptActivationContingent | undefined = findSettlementConceptActivationContingentByNumberOfActivations(1);

        if (settlementConceptActivationContingent === undefined) {
            return;
        }

        setSingleActivationSettlementConceptActivationContingent(settlementConceptActivationContingent);
    }, [settlementConceptActivationContingents]);

    useEffect((): void => {
        if (exactMatchingResultGroups === undefined || fromOtherIndustryClassificationsMatchingResultGroups === undefined) {
            return;
        }

        const forUnlockMatchingResultGroups: MatchingResultGroup[] = exactMatchingResultGroups.slice();

        fromOtherIndustryClassificationsMatchingResultGroups.forEach((fromOtherIndustryClassificationMatchingResultGroup: MatchingResultGroup): void => {
            const duplicateMatchingResultGroup: MatchingResultGroup | undefined = exactMatchingResultGroups.find((exactMatchingResultGroup: MatchingResultGroup): boolean => {
                return fromOtherIndustryClassificationMatchingResultGroup.brand.id === exactMatchingResultGroup.brand.id;
            });

            if (duplicateMatchingResultGroup === undefined) {
                forUnlockMatchingResultGroups.push(fromOtherIndustryClassificationMatchingResultGroup);
            }
        });

        setForUnlockMatchingResultGroups(forUnlockMatchingResultGroups);
    }, [exactMatchingResultGroups, fromOtherIndustryClassificationsMatchingResultGroups]);

    useEffect((): void => {
        if (exactMatchingResultGroups === undefined || fromOtherIndustryClassificationsMatchingResultGroups === undefined) {
            return;
        }

        if (props.scrollPosition === null) {
            return;
        }

        setTimeout((): void => {
            window.scrollTo({top: props.scrollPosition!, behavior: 'instant'});

            props.setScrollPosition(null);
        }, 500);
    }, [exactMatchingResultGroups, fromOtherIndustryClassificationsMatchingResultGroups]);

    const fetchSettlementConceptActivationContingents = async (): Promise<void> => {
        const settlementConceptActivationContingents: SettlementConceptActivationContingent[] = await settlementConceptActivationContingentService.fetchSettlementConceptActivationContingentsFromApi();

        setSettlementConceptActivationContingents(settlementConceptActivationContingents);
    };

    const findSettlementConceptActivationContingentByNumberOfActivations = (numberOfActivations: number): SettlementConceptActivationContingent | undefined => {
        if (settlementConceptActivationContingents === undefined) {
            return undefined;
        }

        return settlementConceptActivationContingents.find((settlementConceptActivationContingent: SettlementConceptActivationContingent): boolean => {
            return settlementConceptActivationContingent.numberOfActivations === numberOfActivations;
        });
    };

    const fetchMatchingResultGroups = async (property: Property): Promise<void> => {
        await fetchExactMatchingResultGroupsByProperty(property);

        await fetchFromOtherIndustryClassificationsMatchingResultGroupsByProperty(property);
    };

    const fetchExactMatchingResultGroupsByProperty = async (property: Property): Promise<void> => {
        setExactMatchingResultGroups(await props.matchingService.fetchMatchingResultGroupsFromApiByProperty(property, MatchType.Exact));
    };

    const fetchFromOtherIndustryClassificationsMatchingResultGroupsByProperty = async (property: Property): Promise<void> => {
        setFromOtherIndustryClassificationsMatchingResultGroups(await props.matchingService.fetchMatchingResultGroupsFromApiByProperty(property, MatchType.FromOtherIndustryClassification));
    };

    const openSingleActivationUnlockMatchingResultGroupsOverlay = (matchingResultGroup: MatchingResultGroup): void => {
        props.setScrollPosition(window.scrollY);

        setSingleActivationMatchingResultGroup(matchingResultGroup);
        setShowSingleActivationUnlockMatchingResultGroupsOverlay(true);
    };

    if (exactMatchingResultGroups === undefined || fromOtherIndustryClassificationsMatchingResultGroups === undefined || forUnlockMatchingResultGroups === undefined || settlementConceptActivationContingents === undefined) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <>
            {props.withAdPartner === true &&
                <AdPartnerSection adPartnerType={AdPartnerType.Premium} />
            }
            {(exactMatchingResultGroups.length > 0 || fromOtherIndustryClassificationsMatchingResultGroups.length > 0) &&
                <>
                    {forUnlockMatchingResultGroups.length > 0 &&
                        <div className="mb-3">
                            <MatchPointButton
                                matchingResultGroups={forUnlockMatchingResultGroups}
                                settlementConceptActivationContingents={settlementConceptActivationContingents}
                                onUnlockMatchingResultsOverlayClose={props.onUnlockMatchingResultsOverlayClose}
                            />
                        </div>
                    }
                </>
            }

            {exactMatchingResultGroups.length > 0 &&
                <>
                    {(exactMatchingResultGroups.length === 1) ? (
                        <h4 className="mb-4">1 möglicher Nachnutzer, der exakt zu Deinen Anforderungen passt:</h4>
                    ) : (
                        <h4 className="mb-4">{exactMatchingResultGroups.length} mögliche Nachnutzer, die exakt zu Deinen Anforderungen passen:</h4>
                    )}

                    {singleActivationSettlementConceptActivationContingent !== undefined &&
                        <>
                            {exactMatchingResultGroups.map((exactMatchingResultGroup: MatchingResultGroup, index: number): React.JSX.Element => (
                                <MatchingResultGroupCard
                                    key={'matchingResult-' + index}
                                    matchingResultGroup={exactMatchingResultGroup}
                                    property={props.property}
                                    settlementConceptActivationContingent={singleActivationSettlementConceptActivationContingent}
                                    openSingleActivationUnlockMatchingResultGroupsOverlay={openSingleActivationUnlockMatchingResultGroupsOverlay}
                                    onUnlockMatchingResultsOverlayClose={props.onUnlockMatchingResultsOverlayClose}
                                />
                            ))}
                        </>
                    }
                    {props.withAdPartner === true &&
                        <>
                            <h4 className="mt-5 mb-4 text-center">
                                Keine passenden Nutzer dabei?<br />
                                Vielleicht können Dir unsere Partner bei der Nachnutzung Deiner Fläche behilflich sein:
                            </h4>
                            <AdPartnerSection adPartnerType={AdPartnerType.Standard} />
                        </>
                    }
                </>
            }

            {fromOtherIndustryClassificationsMatchingResultGroups.length > 0 &&
                <>
                    {(exactMatchingResultGroups.length > 0) ? (
                        <>
                            {(fromOtherIndustryClassificationsMatchingResultGroups.length === 1) ? (
                                <h4 className="mt-5 mb-4">1 weiterer passender Nachnutzer, der aus einer anderen Branche kommt:</h4>
                            ) : (
                                <h4 className="mt-5 mb-4">{fromOtherIndustryClassificationsMatchingResultGroups.length} weitere passende Nachnutzer, die aus anderen Branchen kommen:</h4>
                            )}
                        </>
                    ) : (
                        <>
                            <h4 className="mt-5 mb-4">Keine passenden Ergebnisse in den von Dir gewählten Kategorien</h4>
                            <InfoBox cardType="outline" className="p-3 mb-5">
                                <div className="fs-6 mb-3">
                                    Leider gibt es derzeit keine Ergebnisse, die exakt zu der von Dir gewählten
                                    Kategorie passen. Gerne kannst Du den von Dir gesetzten Branchenfilter ändern und das Matching erneut ausführen.
                                </div>
                                <div className="fs-6 mb-3">
                                    Ansonsten informieren wir Dich per E-Mail, sobald es passende Gesuche für Deine Fläche gibt.
                                </div>
                            </InfoBox>
                            {props.withAdPartner === true &&
                                <>
                                    <h4 className="mt-5 mb-4 text-center">
                                        Vielleicht können Dir unsere Partner bei der Nachnutzung Deiner Fläche behilflich sein:
                                    </h4>
                                    <AdPartnerSection adPartnerType={AdPartnerType.Standard} />
                                </>
                            }
                            {(fromOtherIndustryClassificationsMatchingResultGroups.length === 1) ? (
                                <h4 className="mt-5 mb-4">1 passender Nachnutzer, der aus einer anderen Branche kommt:</h4>
                            ) : (
                                <h4 className="mt-5 mb-4">{fromOtherIndustryClassificationsMatchingResultGroups.length} passende Nachnutzer, die aus anderen Branchen kommen:</h4>
                            )}
                        </>
                    )}

                    {singleActivationSettlementConceptActivationContingent !== undefined &&
                        <>
                            {fromOtherIndustryClassificationsMatchingResultGroups.map((fromOtherIndustryClassificationMatchingResultGroup: MatchingResultGroup, index: number): React.JSX.Element => (
                                <MatchingResultGroupCard
                                    key={'fromOtherIndustryClassificationMatchingResult-' + index}
                                    matchingResultGroup={fromOtherIndustryClassificationMatchingResultGroup}
                                    property={props.property}
                                    settlementConceptActivationContingent={singleActivationSettlementConceptActivationContingent}
                                    openSingleActivationUnlockMatchingResultGroupsOverlay={openSingleActivationUnlockMatchingResultGroupsOverlay}
                                    onUnlockMatchingResultsOverlayClose={props.onUnlockMatchingResultsOverlayClose}
                                />
                            ))}
                        </>
                    }
                </>
            }

            {(exactMatchingResultGroups.length > 0 || fromOtherIndustryClassificationsMatchingResultGroups.length > 0) &&
                <>
                    {singleActivationSettlementConceptActivationContingent !== undefined &&
                        <>
                            {singleActivationMatchingResultGroup !== undefined &&
                                <UnlockMatchingResultGroupsOverlay
                                    matchingResultGroups={[singleActivationMatchingResultGroup]}
                                    show={showSingleActivationUnlockMatchingResultGroupsOverlay}
                                    setShow={setShowSingleActivationUnlockMatchingResultGroupsOverlay}
                                    settlementConceptActivationContingent={singleActivationSettlementConceptActivationContingent}
                                    amount={singleActivationSettlementConceptActivationContingent.numberOfActivations}
                                    price={singleActivationSettlementConceptActivationContingent.matchPointCost}
                                    onUnlockMatchingResultsOverlayClose={props.onUnlockMatchingResultsOverlayClose}
                                />
                            }
                        </>
                    }

                    {forUnlockMatchingResultGroups.length > 0 &&
                        <div className="mb-3 mt-3">
                            <MatchPointButton
                                matchingResultGroups={forUnlockMatchingResultGroups}
                                settlementConceptActivationContingents={settlementConceptActivationContingents}
                                onUnlockMatchingResultsOverlayClose={props.onUnlockMatchingResultsOverlayClose}
                            />
                        </div>
                    }
                </>
            }

            {exactMatchingResultGroups.length === 0 && fromOtherIndustryClassificationsMatchingResultGroups.length === 0 &&
                <>
                    <h4>Mögliche Nachnutzer für Deine Fläche:</h4>
                    <div className="alert alert-warning mt-3 mb-5">
                        <div className="mb-0 fs-6">
                            Leider gibt es mit Deinen gewählten Parametern zur Zeit keine zu Deiner Fläche passenden Suchprofile in unserer Datenbank.
                            Bitte ändere Deine Angaben oder starte das Matching zu einem späteren Zeitpunkt noch einmal neu.
                        </div>
                    </div>
                    {props.withAdPartner === true &&
                        <div className="mb-5">
                            <h4 className="mt-5 mb-4 text-center">
                                Vielleicht können Dir unsere Partner bei der Nachnutzung Deiner Fläche behilflich sein:
                            </h4>
                            <AdPartnerSection adPartnerType={AdPartnerType.Standard} />
                        </div>
                    }
                </>
            }
        </>
    );
};

export default MatchingResultOverview;
