import Concept from './Concept';
import Pagination from '../Pagination/Pagination';
import Paginated from '../Pagination/Paginated';
import ConceptPaginatedResponse from '../../../../api/Llasm/shared/HallOfInspiration/ConceptPaginatedResponse';
import ConceptResponse from '../../../../api/Llasm/shared/HallOfInspiration/ConceptResponse';

class ConceptPaginated extends Paginated<Concept> {
    public static createFromConceptPaginatedResponse(conceptPaginatedResponse: ConceptPaginatedResponse): ConceptPaginated {
        return new this(
            Pagination.createFromPaginationResponse(conceptPaginatedResponse.pagination),
            conceptPaginatedResponse.concepts.map((conceptResponse: ConceptResponse): Concept => {
                return Concept.createFromConceptResponse(conceptResponse);
            })
        );
    }
}

export default ConceptPaginated;
