import Branch from '../Entity/HallOfInspiration/Branch';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import BranchResponse from '../../../api/Llasm/shared/HallOfInspiration/BranchResponse';
import {AxiosResponse} from 'axios';

class BranchService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/hall-of-inspiration/branches';
    }

    public async fetchBranchesFromApi(): Promise<Branch[]> {
        const apiResponse: AxiosResponse<BranchResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath);

        return apiResponse.data.map((branchResponse: BranchResponse): Branch => {
            return Branch.createFromBranchResponse(branchResponse);
        });
    }
}

export default BranchService;
