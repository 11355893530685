import Property from '../../Entity/Property/Property';
import {getLocationTypeLabel} from '../../Entity/LocationType';
import {getLocationCategoryLabel} from '../../Entity/LocationCategory';
import {getCentralPlaceTypeLabel} from '../../Entity/Location/CentralPlaceType';
import LocationFactor, {getLocationFactorLabel} from '../../Entity/LocationFactor';
import CollapseCard from '../../Component/CollapseCard/CollapseCard';
import LabelValuePair from '../../../../components/LabelValuePair';
import LabelValuesPair from '../../../../components/LabelValuesPair';
import React from 'react';

interface LocationDetailCollapseCardProps {
    readonly property: Property;
}

const LocationDetailCollapseCard = (props: LocationDetailCollapseCardProps): React.JSX.Element => {
    const locationFactorValues: string[] = props.property.locationDetail.locationFactors.map((locationFactor: LocationFactor): string => {
        return getLocationFactorLabel(locationFactor);
    });

    return (
        <CollapseCard cardType="shadow" title="Lage- und Standortinformationen" className="mb-3">
            <div className="row">
                {props.property.locationDetail.streetName !== null && props.property.locationDetail.houseNumber === null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Straße" value={props.property.locationDetail.streetName} />
                    </div>
                }
                {props.property.locationDetail.streetName !== null && props.property.locationDetail.houseNumber !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Straße, Hausnr." value={props.property.locationDetail.streetName + ' ' + props.property.locationDetail.houseNumber} />
                    </div>
                }
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <LabelValuePair
                        label="PLZ, Ort"
                        value={props.property.locationDetail.postalCode + ' ' + props.property.locationDetail.city!.placeName}
                    />
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <LabelValuePair
                        label="Land"
                        value={props.property.locationDetail.countryPlace.placeName}
                    />
                </div>
                {props.property.locationDetail.addressAffix !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Adresszusatz" value={props.property.locationDetail.addressAffix} />
                    </div>
                }
            </div>
            <div className="row">
                {props.property.locationDetail.locationDescription !== null &&
                    <div className="col-12 mb-3">
                        <LabelValuePair label="Lagebeschreibung" value={props.property.locationDetail.locationDescription} />
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-12 col-lg-8 mb-3">
                    <LabelValuePair
                        label="Standort-Typ"
                        value={getLocationTypeLabel(props.property.locationDetail.locationType)}
                    />
                </div>
                <div className="col-12 col-lg-4 mb-3">
                    <LabelValuePair
                        label="Lagekategorie"
                        value={getLocationCategoryLabel(props.property.locationDetail.locationCategory)}
                    />
                </div>
                {props.property.locationDetail.locationFactors.length > 0 &&
                    <div className="col-12 col-lg-8 mb-3">
                        <LabelValuesPair label="Mikrolage" values={locationFactorValues} delimiter=", " />
                    </div>
                }
                {(props.property.locationDetail.city !== undefined && props.property.locationDetail.city.advancedPlaceInformation !== null) &&
                    <>
                        {props.property.locationDetail.city.advancedPlaceInformation.population > 0 &&
                            <div className="col-12 col-md-6 col-lg-4  mb-3">
                                <LabelValuePair
                                    label="Einwohner am Standort"
                                    value={new Intl.NumberFormat('de-DE').format(props.property.locationDetail.city.advancedPlaceInformation.population)}
                                />
                            </div>
                        }
                        {props.property.locationDetail.city.advancedPlaceInformation.centralPlaceType !== null &&
                            <div className="col-12 col-md-6 col-lg-4  mb-3">
                                <LabelValuePair
                                    label="Ortsklassifizierung"
                                    value={getCentralPlaceTypeLabel(props.property.locationDetail.city.advancedPlaceInformation.centralPlaceType)}
                                />
                            </div>
                        }
                        {(props.property.locationDetail.city.advancedPlaceInformation.purchasingPowerPerYear !== null && props.property.locationDetail.city.advancedPlaceInformation.purchasingPowerPerYear > 0) &&
                            <div className="col-12 col-md-6 col-lg-4  mb-3">
                                <LabelValuePair
                                    label="Kaufkraft/Jahr"
                                    value={new Intl.NumberFormat('de-DE').format(props.property.locationDetail.city.advancedPlaceInformation.purchasingPowerPerYear) + ' €'}
                                />
                            </div>
                        }
                        {(props.property.locationDetail.city.advancedPlaceInformation.purchasingPower !== null && props.property.locationDetail.city.advancedPlaceInformation.purchasingPower > 0) &&
                            <div className="col-12 col-md-6 col-lg-4  mb-3">
                                <LabelValuePair
                                    label="KKP-Index"
                                    value={new Intl.NumberFormat('de-DE').format(props.property.locationDetail.city.advancedPlaceInformation.purchasingPower)}
                                />
                            </div>
                        }
                    </>
                }
            </div>
        </CollapseCard>
    );
};

export default LocationDetailCollapseCard;
