import BootstrapIcon from '../Icon/BootstrapIcon';
import React from 'react';

interface CollapseButtonProps extends React.PropsWithChildren {
    readonly title: string;
    readonly expanded: boolean;
    readonly onClick: () => void;
    readonly icon?: React.JSX.Element;
}

const CollapseButton = (props: CollapseButtonProps): React.JSX.Element => {
    return (
        <div className="d-flex align-items-center cursor-pointer me-3" onClick={props.onClick}>
            {props.icon !== undefined &&
                <>{props.icon}</>
            }
            <span className="me-1">{props.title}</span>
            {props.expanded === true &&
                <BootstrapIcon iconName="caret-up-fill" />
            }
            {props.expanded === false &&
                <BootstrapIcon iconName="caret-down-fill" />
            }
        </div>
    );
};

export default CollapseButton;
