import ConversationService from './ConversationService';
import ConversationEntity from '../Entity/Messaging/Conversation';
import ProviderSeekerMessage from '../Entity/Messaging/ProviderSeekerMessage';
import MessageEntity from '../Entity/Messaging/Message';
import MessageWrapper from '../Entity/Messaging/MessageWrapper';
import MessageType from '../Entity/Messaging/MessageType';
import ProviderSeekerConversation from '../Entity/Messaging/ProviderSeekerConversation';
import PropertyInterest from '../Entity/PropertyInterest/PropertyInterest';
import ParticipatingUser from '../Entity/Messaging/ParticipatingUser';
import Message from './Message';
import ContactIntentionNotificationMessageText from './Message/ContactIntentionNotificationMessageText';
import PropertyInterestNotificationMessageText from './Message/PropertyInterestNotificationMessageText';
import PropertyInterestFeedbackButton from './PropertyInterest/PropertyInterestFeedbackButton';
import PropertyInterestFeedbackStatus from './PropertyInterest/PropertyInterestFeedbackStatus';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import Divider from '../Component/Divider/Divider';
import LoadingIndicator from '../Component/LoadingIndicator/LoadingIndicator';
import messagingStyle from './Messaging.module.scss';
import conversationStyle from './Conversation.module.scss';
import React, {useCallback, useEffect, useRef, useState} from 'react';

interface ConversationProps {
    readonly conversation: ConversationEntity;
    readonly updateConversation: (conversation: ConversationEntity) => void;
    readonly updateConversationList?: () => void;
}

enum ScrollType {
    Direct = 0,
    Smooth = 1
}

const conversationService: ConversationService = new ConversationService(process.env.REACT_APP_LLASM_API_URL!);

const messageReloadInterval: number = 30000;

let isScrolledToBottom: boolean = false;
let shouldScrollToBottom: boolean = false;
let shouldScrollToFirstUnreadMessage: boolean = false;
let scrollType: ScrollType = ScrollType.Direct;
let messageReloadTimeout: NodeJS.Timeout | number | undefined = undefined;

const Conversation = (props: ConversationProps): React.JSX.Element => {
    const node: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const chatBottom: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const [firstUnreadMessage, setFirstUnreadMessage] = useState<MessageEntity | undefined>();

    const [messages, setMessages] = useState<MessageWrapper[] | undefined>();

    const [messageText, setMessageText] = useState<string>();

    const [propertyInterest, setPropertyInterest] = useState<PropertyInterest | null>();

    const firstUnreadMessageRef: (node: HTMLDivElement) => void = useCallback(node => {
        if (node !== null && shouldScrollToFirstUnreadMessage === true) {
            node.scrollIntoView();
            shouldScrollToFirstUnreadMessage = false;
        }
    }, []);

    useEffect((): void => {
        isScrolledToBottom = false;
        shouldScrollToBottom = false;
        shouldScrollToFirstUnreadMessage = false;

        if (messageReloadTimeout !== undefined) {
            clearTimeout(messageReloadTimeout);
        }

        setMessages(undefined);

        if (props.conversation instanceof ProviderSeekerConversation) {
            if (props.conversation.propertyInterests.length > 0) {
                setPropertyInterest(props.conversation.propertyInterests[props.conversation.propertyInterests.length - 1]);
            } else {
                setPropertyInterest(null);
            }
        }
    }, [props.conversation]);

    useEffect((): () => void => {
        if (messages !== undefined) {
            messageReloadTimeout = setTimeout((): void => {
                reloadConversation();
            }, messageReloadInterval);
        }

        return (): void => {
            if (messageReloadTimeout !== undefined) {
                clearTimeout(messageReloadTimeout);
            }
        };
    }, [messages]);

    useEffect((): void => {
        if (chatBottom.current === null) {
            return;
        }

        const intersectionObserver: IntersectionObserver = new IntersectionObserver((intersectionObserverEntries: IntersectionObserverEntry[]): void => {
            const intersectionObserverEntry: IntersectionObserverEntry = intersectionObserverEntries[0];

            if (intersectionObserverEntry.isIntersecting === true) {
                isScrolledToBottom = true;
            } else {
                isScrolledToBottom = false;
            }
        });

        intersectionObserver.observe(chatBottom.current as Element);
    }, [chatBottom.current]);

    useEffect((): void => {
        if (messages !== undefined) {
            return;
        }

        fetchAndProcessMessages();
    }, [messages]);

    useEffect((): void => {
        if (shouldScrollToBottom === true) {
            scrollToBottom();
        }
    }, [messages]);

    useEffect((): void => {
        if (messages === undefined || messages.length === 0) {
            return;
        }

        const unreadMessages: MessageWrapper[] = filterForUnreadMessages(messages);

        props.conversation.unreadMessages = unreadMessages.length > 0;
        props.conversation.lastMessageAt = messages[messages.length - 1].message.createdAt;

        if (props.updateConversationList !== undefined) {
            props.updateConversationList();
        }
    }, [messages]);

    const scrollToBottom = (): void => {
        node.current!.scrollTop = node.current!.scrollHeight;
    };

    const isMessageTextEmpty = (): boolean => {
        return messageText === undefined || messageText === '';
    };

    const showDateDivider = (message: MessageEntity): React.JSX.Element => {
        const today: Date = new Date();

        if (message.createdAt.toDateString() === today.toDateString()) {
            return <Divider color="primary">Heute</Divider>;
        }

        const yesterday: Date = new Date();

        yesterday.setDate(yesterday.getDate() - 1);

        if (message.createdAt.toDateString() === yesterday.toDateString()) {
            return <Divider color="primary">Gestern</Divider>;
        }

        return <Divider color="primary">{message.createdAt.toLocaleDateString('de-DE', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</Divider>;
    };

    const createMessageFromMessageText = (messageText: string): MessageEntity => {
        const message: MessageEntity = new ProviderSeekerMessage('providerSeekerMessage' + (new Date()).valueOf(), MessageType.Standard);

        message.text = messageText;
        message.createdAt = new Date();
        message.isSender = true;

        return message;
    };

    const fetchCorrespondingParticipatingUserFromProviderSeekerConversation = (providerSeekerConversation: ProviderSeekerConversation): ParticipatingUser => {
        let participatingUser: ParticipatingUser;

        if (providerSeekerConversation.property.participatingUser.id === null) {
            participatingUser = providerSeekerConversation.property.participatingUser;
        } else {
            participatingUser = providerSeekerConversation.settlementConcept.participatingUser;
        }

        return participatingUser;
    }

    const createProviderSeekerMessageFromConversationContactIntension = (): ProviderSeekerMessage => {
        if (props.conversation instanceof ProviderSeekerConversation === false) {
            throw new Error();
        }

        if (props.conversation.contactIntention === null) {
            throw new Error();
        }

        const providerSeekerMessage: ProviderSeekerMessage = new ProviderSeekerMessage('contactIntension' + props.conversation.contactIntention.createdAt.valueOf(), MessageType.Notification);

        providerSeekerMessage.isSender = props.conversation.property.participatingUser.id !== null;
        providerSeekerMessage.senderUser = props.conversation.property.participatingUser;
        providerSeekerMessage.text = <ContactIntentionNotificationMessageText participatingUser={props.conversation.correspondingParticipatingUser} isSender={providerSeekerMessage.isSender} />;
        providerSeekerMessage.createdAt = props.conversation.contactIntention.createdAt;
        providerSeekerMessage.unread = false;

        return providerSeekerMessage;
    };

    const createProviderSeekerMessageFromPropertyInterest = (propertyInterest: PropertyInterest): ProviderSeekerMessage => {
        if (props.conversation instanceof ProviderSeekerConversation === false) {
            throw new Error();
        }

        const participatingUser: ParticipatingUser = fetchCorrespondingParticipatingUserFromProviderSeekerConversation(props.conversation);

        const providerSeekerMessage: ProviderSeekerMessage = new ProviderSeekerMessage('propertyInterest' + propertyInterest.createdAt.valueOf(), MessageType.Notification);

        providerSeekerMessage.isSender = props.conversation.settlementConcept.participatingUser.id !== null;
        providerSeekerMessage.senderUser = props.conversation.settlementConcept.participatingUser;
        providerSeekerMessage.text = <PropertyInterestNotificationMessageText participatingUser={participatingUser} propertyInterestFeedback={propertyInterest.propertyInterestFeedback} isSender={providerSeekerMessage.isSender} />;
        providerSeekerMessage.createdAt = propertyInterest.createdAt;
        providerSeekerMessage.unread = false;

        return providerSeekerMessage;
    }

    const createProviderSeekerMessagesFromConversationPropertyInterests = (providerSeekerConversation: ProviderSeekerConversation): ProviderSeekerMessage[] => {
        return providerSeekerConversation.propertyInterests.map((propertyInterest: PropertyInterest): ProviderSeekerMessage => {
            return createProviderSeekerMessageFromPropertyInterest(propertyInterest);
        });
    };

    const fetchMessages = async (): Promise<MessageEntity[]> => {
        let messages: MessageEntity[] = [];

        if (props.conversation instanceof ProviderSeekerConversation) {
            messages = createProviderSeekerMessagesFromConversationPropertyInterests(props.conversation);

            if (props.conversation.contactIntention !== null) {
                messages.push(createProviderSeekerMessageFromConversationContactIntension());
            }
        }

        try {
            const fetchedMessages: MessageEntity[] = await conversationService.fetchMessagesFromApi(props.conversation);

            return messages.concat(fetchedMessages).sort((a: MessageEntity, b: MessageEntity): number => {
                if (a.createdAt < b.createdAt) {
                    return -1;
                }

                if (a.createdAt > b.createdAt) {
                    return 1;
                }

                return 0;
            });
        } catch (error) {
            throw error;
        }
    };

    const reloadConversation = async (): Promise<void> => {
        if (props.conversation instanceof ProviderSeekerConversation) {
            const conversation: ProviderSeekerConversation = await conversationService.fetchConversationFromApiById(props.conversation.conversationType, props.conversation.id!) as ProviderSeekerConversation;

            props.updateConversation(conversation);
        }

        fetchAndProcessMessages();
    };

    const fetchAndProcessMessages = async (): Promise<void> => {
        try {
            const fetchedMessages: MessageEntity[] = await fetchMessages();

            const messageWrappers: MessageWrapper[] = fetchedMessages.map((message: MessageEntity): MessageWrapper => {
                return {
                    message: message,
                    isLoading: false
                }
            });

            if (messages === undefined) {
                const unreadMessages: MessageWrapper[] = filterForUnreadMessages(messageWrappers);

                if (unreadMessages.length > 0) {
                    shouldScrollToFirstUnreadMessage = true;
                    shouldScrollToBottom = false;

                    setFirstUnreadMessage(unreadMessages[0].message);
                } else {
                    shouldScrollToBottom = true;
                }
            } else if (isScrolledToBottom === true) {
                shouldScrollToBottom = true;
            } else {
                shouldScrollToBottom = false;
            }

            setMessages(messageWrappers);
        } catch (error) {
            throw error;
        }
    };

    const filterForUnreadMessages = (messages: MessageWrapper[]): MessageWrapper[] => {
        return messages.filter((messageWrapper: MessageWrapper): boolean => {
            if (messageWrapper.message.isSender === true) {
                return false;
            }

            if (messageWrapper.message.messageType === MessageType.Deleted) {
                return false;
            }

            return messageWrapper.message.unread === true;
        });
    };

    const updateMessageList = (): void => {
        if (messages === undefined) {
            return;
        }

        setMessages(messages.concat([]));
    };

    const handleMessageTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setMessageText(event.target.value);
    };

    const sendMessage = async (): Promise<void> => {
        if (isMessageTextEmpty() === true) {
            return;
        }

        const message: MessageEntity = createMessageFromMessageText(messageText!);

        setMessageText('');

        const messageWrapper: MessageWrapper = {
            message: message,
            isLoading: true
        }

        const providerSeekerMessagesWithAddedMessage: MessageWrapper[] = (messages !== undefined) ? messages.concat([messageWrapper]) : [messageWrapper];

        const listItemIndex: number = providerSeekerMessagesWithAddedMessage.length - 1;

        await setMessages(providerSeekerMessagesWithAddedMessage);

        shouldScrollToBottom = true;

        try {
            const persistedMessage: ProviderSeekerMessage = await conversationService.persistMessage(
                props.conversation,
                message
            );

            setMessages((prevProviderSeekerMessages: MessageWrapper[] | undefined): MessageWrapper[] => {
                const persistedMessageWrapper: MessageWrapper = {
                    message: persistedMessage,
                    isLoading: false
                };

                if (prevProviderSeekerMessages === undefined) {
                    return [persistedMessageWrapper];
                }

                prevProviderSeekerMessages[listItemIndex] = persistedMessageWrapper;

                return prevProviderSeekerMessages.concat([]);
            });

            if (props.updateConversationList !== undefined) {
                props.updateConversationList();
            }
        } catch (error) {
            throw error;
        }
    };

    const deleteMessage = async (messageWrapper: MessageWrapper): Promise<void> => {
        if (messages === undefined) {
            return;
        }

        messageWrapper.message.text = null;
        messageWrapper.isLoading = true;

        setMessages(messages.concat([]));

        await conversationService.deleteMessage(props.conversation, messageWrapper.message);

        messageWrapper.isLoading = false;

        messageWrapper.message.messageType = MessageType.Deleted;

        setMessages(messages.concat([]));
    };

    const onPropertyInterestCreated = (propertyInterest: PropertyInterest): void => {
        if (props.conversation instanceof ProviderSeekerConversation === false) {
            return;
        }

        const message: MessageEntity = createProviderSeekerMessageFromPropertyInterest(propertyInterest);

        const messageWrapper: MessageWrapper = {
            message: message,
            isLoading: true
        }

        const providerSeekerMessagesWithAddedMessage: MessageWrapper[] = (messages !== undefined) ? messages.concat([messageWrapper]) : [messageWrapper];

        setMessages(providerSeekerMessagesWithAddedMessage);

        shouldScrollToBottom = true;

        if (props.updateConversationList !== undefined) {
            props.updateConversationList();
        }
    };

    if (messages === undefined) {
        return (
            <div className={messagingStyle.loadingIndicatorContainer}>
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <>
            <div ref={node} className={['position-relative', 'p-3', 'px-lg-4', 'mb-2', conversationStyle.conversationContainer].join(' ')}>
                {messages.map((messageWrapper: MessageWrapper, index: number): React.JSX.Element => (
                    <div key={messageWrapper.message.identifier}>
                        {(index === 0 || messageWrapper.message.createdAt.toDateString() !== messages[index - 1].message.createdAt.toDateString()) &&
                            showDateDivider(messageWrapper.message)
                        }

                        {(messageWrapper.message.messageType === MessageType.Notification) ? (
                            <Message conversation={props.conversation} messageWrapper={messageWrapper} />
                        ) : (
                            <>
                                {(messageWrapper.message.isSender === true) ? (
                                    <div className="d-flex justify-content-end">
                                        <div className={conversationStyle.messageContainer}>
                                            <Message conversation={props.conversation} messageWrapper={messageWrapper} deleteMessage={deleteMessage} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-start">
                                        {messageWrapper.message.unread === true && firstUnreadMessage !== undefined && firstUnreadMessage === messageWrapper.message &&
                                            <div ref={firstUnreadMessageRef}></div>
                                        }
                                        <div className={conversationStyle.messageContainer}>
                                            <Message conversation={props.conversation} messageWrapper={messageWrapper} updateMessageList={updateMessageList} updateConversationList={props.updateConversationList} />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ))}
                <div ref={chatBottom}></div>
                {props.conversation instanceof ProviderSeekerConversation && propertyInterest !== undefined && props.conversation.settlementConcept.participatingUser.id !== null &&
                    <div className="position-sticky bottom-0 d-flex justify-content-end">
                        <PropertyInterestFeedbackButton providerSeekerConversation={props.conversation} propertyInterest={propertyInterest} setPropertyInterest={setPropertyInterest} onPropertyInterestCreated={onPropertyInterestCreated} />
                    </div>
                }
            </div>
            {props.conversation instanceof ProviderSeekerConversation && propertyInterest !== undefined && props.conversation.settlementConcept.participatingUser.id !== null &&
                <div className="d-flex justify-content-end mb-1">
                    <PropertyInterestFeedbackStatus propertyInterest={propertyInterest} participatingUser={fetchCorrespondingParticipatingUserFromProviderSeekerConversation(props.conversation)} />
                </div>
            }
            <div className="mb-2">
                <TextAreaField name="newMessage" rows={4} placeholder="Schreibe etwas..." value={messageText} onChange={handleMessageTextAreaChange} />
            </div>
            <div className="mb-3 ps-2">
                <button className="btn btn-primary d-flex align-items-center mt-3" onClick={sendMessage} disabled={isMessageTextEmpty()}>
                    <i className="bi bi-send-fill me-2"></i>
                    Senden
                </button>
            </div>
        </>
    );
};

export default Conversation;
