import User from '../Entity/User/User';
import UserPasswordResetDemand from '../Entity/User/UserPasswordResetDemand';
import UserPasswordReset from '../Entity/User/UserPasswordReset';
import BillingAddress from '../Entity/User/BillingAddress';
import UserEmailChange from '../Entity/User/UserEmailChange';
import UserPasswordChange from '../Entity/User/UserPasswordChange';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import UserResponse from '../../../api/Llasm/shared/User/UserResponse';
import UserPasswordResetDemandResponse from '../../../api/Llasm/shared/User/UserPasswordResetDemandResponse';
import UserPatchRequest from '../../../api/Llasm/shared/User/UserPatchRequest';
import UserPasswordResetDemandPostRequest from '../../../api/Llasm/shared/User/UserPasswordResetDemandPostRequest';
import UserPasswordResetPostRequest from '../../../api/Llasm/shared/User/UserPasswordResetPostRequest';
import DefaultBillingAddressResponse from '../../../api/Llasm/shared/User/DefaultBillingAddressResponse';
import DefaultBillingAddressPostRequest from '../../../api/Llasm/shared/User/DefaultBillingAddressPostRequest';
import DefaultBillingAddressPatchRequest from '../../../api/Llasm/shared/User/DefaultBillingAddressPatchRequest';
import UserEmailChangePostRequest from '../../../api/Llasm/shared/User/UserEmailChangePostRequest';
import UserPasswordChangePostRequest from '../../../api/Llasm/shared/User/UserPasswordChangePostRequest';
import {AxiosResponse} from 'axios';

class UserService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/users';
    }

    public async fetchUsersFromApi(): Promise<User[]> {
        const apiResponse: AxiosResponse<UserResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath);

        return apiResponse.data.map((userResponse: UserResponse): User => {
            return User.createFromUserResponse(userResponse);
        });
    }

    public async fetchUserFromApiById(id: number): Promise<User> {
        const apiResponse: AxiosResponse<UserResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + id);

        return User.createFromUserResponse(apiResponse.data);
    }

    public async fetchUserPasswordResetDemandFromApiByUuid(uuid: string): Promise<UserPasswordResetDemand> {
        const apiResponse: AxiosResponse<UserPasswordResetDemandResponse> = await this.llasmApiV1Provider.get('/user-password-reset-demands' + '/' + uuid);

        return UserPasswordResetDemand.createFromUserPasswordResetDemandResponse(apiResponse.data);
    }

    public async updateUser(user: User): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.patch<null, AxiosResponse<null>, UserPatchRequest>(
            this.resourcePath  + '/' + user.id,
            UserService.mapFromUserToUserPatchRequest(user)
        );
    }

    public async persistUserPasswordResetDemand(userPasswordResetDemandPostRequest: UserPasswordResetDemandPostRequest): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.post<null, AxiosResponse<null>, UserPasswordResetDemandPostRequest>(
            '/user-password-reset-demands',
            userPasswordResetDemandPostRequest
        );
    }

    public async resetPassword(uuid: string, userPasswordReset: UserPasswordReset): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.post<null, AxiosResponse<null>, UserPasswordResetPostRequest>(
            '/user-password-reset-demands' + '/' + uuid + '/reset',
            UserService.mapFromUserPasswordResetToUserPasswordResetPostRequest(userPasswordReset)
        );
    }

    public async fetchBillingAddressFromApiByUser(user: User): Promise<BillingAddress | null> {
        const apiResponse: AxiosResponse<DefaultBillingAddressResponse[]> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + user.id + '/default-billing-address');

        if (apiResponse.data.length !== 1) {
            return null;
        }

        return BillingAddress.createFromBillingAddressResponse(apiResponse.data[0]);
    }

    public async persistBillingAddress(user: User, billingAddress: BillingAddress): Promise<BillingAddress> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, DefaultBillingAddressPostRequest>(
            this.resourcePath + '/' + user.id + '/default-billing-address',
            UserService.mapFromBillingAddressToDefaultBillingAddressPostRequest(billingAddress)
        );

        const apiResponse: AxiosResponse<DefaultBillingAddressResponse[]> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        if (apiResponse.data.length !== 1) {
            throw new Error();
        }

        return BillingAddress.createFromBillingAddressResponse(apiResponse.data[0]);
    }

    public async updateBillingAddress(user: User, billingAddress: BillingAddress): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.patch<null, AxiosResponse<null>, DefaultBillingAddressPatchRequest>(
            this.resourcePath + '/' + user.id + '/default-billing-address'  + '/' + billingAddress.id,
            UserService.mapFromBillingAddressToDefaultBillingAddressPatchRequest(billingAddress)
        );
    }

    public async persistUserEmailChange(userEmailChange: UserEmailChange): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.post<null, AxiosResponse<null>, UserEmailChangePostRequest>(
            '/user-email-change',
            UserService.mapFromUserEmailChangeToUserEmailChangePostRequest(userEmailChange)
        );
    }

    public async persistUserPasswordChange(userPasswordChange: UserPasswordChange): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.post<null, AxiosResponse<null>, UserPasswordChangePostRequest>(
            '/user-password-change',
            UserService.mapFromUserPasswordChangeToUserPasswordChangePostRequest(userPasswordChange)
        );
    }

    public async confirmUserEmailChange(userEmailChangeUuid: string): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.get('/user-email-change/' + userEmailChangeUuid);
    }

    private static mapFromUserToUserPatchRequest(user: User): UserPatchRequest {
        return {
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            streetName: user.streetName,
            houseNumber: user.houseNumber,
            postalCode: user.postalCode,
            placeName: user.placeName,
            countryId: user.country.id!,
            imprint: user.imprint
        };
    }

    private static mapFromUserPasswordResetToUserPasswordResetPostRequest(userPasswordReset: UserPasswordReset): UserPasswordResetPostRequest {
        return {
            password: userPasswordReset.password,
            passwordRepeat: userPasswordReset.passwordRepeat
        };
    }

    private static mapFromBillingAddressToDefaultBillingAddressPostRequest(billingAddress: BillingAddress): DefaultBillingAddressPostRequest {
        return UserService.mapBillingAddressToApiRequest(billingAddress) as DefaultBillingAddressPostRequest;
    }

    private static mapFromBillingAddressToDefaultBillingAddressPatchRequest(billingAddress: BillingAddress): DefaultBillingAddressPatchRequest {
        return UserService.mapBillingAddressToApiRequest(billingAddress) as DefaultBillingAddressPatchRequest;
    }

    private static mapBillingAddressToApiRequest(billingAddress: BillingAddress): DefaultBillingAddressPostRequest | DefaultBillingAddressPatchRequest {
        return {
            firstName: billingAddress.firstName,
            lastName: billingAddress.lastName,
            companyName: billingAddress.companyName,
            streetName: billingAddress.streetName,
            houseNumber: billingAddress.houseNumber,
            postalCode: billingAddress.postalCode,
            placeName: billingAddress.placeName,
            email: billingAddress.email
        };
    }

    private static mapFromUserEmailChangeToUserEmailChangePostRequest(userEmailChange: UserEmailChange): UserEmailChangePostRequest {
        return {
            email: userEmailChange.newEmail
        };
    }

    private static mapFromUserPasswordChangeToUserPasswordChangePostRequest(userPasswordChange: UserPasswordChange): UserPasswordChangePostRequest {
        return {
            passwordOld: userPasswordChange.passwordOld,
            password: userPasswordChange.password,
            passwordRepeat:userPasswordChange.passwordRepeat
        };
    }
}

export default UserService;
