import BranchService from './BranchService';
import Branch from '../Entity/HallOfInspiration/Branch';
import Concept from '../Entity/HallOfInspiration/Concept';
import ConceptFetchParameter from '../Entity/HallOfInspiration/ConceptFetchParameter';
import ConceptFilter from '../Entity/HallOfInspiration/ConceptFilter';
import ConceptPaginated from '../Entity/HallOfInspiration/ConceptPaginated';
import PaginationParameter from '../Entity/Pagination/PaginationParameter';
import SortingOption from '../Entity/SortingOption/SortingOption';
import SortingDirection from '../Entity/SortingOption/SortingDirection';
import SelectOption from '../Entity/Form/SelectOption';
import ConceptService from './ConceptService';
import ConceptCard from './ConceptCard';
import Filter from './Filter';
import Sorting from '../Sorting';
import Pagination from '../Pagination/Pagination';
import Card from '../Component/Card/Card';
import InfoButtonOverlay from '../Component/InfoButtonOverlay/InfoButtonOverlay';
import CollapseButton from '../Component/CollapseButton/CollapseButton';
import BootstrapIcon from '../Component/Icon/BootstrapIcon';
import Spinner from '../../../components/Spinner';
import {useAppDispatch} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';

const conceptService: ConceptService = new ConceptService(process.env.REACT_APP_LLASM_API_URL!);
const branchService: BranchService = new BranchService(process.env.REACT_APP_LLASM_API_URL!);

const sortingOptions: SortingOption[] = [
    new SortingOption('createdAt', SortingDirection.Descending, 'Neueste Einträge zuerst', 'sort-down-alt'),
    new SortingOption('createdAt', SortingDirection.Ascending, 'Älteste Einträge zuerst', 'sort-down'),
];

const paginationLimitOptions: SelectOption<number>[] = [
    {label: '12', value: 12},
    {label: '48', value: 48},
    {label: '96', value: 96},
];

const ConceptOverviewPage = (): React.JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [branches, setBranches] = useState<Branch[]>();

    const [conceptFetchParameter, setConceptFetchParameter] = useState<ConceptFetchParameter>();

    const [conceptPaginated, setConceptPaginated] = useState<ConceptPaginated | undefined>();

    const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const scrollPosition: number | null = searchParams.get('scroll-position') !== null ? Number(searchParams.get('scroll-position')) : null;

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Hall of Inspiration'},
            ]
        });
    }, [dispatch]);

    useEffect(() => {
        fetchBranches();
    }, []);

    useEffect((): void => {
        const conceptFilter: ConceptFilter = ConceptFilter.createDefault();

        setConceptFetchParameter({
            conceptFilter: conceptFilter,
            sortingOption: sortingOptions.find((sortingOption: SortingOption): boolean => {
                return sortingOption.sortingBy === 'createdAt' && sortingOption.sortingDirection === SortingDirection.Descending;
            }) ?? null,
            paginationParameter: new PaginationParameter(1, paginationLimitOptions[0].value)
        });
    }, []);

    useEffect((): void => {
        if (conceptFetchParameter === undefined) {
            return;
        }

        fetchConceptPaginated();
    }, [conceptFetchParameter]);

    useEffect((): void => {
        if (conceptPaginated === undefined) {
            return;
        }

        if (scrollPosition === null) {
            return;
        }

        window.scrollTo({top: scrollPosition});
    }, [conceptPaginated]);

    const fetchBranches = async (): Promise<void> => {
        setBranches(await branchService.fetchBranchesFromApi());
    };

    const fetchConceptPaginated = async (): Promise<void> => {
        if (conceptFetchParameter === undefined) {
            return;
        }

        setConceptPaginated(undefined);

        setConceptPaginated(await conceptService.fetchConceptsFromApi(
            conceptFetchParameter.conceptFilter,
            conceptFetchParameter.sortingOption ?? undefined,
            conceptFetchParameter.paginationParameter
        ));
    };

    const toggleIsFilterExpanded = (): void => {
        setIsFilterExpanded(isFilterExpanded === false);
    };

    const updateFromFilter = (conceptFilter: ConceptFilter): void => {
        if (conceptFetchParameter === undefined) {
            return;
        }

        conceptFetchParameter.paginationParameter.page = 1;

        setConceptFetchParameter({
            conceptFilter: conceptFilter,
            sortingOption: conceptFetchParameter.sortingOption,
            paginationParameter: conceptFetchParameter.paginationParameter
        });
    };

    const updateFromSorting = (sortingOption: SortingOption | null): void => {
        if (conceptFetchParameter === undefined) {
            return;
        }

        conceptFetchParameter.paginationParameter.page = 1;

        setConceptFetchParameter({
            conceptFilter: conceptFetchParameter.conceptFilter,
            sortingOption: sortingOption,
            paginationParameter: conceptFetchParameter.paginationParameter
        });
    };

    const updateFromPagination = (paginationParameter: PaginationParameter): void => {
        if (conceptFetchParameter === undefined) {
            return;
        }

        setConceptFetchParameter({
            conceptFilter: conceptFetchParameter.conceptFilter,
            sortingOption: conceptFetchParameter.sortingOption,
            paginationParameter: paginationParameter
        });
    };

    if (branches === undefined || conceptFetchParameter === undefined) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            {conceptPaginated === undefined &&
                <Spinner />
            }

            <div className="row align-items-center mb-4">
                <div className="col-12 col-md-6 d-flex align-items-center">
                    <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Hall of Inspiration</h1>
                </div>
                <div className="col-12 col-md-6 d-md-flex align-items-center justify-content-end">
                    <InfoButtonOverlay title="Hall of Inspiration" className="fs-5 ms-1 me-3">
                        <h5 className="fw-bold mb-3">
                            Was ist die „Hall of Inspiration“?
                        </h5>
                        <div className="mb-5">
                            Die „Hall of Inspiration“ ist ein digitaler Ort, der innovative Nutzungskonzepte mit dem
                            gewissen Etwas einer breiten Zielgruppe vorstellt. Dieses virtuelle Schaufenster vereinfacht
                            eine gebündelte Suche nach innovativen Nutzungskonzepten aus Branchen wie zum Beispiel
                            Kunst, Kultur oder Gastro und dient Kommunen, Wirtschaftsförderungen und Immobilienbesitzer
                            aus ganz Deutschland als Inspirationsquelle für besondere Impulse, die unsere Innenstädte
                            bunter gestalten.
                        </div>
                        <h5 className="fw-bold mb-3">
                            Dein Unternehmen hat ein besonderes Konzept?
                        </h5>
                        <div className="mb-3">
                            Dann bewirb Dich kostenfrei für die „Hall of Inspiration“ und mach auf Dein Konzept
                            aufmerksam. Anhand von Bildern, Videos und einer Konzeptbeschreibung, die Du bei uns
                            einreichst, entscheidet unser Team über die Veröffentlichung. Los geht´s!
                        </div>
                        <div className="mb-5">
                            <a href='https://www.llasm.de/hall-of-inspiration' target="_blank" className="btn btn-primary">
                                <i className="bi bi-plus-lg"></i> Inspiration einreichen
                            </a>
                        </div>
                    </InfoButtonOverlay>
                    <a href='https://www.llasm.de/hall-of-inspiration' target="_blank" className="btn btn-secondary">
                        <i className="bi bi-plus-lg"></i> Inspiration einreichen
                    </a>
                </div>
            </div>
            <div className="d-lg-flex">
                <div className="ms-auto mb-3 d-flex align-content-stretch justify-content-end">
                    <CollapseButton
                        title="FILTER"
                        expanded={isFilterExpanded}
                        onClick={toggleIsFilterExpanded}
                        icon={<BootstrapIcon iconName="funnel" className="me-2" />}
                    />
                    <div className="d-flex align-items-center">
                        <Sorting
                            sortingOptions={sortingOptions}
                            selectedSortingOption={conceptFetchParameter.sortingOption}
                            onChange={updateFromSorting}
                        />
                    </div>
                </div>
            </div>
            {isFilterExpanded === true &&
                <Card cardType="shadow" className="mb-3 p-3">
                    <h2>Filter</h2>
                    <Filter
                        branches={branches}
                        conceptFilter={conceptFetchParameter.conceptFilter}
                        onSubmit={updateFromFilter}
                    />
                </Card>
            }
            {conceptPaginated !== undefined &&
                <>
                    {conceptPaginated.results.length > 0 &&
                        <>
                            <div className="d-flex flex-wrap gap-4 pb-4">
                                {conceptPaginated.results.map((concept: Concept): React.JSX.Element => (
                                    <ConceptCard key={'concept-' + concept.id} concept={concept} className="d-flex flex-column justify-content-between" />
                                ))}
                            </div>
                            <div className="pb-3">
                                <Pagination pagination={conceptPaginated.pagination} limitOptions={paginationLimitOptions} onChange={updateFromPagination} />
                            </div>
                        </>
                    }
                    {conceptPaginated.results.length === 0 &&
                        <div className="text-center fs-5 pt-3 pb-4">
                            Derzeit sind keine Konzepte in der Hall of Inspiration veröffentlicht oder die gewählten Filterkriterien liefern keine Ergebnisse.
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default ConceptOverviewPage;
