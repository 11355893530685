import CompanyValue from '../CompanyValue';
import Branch from './Branch';

class ConceptFilter {
    private _companyValues: CompanyValue[] = [];

    private _branches: Branch[] = [];

    public static createDefault(): ConceptFilter {
        return new this();
    }

    get companyValues(): CompanyValue[] {
        return this._companyValues;
    }

    set companyValues(value: CompanyValue[]) {
        this._companyValues = value;
    }

    get branches(): Branch[] {
        return this._branches;
    }

    set branches(value: Branch[]) {
        this._branches = value;
    }
}

export default ConceptFilter;
