import ConversationType from '../Entity/Messaging/ConversationType';
import Conversation from '../Entity/Messaging/Conversation';
import ProviderSeekerConversation from '../Entity/Messaging/ProviderSeekerConversation';
import LeanConversation from '../Entity/Messaging/LeanConversation';
import Message from '../Entity/Messaging/Message';
import ProviderSeekerMessage from '../Entity/Messaging/ProviderSeekerMessage';
import LeanMessage from '../Entity/Messaging/LeanMessage';
import MessageRead from '../Entity/Messaging/MessageRead';
import ProviderSeekerMessageRead from '../Entity/Messaging/ProviderSeekerMessageRead';
import LeanMessageRead from '../Entity/Messaging/LeanMessageRead';
import Property from '../Entity/Property/Property';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import LeanPropertyExpose from '../Entity/Messaging/LeanPropertyExpose';
import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import ConversationResponse from '../../../api/Llasm/shared/Messaging/ConversationResponse';
import ProviderSeekerConversationResponse from '../../../api/Llasm/shared/Messaging/ProviderSeekerConversationResponse';
import LeanConversationResponse from '../../../api/Llasm/shared/Messaging/LeanConversationResponse';
import ProviderSeekerMessageResponse from '../../../api/Llasm/shared/Messaging/ProviderSeekerMessageResponse';
import LeanMessageResponse from '../../../api/Llasm/shared/Messaging/LeanMessageResponse';
import ProviderSeekerMessageReadResponse from '../../../api/Llasm/shared/Messaging/ProviderSeekerMessageReadResponse';
import LeanMessageReadResponse from '../../../api/Llasm/shared/Messaging/LeanMessageReadResponse';
import LeanPropertyExposeResponse from '../../../api/Llasm/shared/Messaging/LeanPropertyExposeResponse';
import ProviderSeekerMessagePostRequest from '../../../api/Llasm/shared/Messaging/ProviderSeekerMessagePostRequest';
import LeanMessagePostRequest from '../../../api/Llasm/shared/Messaging/LeanMessagePostRequest';
import ProviderSeekerMessageReadPostRequest from '../../../api/Llasm/shared/Messaging/ProviderSeekerMessageReadPostRequest';
import LeanMessageReadPostRequest from '../../../api/Llasm/shared/Messaging/LeanMessageReadPostRequest';
import {AxiosResponse} from 'axios';

class ConversationService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
    }

    public async fetchConversationsFromApi(): Promise<Conversation[]> {
        const apiResponse: AxiosResponse<ConversationResponse[]> = await this.llasmApiV1Provider.get('/conversations');

        return apiResponse.data.map((conversationResponse: ConversationResponse): Conversation => {
            if (conversationResponse.conversationType === ConversationType.ProviderSeeker) {
                return ProviderSeekerConversation.createFromProviderSeekerConversationResponse(conversationResponse as ProviderSeekerConversationResponse);
            }

            if (conversationResponse.conversationType === ConversationType.Lean) {
                return LeanConversation.createFromLeanConversationResponse(conversationResponse as LeanConversationResponse);
            }

            throw new Error();
        });
    }

    public async fetchConversationFromApiById(conversationType: ConversationType, id: number): Promise<Conversation> {
        const apiResponse: AxiosResponse<ConversationResponse> = await this.llasmApiV1Provider.get('/conversations/' + conversationType + '-' + id);

        if (apiResponse.data.conversationType === ConversationType.ProviderSeeker) {
            return ProviderSeekerConversation.createFromProviderSeekerConversationResponse(apiResponse.data as ProviderSeekerConversationResponse);
        }

        if (apiResponse.data.conversationType === ConversationType.Lean) {
            return LeanConversation.createFromLeanConversationResponse(apiResponse.data as LeanConversationResponse);
        }

        throw new Error();
    }

    public async fetchProviderSeekerConversationsFromApiByPropertyAndSettlementConcept(property: Property, settlementConcept: SettlementConcept): Promise<Conversation[]> {
        const apiResponse: AxiosResponse<ConversationResponse[]> = await this.llasmApiV1Provider.get(
            '/conversations?conversationTypes=' + ConversationType.ProviderSeeker + '&propertyIds=' + property.id + '&settlementConceptIds=' + settlementConcept.id
        );

        return apiResponse.data.map((conversationResponse: ConversationResponse): Conversation => {
            return ProviderSeekerConversation.createFromProviderSeekerConversationResponse(conversationResponse as ProviderSeekerConversationResponse);
        });
    }

    public async fetchMessagesFromApi(conversation: Conversation): Promise<Message[]> {
        if (conversation instanceof ProviderSeekerConversation) {
            return this.fetchProviderSeekerMessagesFromApi(conversation);
        }

        if (conversation instanceof LeanConversation) {
            return this.fetchLeanMessagesFromApi(conversation);
        }

        throw new Error();
    }

    public async fetchLeanPropertyExposeFromApi(leanConversation: LeanConversation): Promise<LeanPropertyExpose> {
        const apiResponse: AxiosResponse<LeanPropertyExposeResponse[]> = await this.llasmApiV1Provider.get(
            '/conversations/' + ConversationType.Lean + '-' + leanConversation.leanApiConfiguration.id + '-' + leanConversation.id + '/property-exposes'
        );

        if (apiResponse.data.length === 0) {
            throw new Error();
        }

        return LeanPropertyExpose.createFromLeanPropertyExposeResponse(apiResponse.data[0]);
    }

    public async persistMessage(conversation: Conversation, message: Message): Promise<Message> {
        if (conversation instanceof ProviderSeekerConversation) {
            return await this.persistProviderSeekerMessage(conversation, message);
        }

        if (conversation instanceof LeanConversation) {
            return await this.persistLeanMessage(conversation, message);
        }

        throw new Error();
    }

    public async persistMessageRead(conversation: Conversation, message: Message, messageRead: MessageRead): Promise<MessageRead> {
        if (conversation instanceof ProviderSeekerConversation && message instanceof ProviderSeekerMessage) {
            return await this.persistProviderSeekerMessageRead(conversation, message, messageRead);
        }

        if (conversation instanceof LeanConversation && message instanceof LeanMessage) {
            return await this.persistLeanMessageRead(conversation, message, messageRead);
        }

        throw new Error();
    }

    public async deleteMessage(conversation: Conversation, message: Message): Promise<AxiosResponse<null>> {
        if (conversation instanceof ProviderSeekerConversation) {
            return await this.deleteProviderSeekerMessage(conversation, message);
        }

        if (conversation instanceof LeanConversation) {
            return await this.deleteLeanMessage(conversation, message);
        }

        throw new Error();
    }

    public sortConversations(conversations: Conversation[]): void {
        conversations.sort((a: Conversation, b: Conversation): number => {
            const sortingDateA: Date = a.lastMessageAt ?? a.createdAt;
            const sortingDateB: Date = b.lastMessageAt ?? b.createdAt;

            if (sortingDateA < sortingDateB) {
                return 1;
            }

            if (sortingDateA > sortingDateB) {
                return -1;
            }

            return 0;
        });
    }

    public static createLinkToConversation(conversation: Conversation): string {
        const searchParams: URLSearchParams = new URLSearchParams();

        searchParams.set('conversation', conversation.buildIdentifier());

        return '/konversationen/?' + searchParams.toString();
    }

    private async fetchProviderSeekerMessagesFromApi(providerSeekerConversation: ProviderSeekerConversation): Promise<ProviderSeekerMessage[]> {
        const apiResponse: AxiosResponse<ProviderSeekerMessageResponse[]> = await this.llasmApiV1Provider.get(
            '/conversations/' + ConversationType.ProviderSeeker + '-' + providerSeekerConversation.id + '/messages'
        );

        return apiResponse.data.map((providerSeekerMessageResponse: ProviderSeekerMessageResponse): ProviderSeekerMessage => {
            return ProviderSeekerMessage.createFromProviderSeekerMessageResponse(providerSeekerMessageResponse);
        });
    }

    private async fetchLeanMessagesFromApi(leanConversation: LeanConversation): Promise<LeanMessage[]> {
        const apiResponse: AxiosResponse<LeanMessageResponse[]> = await this.llasmApiV1Provider.get(
            '/conversations/' + ConversationType.Lean + '-' + leanConversation.leanApiConfiguration.id + '-' + leanConversation.id + '/messages'
        );

        return apiResponse.data.map((leanMessageResponse: LeanMessageResponse): LeanMessage => {
            return LeanMessage.createFromLeanMessageResponse(leanMessageResponse);
        });
    }

    private async persistProviderSeekerMessage(providerSeekerConversation: ProviderSeekerConversation, providerSeekerMessage: ProviderSeekerMessage): Promise<ProviderSeekerMessage> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, ProviderSeekerMessagePostRequest>(
            '/conversations/' + ConversationType.ProviderSeeker + '-' + providerSeekerConversation.id + '/messages',
            ConversationService.mapFromProviderSeekerMessageToProviderSeekerMessagePostRequest(providerSeekerMessage)
        );

        const apiResponse: AxiosResponse<ProviderSeekerMessageResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return ProviderSeekerMessage.createFromProviderSeekerMessageResponse(apiResponse.data);
    }

    private async persistLeanMessage(leanConversation: LeanConversation, leanMessage: LeanMessage): Promise<LeanMessage> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, LeanMessagePostRequest>(
            '/conversations/' + ConversationType.Lean + '-' + leanConversation.leanApiConfiguration.id + '-' + leanConversation.id + '/messages',
            ConversationService.mapFromLeanMessageToLeanMessagePostRequest(leanMessage)
        );

        const apiResponse: AxiosResponse<LeanMessageResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return LeanMessage.createFromLeanMessageResponse(apiResponse.data);
    }

    private async persistProviderSeekerMessageRead(providerSeekerConversation: ProviderSeekerConversation, providerSeekerMessage: ProviderSeekerMessage, providerSeekerMessageRead: ProviderSeekerMessageRead): Promise<ProviderSeekerMessageRead> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, ProviderSeekerMessageReadPostRequest>(
            '/conversations/' + ConversationType.ProviderSeeker + '-' + providerSeekerConversation.id + '/messages/' + providerSeekerMessage.id + '/message-reads',
            ConversationService.mapFromProviderSeekerMessageReadToProviderSeekerMessageReadPostRequest(providerSeekerMessageRead)
        );

        const apiResponse: AxiosResponse<ProviderSeekerMessageReadResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return ProviderSeekerMessageRead.createFromProviderSeekerMessageReadResponse(apiResponse.data);
    }

    private async persistLeanMessageRead(leanConversation: LeanConversation, leanMessage: LeanMessage, leanMessageRead: LeanMessageRead): Promise<LeanMessageRead> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, LeanMessageReadPostRequest>(
            '/conversations/' + ConversationType.Lean + '-' + leanConversation.leanApiConfiguration.id + '-' + leanConversation.id + '/messages/' + leanMessage.id + '/message-reads',
            ConversationService.mapFromLeanMessageReadToLeanMessageReadPostRequest(leanMessageRead)
        );

        const apiResponse: AxiosResponse<LeanMessageReadResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return LeanMessageRead.createFromLeanMessageReadResponse(apiResponse.data);
    }

    private async deleteProviderSeekerMessage(providerSeekerConversation: ProviderSeekerConversation, providerSeekerMessage: ProviderSeekerMessage): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.delete('/conversations/' + ConversationType.ProviderSeeker + '-' + providerSeekerConversation.id + '/messages/' + providerSeekerMessage.id);
    }

    private async deleteLeanMessage(leanConversation: LeanConversation, leanMessage: LeanMessage): Promise<AxiosResponse<null>> {
        return await this.llasmApiV1Provider.delete('/conversations/' + ConversationType.Lean + '-' + leanConversation.leanApiConfiguration.id + '-' + leanConversation.id + '/messages/' + leanMessage.id);
    }

    private static mapFromProviderSeekerMessageToProviderSeekerMessagePostRequest(providerSeekerMessage: ProviderSeekerMessage): ProviderSeekerMessagePostRequest {
        if (typeof providerSeekerMessage.text !== 'string') {
            throw new Error();
        }

        return {
            text: providerSeekerMessage.text
        };
    }

    private static mapFromLeanMessageToLeanMessagePostRequest(leanMessage: LeanMessage): LeanMessagePostRequest {
        if (typeof leanMessage.text !== 'string') {
            throw new Error();
        }

        return {
            text: leanMessage.text
        };
    }

    private static mapFromProviderSeekerMessageReadToProviderSeekerMessageReadPostRequest(providerSeekerMessageRead: ProviderSeekerMessageRead): ProviderSeekerMessageReadPostRequest {
        return {
        };
    }

    private static mapFromLeanMessageReadToLeanMessageReadPostRequest(leanMessageRead: LeanMessageRead): LeanMessageReadPostRequest {
        return {
        };
    }
}

export default ConversationService;
