import CentralPlaceType from './CentralPlaceType';
import AdvancedPlaceInformationResponse from '../../../../api/Llasm/shared/Location/AdvancedPlaceInformationResponse';

class AdvancedPlaceInformation {

    private _id?: number;

    private _population: number;

    private _purchasingPower: number | null = null;

    private _purchasingPowerPerYear: number | null = null;

    private _centralPlaceType: CentralPlaceType | null = null;

    public static createFromAdvancedPlaceInformationResponse(advancedPlaceInformationResponse: AdvancedPlaceInformationResponse): AdvancedPlaceInformation {
        const advancedPlaceInformation: AdvancedPlaceInformation = new this();

        advancedPlaceInformation._id = advancedPlaceInformationResponse.id;
        advancedPlaceInformation._population = advancedPlaceInformationResponse.population;
        advancedPlaceInformation._purchasingPower = advancedPlaceInformationResponse.purchasingPower;
        advancedPlaceInformation._purchasingPowerPerYear = advancedPlaceInformationResponse.purchasingPowerPerYear;
        advancedPlaceInformation._centralPlaceType = advancedPlaceInformationResponse.centralPlaceType;

        return advancedPlaceInformation;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get population(): number {
        return this._population;
    }

    set population(value: number) {
        this._population = value;
    }

    get purchasingPower(): number | null {
        return this._purchasingPower;
    }

    set purchasingPower(value: number | null) {
        this._purchasingPower = value;
    }

    get purchasingPowerPerYear(): number | null {
        return this._purchasingPowerPerYear;
    }

    set purchasingPowerPerYear(value: number | null) {
        this._purchasingPowerPerYear = value;
    }

    get centralPlaceType(): CentralPlaceType | null {
        return this._centralPlaceType;
    }

    set centralPlaceType(value: CentralPlaceType | null) {
        this._centralPlaceType = value;
    }

}

export default AdvancedPlaceInformation;
