import Concept from '../Entity/HallOfInspiration/Concept';
import ConceptDetailInformation from './ConceptDetailInformation';
import Overlay from '../Component/Overlay/Overlay';
import React from 'react';

interface ConceptDetailInformationOverlayProps extends React.PropsWithChildren {
    readonly show: boolean;
    readonly setShow: (show: boolean) => void;
    readonly concept: Concept;
    readonly onClose?: () => void;
}

const ConceptDetailInformationOverlay = (props: ConceptDetailInformationOverlayProps): React.JSX.Element => {
    return (
        <Overlay show={props.show} setShow={props.setShow} title={props.concept.brandName} onClose={props.onClose}>
            <div className="container-fluid mb-5">
                <ConceptDetailInformation concept={props.concept} />
            </div>
        </Overlay>
    );
};

export default ConceptDetailInformationOverlay;
