import CompanyValue, {getCompanyValueLabel} from '../Entity/CompanyValue';
import IconBadge from '../Component/IconBadge/IconBadge';
import React from 'react';

interface CompanyValueIconBadgeProps {
    readonly companyValue: CompanyValue;
}

const CompanyValueIconBadge = (props: CompanyValueIconBadgeProps): React.JSX.Element => {
    switch (props.companyValue) {
        case CompanyValue.Sustainability:
            return <IconBadge iconPublisher="material" iconName="forest" description={getCompanyValueLabel(CompanyValue.Sustainability)} backgroundColor="secondary" />;
        case CompanyValue.Specialized:
            return <IconBadge iconPublisher="material" iconName="bolt" description={getCompanyValueLabel(CompanyValue.Specialized)} backgroundColor="secondary" />;
        case CompanyValue.Innovative:
            return <IconBadge iconPublisher="material" iconName="emoji_objects" description={getCompanyValueLabel(CompanyValue.Innovative)} backgroundColor="secondary" />;
        case CompanyValue.Networking:
            return <IconBadge iconPublisher="material" iconName="share" description={getCompanyValueLabel(CompanyValue.Networking)} backgroundColor="secondary" />;
        case CompanyValue.RelaxationRecreation:
            return <IconBadge iconPublisher="material" iconName="self_improvement" description={getCompanyValueLabel(CompanyValue.RelaxationRecreation)} backgroundColor="secondary" />;
        case CompanyValue.Creativity:
            return <IconBadge iconPublisher="material" iconName="color_lens" description={getCompanyValueLabel(CompanyValue.Creativity)} backgroundColor="secondary" />;
        case CompanyValue.ExperienceEntertainment:
            return <IconBadge iconPublisher="material" iconName="attractions" description={getCompanyValueLabel(CompanyValue.ExperienceEntertainment)} backgroundColor="secondary" />;
        case CompanyValue.LocalityRegionality:
            return <IconBadge iconPublisher="material" iconName="anchor" description={getCompanyValueLabel(CompanyValue.LocalityRegionality)} backgroundColor="secondary" />;
        case CompanyValue.ServiceOriented:
            return <IconBadge iconPublisher="material" iconName="room_service" description={getCompanyValueLabel(CompanyValue.ServiceOriented)} backgroundColor="secondary" />;
        case CompanyValue.VitalityVibrancy:
            return <IconBadge iconPublisher="material" iconName="fitness_center" description={getCompanyValueLabel(CompanyValue.VitalityVibrancy)} backgroundColor="secondary" />;
        case CompanyValue.ShopWindowDressing:
            return <IconBadge iconPublisher="material" iconName="storefront" description={getCompanyValueLabel(CompanyValue.ShopWindowDressing)} backgroundColor="secondary" />;
        case CompanyValue.ShopFitting:
            return <IconBadge iconPublisher="material" iconName="handyman" description={getCompanyValueLabel(CompanyValue.ShopFitting)} backgroundColor="secondary" />;
        case CompanyValue.Digitalisation:
            return <IconBadge iconPublisher="material" iconName="smart_toy" description={getCompanyValueLabel(CompanyValue.Digitalisation)} backgroundColor="secondary" />;
        default:
            return <></>;
    }
};

export default CompanyValueIconBadge;
