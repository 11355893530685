import LlasmApiV1Provider from '../../../api/LlasmApiV1Provider';
import PropertyInterest from '../Entity/PropertyInterest/PropertyInterest';
import PropertyInterestResponse from '../../../api/Llasm/shared/PropertyInterest/PropertyInterestResponse';
import PropertyInterestPostRequest from '../../../api/Llasm/shared/PropertyInterest/PropertyInterestPostRequest';
import {AxiosResponse} from 'axios';

class PropertyInterestService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    private readonly resourcePath: string;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
        this.resourcePath = '/property-interests';
    }

    public async fetchPropertyInterestFromApiByIdentifier(identifier: string): Promise<PropertyInterest> {
        const apiResponse: AxiosResponse<PropertyInterestResponse> = await this.llasmApiV1Provider.get(this.resourcePath + '/' + identifier);

        return PropertyInterest.createFromPropertyInterestResponse(apiResponse.data);
    }

    public async persistPropertyInterest(propertyInterest: PropertyInterest): Promise<PropertyInterest> {
        const response: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, PropertyInterestPostRequest>(
            this.resourcePath,
            PropertyInterestService.mapFromPropertyInterestToPropertyInterestPostRequest(propertyInterest)
        );

        const apiResponse: AxiosResponse<PropertyInterestResponse> = await this.llasmApiV1Provider.getFromLocation(response.headers.location);

        return PropertyInterest.createFromPropertyInterestResponse(apiResponse.data);
    }

    private static mapFromPropertyInterestToPropertyInterestPostRequest(propertyInterest: PropertyInterest): PropertyInterestPostRequest {
        if (propertyInterest.propertyId === null || propertyInterest.settlementConceptId === null) {
            throw new Error();
        }

        return {
            propertyId: propertyInterest.propertyId,
            settlementConceptId: propertyInterest.settlementConceptId,
            propertyInterestFeedback: propertyInterest.propertyInterestFeedback
        };
    }
}

export default PropertyInterestService;
