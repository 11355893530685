import FormData from '../Entity/Form/FormData';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import InputField from '../Component/Form/Field/InputField';
import UserPasswordResetDemandPostRequest from '../../../api/Llasm/shared/User/UserPasswordResetDemandPostRequest';
import React, { ChangeEvent } from 'react';

interface PasswordResetRequestFormProps {
    readonly formData: FormData<UserPasswordResetDemandPostRequest>;
    readonly setFormData: Function;
    readonly formValidationHandler?: FormValidationHandler<UserPasswordResetDemandPostRequest>;
}

const UserPasswordResetDemandForm = (props: PasswordResetRequestFormProps): React.JSX.Element => {
    const userPasswordResetDemandPostRequest: UserPasswordResetDemandPostRequest = props.formData.data;

    const handleChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (userPasswordResetDemandPostRequest as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: userPasswordResetDemandPostRequest});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <div className="mb-3">
            <InputField
                name="email"
                label="E-Mail-Adresse"
                type="email"
                required={true}
                value={userPasswordResetDemandPostRequest.email}
                onChange={handleChange}
                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'email')}
            />
        </div>
    );
};

export default UserPasswordResetDemandForm;
