import CompanyLocation from './CompanyLocation';
import Address from './Address';
import Branch from './Branch';
import CompanyValue from '../CompanyValue';
import Image from '../Image/Image';
import BranchResponse from '../../../../api/Llasm/shared/HallOfInspiration/BranchResponse';
import ConceptResponse from '../../../../api/Llasm/shared/HallOfInspiration/ConceptResponse';

class Concept {
    private _id?: number;

    private _enabled: boolean;

    private _brandName: string;

    private _companyValues: CompanyValue[] = [];

    private _numberOfCompanyLocations: number;

    private _companyLocations: CompanyLocation[] = [];

    private _conceptDescription: string;

    private _usp: string | null = null;

    private _experience: string | null = null;

    private _successStory: string | null = null;

    private _expansionPlans: string | null = null;

    private _flagshipStore: boolean;

    private _instagram: string | null = null;

    private _linkedIn: string | null = null;

    private _website: string | null = null;

    private _videos: string[] = [];

    private _mainImage: Image | null = null;

    private _address: Address | null = null;

    private _branches: Branch[] = [];

    private _hasContact: boolean;

    public static createFromConceptResponse(conceptResponse: ConceptResponse): Concept {
        const concept: Concept = new this();

        concept._id = conceptResponse.id;
        concept._enabled = conceptResponse.enabled;
        concept._brandName = conceptResponse.brandName;
        concept._companyValues = conceptResponse.companyValues;
        concept._numberOfCompanyLocations = conceptResponse.numberOfCompanyLocations;
        concept._companyLocations = conceptResponse.companyLocations;
        concept._conceptDescription = conceptResponse.conceptDescription;
        concept._usp = conceptResponse.usp;
        concept._experience = conceptResponse.experience;
        concept._successStory = conceptResponse.successStory;
        concept._expansionPlans = conceptResponse.expansionPlans;
        concept._flagshipStore = conceptResponse.flagshipStore;
        concept._instagram = conceptResponse.instagram;
        concept._linkedIn = conceptResponse.linkedIn;
        concept._website = conceptResponse.website;
        concept._videos = conceptResponse.videos;

        if (conceptResponse.mainImage !== null) {
            concept._mainImage = Image.createFromImageResponse(conceptResponse.mainImage);
        }

        if (conceptResponse.address !== null) {
            concept._address = Address.createFromAddressResponse(conceptResponse.address);
        }

        concept._branches = conceptResponse.branches.map((branchResponse: BranchResponse): Branch => {
            return Branch.createFromBranchResponse(branchResponse);
        });

        concept._hasContact = conceptResponse.hasContact;

        return concept;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }

    get brandName(): string {
        return this._brandName;
    }

    set brandName(value: string) {
        this._brandName = value;
    }

    get companyValues(): CompanyValue[] {
        return this._companyValues;
    }

    set companyValues(value: CompanyValue[]) {
        this._companyValues = value;
    }

    get numberOfCompanyLocations(): number {
        return this._numberOfCompanyLocations;
    }

    set numberOfCompanyLocations(value: number) {
        this._numberOfCompanyLocations = value;
    }

    get companyLocations(): CompanyLocation[] {
        return this._companyLocations;
    }

    set companyLocations(value: CompanyLocation[]) {
        this._companyLocations = value;
    }

    get conceptDescription(): string {
        return this._conceptDescription;
    }

    set conceptDescription(value: string) {
        this._conceptDescription = value;
    }

    get usp(): string | null {
        return this._usp;
    }

    set usp(value: string | null) {
        this._usp = value;
    }

    get experience(): string | null {
        return this._experience;
    }

    set experience(value: string | null) {
        this._experience = value;
    }

    get successStory(): string | null {
        return this._successStory;
    }

    set successStory(value: string | null) {
        this._successStory = value;
    }

    get expansionPlans(): string | null {
        return this._expansionPlans;
    }

    set expansionPlans(value: string | null) {
        this._expansionPlans = value;
    }

    get flagshipStore(): boolean {
        return this._flagshipStore;
    }

    set flagshipStore(value: boolean) {
        this._flagshipStore = value;
    }

    get instagram(): string | null {
        return this._instagram;
    }

    set instagram(value: string | null) {
        this._instagram = value;
    }

    get linkedIn(): string | null {
        return this._linkedIn;
    }

    set linkedIn(value: string | null) {
        this._linkedIn = value;
    }

    get website(): string | null {
        return this._website;
    }

    set website(value: string | null) {
        this._website = value;
    }

    get videos(): string[] {
        return this._videos;
    }

    set videos(value: string[]) {
        this._videos = value;
    }

    get mainImage(): Image | null {
        return this._mainImage;
    }

    set mainImage(value: Image | null) {
        this._mainImage = value;
    }

    get address(): Address | null {
        return this._address;
    }

    set address(value: Address | null) {
        this._address = value;
    }

    get branches(): Branch[] {
        return this._branches;
    }

    set branches(value: Branch[]) {
        this._branches = value;
    }

    get hasContact(): boolean {
        return this._hasContact;
    }

    set hasContact(value: boolean) {
        this._hasContact = value;
    }
}

export default Concept;
