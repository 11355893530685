import AuthenticatedUser from '../Entity/Authentication/AuthenticatedUser';
import LoadingIndicator from '../Component/LoadingIndicator/LoadingIndicator';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {authenticateByTokenExchange} from '../../../features/authentication/authenticationSlice';
import React, {useEffect, useRef, useState} from 'react';
import {NavigateFunction, useNavigate, useParams} from 'react-router-dom';
import {Action, Dispatch, ThunkDispatch} from '@reduxjs/toolkit';

const AuthenticationTokenExchangePage = (): React.JSX.Element => {
    const {oneTimeTokenUuid} = useParams<string>();

    const navigate: NavigateFunction = useNavigate();

    const dispatch: ThunkDispatch<{auth: any}, undefined, Action> & Dispatch<Action> = useAppDispatch();

    const didAuthenticationRef: React.MutableRefObject<boolean> = useRef<boolean>(false);

    const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser | null>(null);

    useEffect((): void => {
        if (oneTimeTokenUuid === undefined) {
            return;
        }

        if (didAuthenticationRef.current === true) {
            return;
        }

        didAuthenticationRef.current = true;

        doAuthenticateByTokenExchange(oneTimeTokenUuid);
    }, [oneTimeTokenUuid]);

    useEffect((): void => {
        if (authenticatedUser === null) {
            return;
        }

        dispatch({type: 'authentication/setAuthenticatedUserFromStorage'});

        navigate('/');
    }, [authenticatedUser,dispatch]);

    const doAuthenticateByTokenExchange = async (oneTimeTokenUuid: string): Promise<void> => {
        const authenticateAction = await dispatch(authenticateByTokenExchange(oneTimeTokenUuid));

        if (authenticateAction.type === 'auth/tokenExchange/fulfilled') {
            setAuthenticatedUser((authenticateAction.payload) as AuthenticatedUser);
        }

        if (authenticateAction.type === 'auth/tokenExchange/rejected') {
            navigate('/login');

            return;
        }
    };

    return <LoadingIndicator />;
};

export default AuthenticationTokenExchangePage;
